import { api, User } from 'services';

export class LocalStorage {
  // Manage token
  static getToken = () => localStorage.getItem('token');

  static setToken = (token: string) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem('token', token);
  };

  static removeToken = () => {
    api.defaults.headers.common.Authorization = '';
    localStorage.removeItem('token');
  };

  // Manage user
  static getUser = (): User | null => {
    const stringUser = localStorage.getItem('user');
    if (!stringUser) {
      return null;
    }
    return JSON.parse(stringUser);
  };

  static setUser = (user: User) => localStorage.setItem('user', JSON.stringify(user));

  static removeUser = () => localStorage.removeItem('user');

  // Manage admin default app state
  static getAdminDefaultAppState = (): string | null => localStorage.getItem('adminDefaultAppState');

  static setAdminDefaultAppState = (appState: string) => localStorage.setItem('adminDefaultAppState', appState);

  static removeAdminDefaultAppState = () => localStorage.removeItem('adminDefaultAppState');
}
