import { CellProps } from 'react-table';

import { TableColumn, Tooltip } from 'components';
import { shortenId } from 'helpers';
import { Article, User, UserRole } from 'services';
import clsx from 'clsx';
import { ConfirmationModal } from './types';
import { ArticleStatus } from 'services/articles/const';

export const initialConfirmationModal: ConfirmationModal = {
  action: null,
  isOpen: false,
  title: '',
  message: '',
  actionButtonLabel: 'Cancel',
};

export const columns = (deleteClicked: (rowData: Article) => void, user: User | null): TableColumn<Article>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    isSortable: true,
    Cell: ({ row }: CellProps<Article>) => {
      const rowData = row.original;
      return (
        <Tooltip
          popoverMessage="Copied!"
          tooltipMessage={rowData.id}
          onClick={() => navigator.clipboard.writeText(rowData.id)}
        >
          {shortenId(rowData.id)}
        </Tooltip>
      );
    },
  },
  {
    Header: 'Name',
    accessor: 'name' as any,
    isSortable: true,
  },
  {
    Header: 'Publisher',
    accessor: 'authorName' as any,
    isSortable: true,
  },
  {
    Header: 'Languages',
    accessor: 'originalLanguages' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Article>) => {
      const rowData = row.original;
      return (
        <div className="flex flex-wrap gap-1">
          {rowData.translations.map((trans, i) => (
            <span
              key={i}
              className={clsx(
                'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
                'bg-blue-100 text-blue-800',
              )}
            >
              {trans.language}
            </span>
          ))}
        </div>
      );
    },
  },
  {
    Header: 'Tags',
    accessor: 'tags' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Article>) => {
      const rowData = row.original;
      return (
        <div className="flex flex-wrap gap-1">
          {rowData.tags.map((tag, id) => (
            <div key={id}>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 whitespace-nowrap">
                {tag.name}
              </span>
              <br />
            </div>
          ))}
        </div>
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'vettingStatus' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Article>) => {
      const rowData = row.original;
      return (
        <span
          className={clsx(
            'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
            rowData.vettingStatus === ArticleStatus.DENIED
              ? 'bg-red-100 text-red-800'
              : rowData.vettingStatus === ArticleStatus.APPROVED
              ? 'bg-green-100 text-green-800'
              : 'bg-gray-200 text-gray-800',
          )}
        >
          {rowData.vettingStatus || ArticleStatus.PENDING}
        </span>
      );
    },
  },
  {
    id: 'deleteButton',
    Header: '',
    Cell: ({ row }: CellProps<Article>) => {
      const rowData = row.original;

      if (user?.role === UserRole.ADMIN) {
        return (
          <div className="flex justify-end">
            <button
              type="button"
              className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={(ev) => {
                ev.stopPropagation();
                deleteClicked(rowData);
              }}
            >
              Delete
            </button>
          </div>
        );
      }
      return null;
    },
    width: 'auto',
  },
];
