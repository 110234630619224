import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

export const TagsContext = React.createContext<unknown>({});

// This will be used in case we state shared inside the module
export const TagsProvider = ({ children = <Outlet /> }: Props) => {
  const providerValue = useMemo(() => ({}), []);

  return <TagsContext.Provider value={providerValue}>{children}</TagsContext.Provider>;
};

interface Props {
  children?: React.ReactNode;
}
