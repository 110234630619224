import { Button, ConfirmationModal, HeaderSection, Input, TagPicker } from 'components';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import { LanguageModal } from './components';
import { useArticlesFormPage } from './hooks';
import TextEditor from 'components/TextEditor/TextEditor';
import { Controller } from 'react-hook-form';
import { FileUploader } from 'components/FileUploader';

export const ArticleFormPage = () => {
  const navigate = useNavigate();
  const {
    control,
    onSubmit,
    openConfirmationModal,
    openDisclaimer,
    closeConfirmationModal,
    closeLanguageModal,
    openLanguageModal,
    closeDisclaimer,
    fields,
    onDeleteConfirm,
    handleTabClick,
    activeTab,
    languageModal,
    onLanguageAdd,
    translationToDelete,
    disclaimer,
    resetFile,
  } = useArticlesFormPage();

  return (
    <>
      <form onSubmit={onSubmit}>
        <HeaderSection
          title="New Community Update"
          leftAction={
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-black bg-transparent hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-black mr-2 z-10"
            >
              <ArrowLeftIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
            </button>
          }
        >
          <div className="flex flex-1 space-x-4 justify-between mt-4 sm:mt-0">
            <nav className="flex space-x-2 sm:ml-8" aria-label="Tabs">
              {fields.map((tab, index) => (
                <div
                  key={tab.language}
                  onClick={handleTabClick(tab.language)}
                  className={clsx(
                    tab.language === activeTab ? 'bg-blue-200 text-blue-800' : 'text-gray-500 hover:text-gray-700',
                    'px-3 py-2 text-sm rounded-md uppercase font-semibold cursor-pointer group relative',
                  )}
                  aria-current={tab.language === activeTab ? 'page' : undefined}
                >
                  {tab.language}

                  {tab.language !== 'en' && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        openConfirmationModal({ language: tab.language, index });
                      }}
                      className="absolute -top-1 -right-1 hidden items-center h-4 w-4 rounded-full text-xs font-medium bg-primary text-white justify-center group-hover:inline-flex shadow-sm"
                    >
                      <MinusIcon className="h-2 w-2 text-white" aria-hidden="true" />
                    </span>
                  )}
                </div>
              ))}

              <div
                onClick={openDisclaimer}
                className="px-3 py-2 font-medium text-sm rounded-md cursor-pointer text-gray-500 hover:text-gray-700 flex items-center hover:bg-gray-200"
              >
                <PlusIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
              </div>
            </nav>
            <div className="flex justify-between items-center">
              <Button onClick={onSubmit} className="ml-auto rounded-md">
                Save
              </Button>
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="px-4 py-2 ml-4 rounded-md border border-transparent text-sm font-medium  border-gray-500  text-black bg-transparent"
              >
                Cancel
              </button>
            </div>
          </div>
        </HeaderSection>

        <div className="bg-white shadow rounded-lg">
          <div className="space-y-6 px-4 py-5 sm:p-6">
            <div className="grid grid-cols-2 gap-4 sm:gap-12">
              {fields.map((field, index) => (
                <div
                  className={clsx(field.language === activeTab ? 'block' : 'hidden', 'col-span-2 space-y-8')}
                  key={field.keyID}
                >
                  <Input control={control} name={`translations.${index}.name`} label="Title" />
                  <Input control={control} name={`translations.${index}.description`} label="Description" />
                  <Controller
                    control={control}
                    name={`translations.${index}.text`}
                    render={({ field: { value, onChange } }) => (
                      <TextEditor value={value || ''} onChange={onChange} placeholder="Enter text" />
                    )}
                  />
                  <div className="w-full lg:w-1/2">
                    <FileUploader
                      control={control}
                      name={`translations.${index}.file`}
                      label="File"
                      resetFile={() => resetFile(index)}
                    />
                  </div>
                </div>
              ))}

              <div className="flex flex-col lg:flex-row items-start justify-between col-span-2 lg:space-x-4 mb-8">
                <div className="flex-1 mt-4 w-full">
                  <Input control={control} name="authorName" label="Publisher" disabled />
                </div>
                <div className="flex-1 w-full">
                  <TagPicker control={control} name="tags" label="Add tags" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <LanguageModal
        open={languageModal}
        onClose={closeLanguageModal}
        control={control}
        name="languages"
        onSubmit={onLanguageAdd}
      />

      <ConfirmationModal
        title="Delete translation"
        body={`Are you sure you want to delete "${translationToDelete.translation}"?`}
        open={!!translationToDelete.translation}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
      <ConfirmationModal
        title="ECNL"
        body={`We are responsible only for vetting the English version of the Community Update`}
        open={disclaimer}
        onClose={closeDisclaimer}
        onAction={openLanguageModal}
        actionButtonLabel="Continue"
      />
    </>
  );
};
