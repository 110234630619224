import React, { createContext, useContext, useMemo, useState, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MetaData, SortDirection, usePagination, MessageType, showMessage } from 'helpers';
import { Question, fetchQuestions, deleteQuestion } from 'services';

const QuestionsContext = createContext<{
  questions: Question[];
  metaData?: MetaData;
  currentPage: number;
  questionsLoading: boolean;
  questionToDelete: Question | null;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  openConfirmationModal: (selectedQuestion: Question | null) => void;
  closeConfirmationModal: () => void;
  onDeleteConfirm: () => void;
  onRowClick: (row: Question) => void;
  onNewClick: () => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const QuestionsProvider = ({ children = <Outlet /> }: Props) => {
  const { currentPage, pageSize, sortBy, sortDirection, pagination, onPageChange, onPageSizeChange, onSortChange } =
    usePagination();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [questionToDelete, setQuestionToDelete] = useState<Question | null>(null);

  const { data: queryData, isLoading: questionsLoading } = useQuery(
    ['questions', currentPage, pageSize, sortBy, sortDirection],
    () => fetchQuestions(pagination),
  );

  const { mutateAsync: removeQuestion } = useMutation(deleteQuestion);

  const questions = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = useCallback(
    (row: Question) => {
      navigate(`/admin/questions/${row.id}`);
    },
    [navigate],
  );

  const onNewClick = useCallback(() => {
    navigate('/admin/questions/new');
  }, []);

  const onDeleteConfirm = useCallback(async () => {
    if (questionToDelete) {
      try {
        await removeQuestion(questionToDelete.id);
        showMessage('Question successfully deleted.', MessageType.Success);
        setQuestionToDelete(null);
        queryClient.invalidateQueries(['questions']);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  }, [queryClient, removeQuestion, questionToDelete]);

  const openConfirmationModal = useCallback((selectedQuestion: Question | null) => {
    if (selectedQuestion) setQuestionToDelete(selectedQuestion);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setQuestionToDelete(null);
  }, []);

  const providerValue = useMemo(
    () => ({
      questions,
      metaData,
      questionsLoading,
      currentPage,
      questionToDelete,
      onPageChange,
      onPageSizeChange,
      onSortChange,
      openConfirmationModal,
      closeConfirmationModal,
      onDeleteConfirm,
      onRowClick,
      onNewClick,
    }),
    [
      questions,
      metaData,
      questionsLoading,
      currentPage,
      questionToDelete,
      onPageChange,
      onPageSizeChange,
      onSortChange,
      openConfirmationModal,
      closeConfirmationModal,
      onDeleteConfirm,
      onRowClick,
      onNewClick,
    ],
  );

  return <QuestionsContext.Provider value={providerValue}>{children}</QuestionsContext.Provider>;
};

export const useQuestionsProvider = () => {
  return useContext(QuestionsContext);
};

interface Props {
  children?: React.ReactNode;
}
