export const LANGUAGES = [
  {
    code: 'ab',
    name: 'Abkhaz',
  },
  {
    code: 'aa',
    name: 'Afar',
  },
  {
    code: 'af',
    name: 'Afrikaans',
  },
  {
    code: 'ak',
    name: 'Akan',
  },
  {
    code: 'sq',
    name: 'Albanian',
  },
  {
    code: 'am',
    name: 'Amharic',
  },
  {
    code: 'ar',
    name: 'Arabic',
  },
  {
    code: 'an',
    name: 'Aragonese',
  },
  {
    code: 'hy',
    name: 'Armenian',
  },
  {
    code: 'as',
    name: 'Assamese',
  },
  {
    code: 'av',
    name: 'Avaric',
  },
  {
    code: 'ae',
    name: 'Avestan',
  },
  {
    code: 'ay',
    name: 'Aymara',
  },
  {
    code: 'az',
    name: 'Azerbaijani',
  },
  {
    code: 'bm',
    name: 'Bambara',
  },
  {
    code: 'ba',
    name: 'Bashkir',
  },
  {
    code: 'eu',
    name: 'Basque',
  },
  {
    code: 'be',
    name: 'Belarusian',
  },
  {
    code: 'bn',
    name: 'Bengali; Bangla',
  },
  {
    code: 'bh',
    name: 'Bihari',
  },
  {
    code: 'bi',
    name: 'Bislama',
  },
  {
    code: 'bs',
    name: 'Bosnian',
  },
  {
    code: 'br',
    name: 'Breton',
  },
  {
    code: 'bg',
    name: 'Bulgarian',
  },
  {
    code: 'my',
    name: 'Burmese',
  },
  {
    code: 'ca',
    name: 'Catalan; Valencian',
  },
  {
    code: 'ch',
    name: 'Chamorro',
  },
  {
    code: 'ce',
    name: 'Chechen',
  },
  {
    code: 'ny',
    name: 'Chichewa; Chewa; Nyanja',
  },
  {
    code: 'zh',
    name: 'Chinese',
  },
  {
    code: 'cv',
    name: 'Chuvash',
  },
  {
    code: 'kw',
    name: 'Cornish',
  },
  {
    code: 'co',
    name: 'Corsican',
  },
  {
    code: 'cr',
    name: 'Cree',
  },
  {
    code: 'hr',
    name: 'Croatian',
  },
  {
    code: 'cs',
    name: 'Czech',
  },
  {
    code: 'da',
    name: 'Danish',
  },
  {
    code: 'dv',
    name: 'Divehi; Dhivehi; Maldivian;',
  },
  {
    code: 'nl',
    name: 'Dutch',
  },
  {
    code: 'dz',
    name: 'Dzongkha',
  },
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'eo',
    name: 'Esperanto',
  },
  {
    code: 'et',
    name: 'Estonian',
  },
  {
    code: 'ee',
    name: 'Ewe',
  },
  {
    code: 'fo',
    name: 'Faroese',
  },
  {
    code: 'fj',
    name: 'Fijian',
  },
  {
    code: 'fi',
    name: 'Finnish',
  },
  {
    code: 'fr',
    name: 'French',
  },
  {
    code: 'ff',
    name: 'Fula; Fulah; Pulaar; Pular',
  },
  {
    code: 'gl',
    name: 'Galician',
  },
  {
    code: 'ka',
    name: 'Georgian',
  },
  {
    code: 'de',
    name: 'German',
  },
  {
    code: 'el',
    name: 'Greek, Modern',
  },
  {
    code: 'gn',
    name: 'GuaranÃ­',
  },
  {
    code: 'gu',
    name: 'Gujarati',
  },
  {
    code: 'ht',
    name: 'Haitian; Haitian Creole',
  },
  {
    code: 'ha',
    name: 'Hausa',
  },
  {
    code: 'he',
    name: 'Hebrew (modern)',
  },
  {
    code: 'hz',
    name: 'Herero',
  },
  {
    code: 'hi',
    name: 'Hindi',
  },
  {
    code: 'ho',
    name: 'Hiri Motu',
  },
  {
    code: 'hu',
    name: 'Hungarian',
  },
  {
    code: 'ia',
    name: 'Interlingua',
  },
  {
    code: 'id',
    name: 'Indonesian',
  },
  {
    code: 'ie',
    name: 'Interlingue',
  },
  {
    code: 'ga',
    name: 'Irish',
  },
  {
    code: 'ig',
    name: 'Igbo',
  },
  {
    code: 'ik',
    name: 'Inupiaq',
  },
  {
    code: 'io',
    name: 'Ido',
  },
  {
    code: 'is',
    name: 'Icelandic',
  },
  {
    code: 'it',
    name: 'Italian',
  },
  {
    code: 'iu',
    name: 'Inuktitut',
  },
  {
    code: 'ja',
    name: 'Japanese',
  },
  {
    code: 'jv',
    name: 'Javanese',
  },
  {
    code: 'kl',
    name: 'Kalaallisut, Greenlandic',
  },
  {
    code: 'kn',
    name: 'Kannada',
  },
  {
    code: 'kr',
    name: 'Kanuri',
  },
  {
    code: 'ks',
    name: 'Kashmiri',
  },
  {
    code: 'kk',
    name: 'Kazakh',
  },
  {
    code: 'km',
    name: 'Khmer',
  },
  {
    code: 'ki',
    name: 'Kikuyu, Gikuyu',
  },
  {
    code: 'rw',
    name: 'Kinyarwanda',
  },
  {
    code: 'ky',
    name: 'Kyrgyz',
  },
  {
    code: 'kv',
    name: 'Komi',
  },
  {
    code: 'kg',
    name: 'Kongo',
  },
  {
    code: 'ko',
    name: 'Korean',
  },
  {
    code: 'ku',
    name: 'Kurdish',
  },
  {
    code: 'kj',
    name: 'Kwanyama, Kuanyama',
  },
  {
    code: 'la',
    name: 'Latin',
  },
  {
    code: 'lb',
    name: 'Luxembourgish, Letzeburgesch',
  },
  {
    code: 'lg',
    name: 'Ganda',
  },
  {
    code: 'li',
    name: 'Limburgish, Limburgan, Limburger',
  },
  {
    code: 'ln',
    name: 'Lingala',
  },
  {
    code: 'lo',
    name: 'Lao',
  },
  {
    code: 'lt',
    name: 'Lithuanian',
  },
  {
    code: 'lu',
    name: 'Luba-Katanga',
  },
  {
    code: 'lv',
    name: 'Latvian',
  },
  {
    code: 'gv',
    name: 'Manx',
  },
  {
    code: 'mk',
    name: 'Macedonian',
  },
  {
    code: 'mg',
    name: 'Malagasy',
  },
  {
    code: 'ms',
    name: 'Malay',
  },
  {
    code: 'ml',
    name: 'Malayalam',
  },
  {
    code: 'mt',
    name: 'Maltese',
  },
  {
    code: 'mi',
    name: 'MÄori',
  },
  {
    code: 'mr',
    name: 'Marathi (MarÄá¹­hÄ«)',
  },
  {
    code: 'mh',
    name: 'Marshallese',
  },
  {
    code: 'mn',
    name: 'Mongolian',
  },
  {
    code: 'na',
    name: 'Nauru',
  },
  {
    code: 'nv',
    name: 'Navajo, Navaho',
  },
  {
    code: 'nb',
    name: 'Norwegian BokmÃ¥l',
  },
  {
    code: 'nd',
    name: 'North Ndebele',
  },
  {
    code: 'ne',
    name: 'Nepali',
  },
  {
    code: 'ng',
    name: 'Ndonga',
  },
  {
    code: 'nn',
    name: 'Norwegian Nynorsk',
  },
  {
    code: 'no',
    name: 'Norwegian',
  },
  {
    code: 'ii',
    name: 'Nuosu',
  },
  {
    code: 'nr',
    name: 'South Ndebele',
  },
  {
    code: 'oc',
    name: 'Occitan',
  },
  {
    code: 'oj',
    name: 'Ojibwe, Ojibwa',
  },
  {
    code: 'om',
    name: 'Oromo',
  },
  {
    code: 'or',
    name: 'Oriya',
  },
  {
    code: 'os',
    name: 'Ossetian, Ossetic',
  },
  {
    code: 'pa',
    name: 'Panjabi, Punjabi',
  },
  {
    code: 'pi',
    name: 'PÄli',
  },
  {
    code: 'fa',
    name: 'Persian (Farsi)',
  },
  {
    code: 'pl',
    name: 'Polish',
  },
  {
    code: 'ps',
    name: 'Pashto, Pushto',
  },
  {
    code: 'pt',
    name: 'Portuguese',
  },
  {
    code: 'qu',
    name: 'Quechua',
  },
  {
    code: 'rm',
    name: 'Romansh',
  },
  {
    code: 'rn',
    name: 'Kirundi',
  },
  {
    code: 'ro',
    name: 'Romanian, [])',
  },
  {
    code: 'ru',
    name: 'Russian',
  },
  {
    code: 'sa',
    name: 'Sanskrit (Saá¹ská¹›ta)',
  },
  {
    code: 'sc',
    name: 'Sardinian',
  },
  {
    code: 'sd',
    name: 'Sindhi',
  },
  {
    code: 'se',
    name: 'Northern Sami',
  },
  {
    code: 'sm',
    name: 'Samoan',
  },
  {
    code: 'sg',
    name: 'Sango',
  },
  {
    code: 'sr',
    name: 'Serbian',
  },
  {
    code: 'gd',
    name: 'Scottish Gaelic; Gaelic',
  },
  {
    code: 'sn',
    name: 'Shona',
  },
  {
    code: 'si',
    name: 'Sinhala, Sinhalese',
  },
  {
    code: 'sk',
    name: 'Slovak',
  },
  {
    code: 'sl',
    name: 'Slovene',
  },
  {
    code: 'so',
    name: 'Somali',
  },
  {
    code: 'st',
    name: 'Southern Sotho',
  },
  {
    code: 'es',
    name: 'Spanish; Castilian',
  },
  {
    code: 'su',
    name: 'Sundanese',
  },
  {
    code: 'sw',
    name: 'Swahili',
  },
  {
    code: 'ss',
    name: 'Swati',
  },
  {
    code: 'sv',
    name: 'Swedish',
  },
  {
    code: 'ta',
    name: 'Tamil',
  },
  {
    code: 'te',
    name: 'Telugu',
  },
  {
    code: 'tg',
    name: 'Tajik',
  },
  {
    code: 'th',
    name: 'Thai',
  },
  {
    code: 'ti',
    name: 'Tigrinya',
  },
  {
    code: 'bo',
    name: 'Tibetan Standard, Tibetan, Central',
  },
  {
    code: 'tk',
    name: 'Turkmen',
  },
  {
    code: 'tl',
    name: 'Tagalog',
  },
  {
    code: 'tn',
    name: 'Tswana',
  },
  {
    code: 'to',
    name: 'Tonga (Tonga Islands)',
  },
  {
    code: 'tr',
    name: 'Turkish',
  },
  {
    code: 'ts',
    name: 'Tsonga',
  },
  {
    code: 'tt',
    name: 'Tatar',
  },
  {
    code: 'tw',
    name: 'Twi',
  },
  {
    code: 'ty',
    name: 'Tahitian',
  },
  {
    code: 'ug',
    name: 'Uyghur, Uighur',
  },
  {
    code: 'uk',
    name: 'Ukrainian',
  },
  {
    code: 'ur',
    name: 'Urdu',
  },
  {
    code: 'uz',
    name: 'Uzbek',
  },
  {
    code: 've',
    name: 'Venda',
  },
  {
    code: 'vi',
    name: 'Vietnamese',
  },
  {
    code: 'vo',
    name: 'VolapÃ¼k',
  },
  {
    code: 'wa',
    name: 'Walloon',
  },
  {
    code: 'cy',
    name: 'Welsh',
  },
  {
    code: 'wo',
    name: 'Wolof',
  },
  {
    code: 'fy',
    name: 'Western Frisian',
  },
  {
    code: 'xh',
    name: 'Xhosa',
  },
  {
    code: 'yi',
    name: 'Yiddish',
  },
  {
    code: 'yo',
    name: 'Yoruba',
  },
  {
    code: 'za',
    name: 'Zhuang, Chuang',
  },
  {
    code: 'zu',
    name: 'Zulu',
  },
];

export const COUNTRIES = [
  {
    country: 'Afghanistan',
    abbreviation: 'AF',
  },
  {
    country: 'Albania',
    abbreviation: 'AL',
  },
  {
    country: 'Algeria',
    abbreviation: 'DZ',
  },
  {
    country: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    country: 'Andorra',
    abbreviation: 'AD',
  },
  {
    country: 'Angola',
    abbreviation: 'AO',
  },
  {
    country: 'Anguilla',
    abbreviation: 'AI',
  },
  {
    country: 'Antarctica',
    abbreviation: 'AQ',
  },
  {
    country: 'Antigua and Barbuda',
    abbreviation: 'AG',
  },
  {
    country: 'Argentina',
    abbreviation: 'AR',
  },
  {
    country: 'Armenia',
    abbreviation: 'AM',
  },
  {
    country: 'Aruba',
    abbreviation: 'AW',
  },
  {
    country: 'Australia',
    abbreviation: 'AU',
  },
  {
    country: 'Austria',
    abbreviation: 'AT',
  },
  {
    country: 'Azerbaijan',
    abbreviation: 'AZ',
  },
  {
    country: 'Bahamas',
    abbreviation: 'BS',
  },
  {
    country: 'Bahrain',
    abbreviation: 'BH',
  },
  {
    country: 'Bangladesh',
    abbreviation: 'BD',
  },
  {
    country: 'Barbados',
    abbreviation: 'BB',
  },
  {
    country: 'Belarus',
    abbreviation: 'BY',
  },
  {
    country: 'Belgium',
    abbreviation: 'BE',
  },
  {
    country: 'Belize',
    abbreviation: 'BZ',
  },
  {
    country: 'Benin',
    abbreviation: 'BJ',
  },
  {
    country: 'Bermuda',
    abbreviation: 'BM',
  },
  {
    country: 'Bhutan',
    abbreviation: 'BT',
  },
  {
    country: 'Bolivia',
    abbreviation: 'BO',
  },
  {
    country: 'Bosnia and Herzegovina',
    abbreviation: 'BA',
  },
  {
    country: 'Botswana',
    abbreviation: 'BW',
  },
  {
    country: 'Bouvet Island',
    abbreviation: 'BV',
  },
  {
    country: 'Brazil',
    abbreviation: 'BR',
  },
  {
    country: 'British Indian Ocean Territory',
    abbreviation: 'IO',
  },
  {
    country: 'Brunei',
    abbreviation: 'BN',
  },
  {
    country: 'Bulgaria',
    abbreviation: 'BG',
  },
  {
    country: 'Burkina Faso',
    abbreviation: 'BF',
  },
  {
    country: 'Burundi',
    abbreviation: 'BI',
  },
  {
    country: 'Cambodia',
    abbreviation: 'KH',
  },
  {
    country: 'Cameroon',
    abbreviation: 'CM',
  },
  {
    country: 'Canada',
    abbreviation: 'CA',
  },
  {
    country: 'Cape Verde',
    abbreviation: 'CV',
  },
  {
    country: 'Cayman Islands',
    abbreviation: 'KY',
  },
  {
    country: 'Central African Republic',
    abbreviation: 'CF',
  },
  {
    country: 'Chad',
    abbreviation: 'TD',
  },
  {
    country: 'Chile',
    abbreviation: 'CL',
  },
  {
    country: 'China',
    abbreviation: 'CN',
  },
  {
    country: 'Christmas Island',
    abbreviation: 'CX',
  },
  {
    country: 'Cocos (Keeling) Islands',
    abbreviation: 'CC',
  },
  {
    country: 'Colombia',
    abbreviation: 'CO',
  },
  {
    country: 'Comoros',
    abbreviation: 'KM',
  },
  {
    country: 'Congo',
    abbreviation: 'CG',
  },
  {
    country: 'Cook Islands',
    abbreviation: 'CK',
  },
  {
    country: 'Costa Rica',
    abbreviation: 'CR',
  },
  {
    country: 'Croatia',
    abbreviation: 'HR',
  },
  {
    country: 'Cuba',
    abbreviation: 'CU',
  },
  {
    country: 'Cyprus',
    abbreviation: 'CY',
  },
  {
    country: 'Czech Republic',
    abbreviation: 'CZ',
  },
  {
    country: 'Denmark',
    abbreviation: 'DK',
  },
  {
    country: 'Djibouti',
    abbreviation: 'DJ',
  },
  {
    country: 'Dominica',
    abbreviation: 'DM',
  },
  {
    country: 'Dominican Republic',
    abbreviation: 'DO',
  },
  {
    country: 'East Timor',
    abbreviation: 'TP',
  },
  {
    country: 'Ecuador',
    abbreviation: 'EC',
  },
  {
    country: 'Egypt',
    abbreviation: 'EG',
  },
  {
    country: 'El Salvador',
    abbreviation: 'SV',
  },
  {
    country: 'Equatorial Guinea',
    abbreviation: 'GQ',
  },
  {
    country: 'Eritrea',
    abbreviation: 'ER',
  },
  {
    country: 'Estonia',
    abbreviation: 'EE',
  },
  {
    country: 'Ethiopia',
    abbreviation: 'ET',
  },
  {
    country: 'Falkland Islands',
    abbreviation: 'FK',
  },
  {
    country: 'Faroe Islands',
    abbreviation: 'FO',
  },
  {
    country: 'Fiji Islands',
    abbreviation: 'FJ',
  },
  {
    country: 'Finland',
    abbreviation: 'FI',
  },
  {
    country: 'France',
    abbreviation: 'FR',
  },
  {
    country: 'French Guiana',
    abbreviation: 'GF',
  },
  {
    country: 'French Polynesia',
    abbreviation: 'PF',
  },
  {
    country: 'French Southern territories',
    abbreviation: 'TF',
  },
  {
    country: 'Gabon',
    abbreviation: 'GA',
  },
  {
    country: 'Gambia',
    abbreviation: 'GM',
  },
  {
    country: 'Georgia',
    abbreviation: 'GE',
  },
  {
    country: 'Germany',
    abbreviation: 'DE',
  },
  {
    country: 'Ghana',
    abbreviation: 'GH',
  },
  {
    country: 'Gibraltar',
    abbreviation: 'GI',
  },
  {
    country: 'Greece',
    abbreviation: 'GR',
  },
  {
    country: 'Greenland',
    abbreviation: 'GL',
  },
  {
    country: 'Grenada',
    abbreviation: 'GD',
  },
  {
    country: 'Guadeloupe',
    abbreviation: 'GP',
  },
  {
    country: 'Guam',
    abbreviation: 'GU',
  },
  {
    country: 'Guatemala',
    abbreviation: 'GT',
  },
  {
    country: 'Guernsey',
    abbreviation: 'GG',
  },
  {
    country: 'Guinea',
    abbreviation: 'GN',
  },
  {
    country: 'Guinea-Bissau',
    abbreviation: 'GW',
  },
  {
    country: 'Guyana',
    abbreviation: 'GY',
  },
  {
    country: 'Haiti',
    abbreviation: 'HT',
  },
  {
    country: 'Heard Island and McDonald Islands',
    abbreviation: 'HM',
  },
  {
    country: 'Holy See (Vatican City State)',
    abbreviation: 'VA',
  },
  {
    country: 'Honduras',
    abbreviation: 'HN',
  },
  {
    country: 'Hong Kong',
    abbreviation: 'HK',
  },
  {
    country: 'Hungary',
    abbreviation: 'HU',
  },
  {
    country: 'Iceland',
    abbreviation: 'IS',
  },
  {
    country: 'India',
    abbreviation: 'IN',
  },
  {
    country: 'Indonesia',
    abbreviation: 'ID',
  },
  {
    country: 'Iran',
    abbreviation: 'IR',
  },
  {
    country: 'Iraq',
    abbreviation: 'IQ',
  },
  {
    country: 'Ireland',
    abbreviation: 'IE',
  },
  {
    country: 'Isle of Man',
    abbreviation: 'IM',
  },
  {
    country: 'Israel',
    abbreviation: 'IL',
  },
  {
    country: 'Italy',
    abbreviation: 'IT',
  },
  {
    country: 'Ivory Coast',
    abbreviation: 'CI',
  },
  {
    country: 'Jamaica',
    abbreviation: 'JM',
  },
  {
    country: 'Japan',
    abbreviation: 'JP',
  },
  {
    country: 'Jersey',
    abbreviation: 'JE',
  },
  {
    country: 'Jordan',
    abbreviation: 'JO',
  },
  {
    country: 'Kazakhstan',
    abbreviation: 'KZ',
  },
  {
    country: 'Kenya',
    abbreviation: 'KE',
  },
  {
    country: 'Kiribati',
    abbreviation: 'KI',
  },
  {
    country: 'Kuwait',
    abbreviation: 'KW',
  },
  {
    country: 'Kyrgyzstan',
    abbreviation: 'KG',
  },
  {
    country: 'Laos',
    abbreviation: 'LA',
  },
  {
    country: 'Latvia',
    abbreviation: 'LV',
  },
  {
    country: 'Lebanon',
    abbreviation: 'LB',
  },
  {
    country: 'Lesotho',
    abbreviation: 'LS',
  },
  {
    country: 'Liberia',
    abbreviation: 'LR',
  },
  {
    country: 'Libyan Arab Jamahiriya',
    abbreviation: 'LY',
  },
  {
    country: 'Liechtenstein',
    abbreviation: 'LI',
  },
  {
    country: 'Lithuania',
    abbreviation: 'LT',
  },
  {
    country: 'Luxembourg',
    abbreviation: 'LU',
  },
  {
    country: 'Macao',
    abbreviation: 'MO',
  },
  {
    country: 'North Macedonia',
    abbreviation: 'MK',
  },
  {
    country: 'Madagascar',
    abbreviation: 'MG',
  },
  {
    country: 'Malawi',
    abbreviation: 'MW',
  },
  {
    country: 'Malaysia',
    abbreviation: 'MY',
  },
  {
    country: 'Maldives',
    abbreviation: 'MV',
  },
  {
    country: 'Mali',
    abbreviation: 'ML',
  },
  {
    country: 'Malta',
    abbreviation: 'MT',
  },
  {
    country: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    country: 'Martinique',
    abbreviation: 'MQ',
  },
  {
    country: 'Mauritania',
    abbreviation: 'MR',
  },
  {
    country: 'Mauritius',
    abbreviation: 'MU',
  },
  {
    country: 'Mayotte',
    abbreviation: 'YT',
  },
  {
    country: 'Mexico',
    abbreviation: 'MX',
  },
  {
    country: 'Micronesia, Federated States of',
    abbreviation: 'FM',
  },
  {
    country: 'Moldova',
    abbreviation: 'MD',
  },
  {
    country: 'Monaco',
    abbreviation: 'MC',
  },
  {
    country: 'Mongolia',
    abbreviation: 'MN',
  },
  {
    country: 'Montenegro',
    abbreviation: 'ME',
  },
  {
    country: 'Montserrat',
    abbreviation: 'MS',
  },
  {
    country: 'Morocco',
    abbreviation: 'MA',
  },
  {
    country: 'Mozambique',
    abbreviation: 'MZ',
  },
  {
    country: 'Myanmar',
    abbreviation: 'MM',
  },
  {
    country: 'Namibia',
    abbreviation: 'NA',
  },
  {
    country: 'Nauru',
    abbreviation: 'NR',
  },
  {
    country: 'Nepal',
    abbreviation: 'NP',
  },
  {
    country: 'Netherlands',
    abbreviation: 'NL',
  },
  {
    country: 'Netherlands Antilles',
    abbreviation: 'AN',
  },
  {
    country: 'New Caledonia',
    abbreviation: 'NC',
  },
  {
    country: 'New Zealand',
    abbreviation: 'NZ',
  },
  {
    country: 'Nicaragua',
    abbreviation: 'NI',
  },
  {
    country: 'Niger',
    abbreviation: 'NE',
  },
  {
    country: 'Nigeria',
    abbreviation: 'NG',
  },
  {
    country: 'Niue',
    abbreviation: 'NU',
  },
  {
    country: 'Norfolk Island',
    abbreviation: 'NF',
  },
  {
    country: 'North Korea',
    abbreviation: 'KP',
  },
  {
    country: 'Northern Ireland',
    abbreviation: 'GB',
  },
  {
    country: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    country: 'Norway',
    abbreviation: 'NO',
  },
  {
    country: 'Oman',
    abbreviation: 'OM',
  },
  {
    country: 'Pakistan',
    abbreviation: 'PK',
  },
  {
    country: 'Palau',
    abbreviation: 'PW',
  },
  {
    country: 'Palestine',
    abbreviation: 'PS',
  },
  {
    country: 'Panama',
    abbreviation: 'PA',
  },
  {
    country: 'Papua New Guinea',
    abbreviation: 'PG',
  },
  {
    country: 'Paraguay',
    abbreviation: 'PY',
  },
  {
    country: 'Peru',
    abbreviation: 'PE',
  },
  {
    country: 'Philippines',
    abbreviation: 'PH',
  },
  {
    country: 'Pitcairn',
    abbreviation: 'PN',
  },
  {
    country: 'Poland',
    abbreviation: 'PL',
  },
  {
    country: 'Portugal',
    abbreviation: 'PT',
  },
  {
    country: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    country: 'Qatar',
    abbreviation: 'QA',
  },
  {
    country: 'Reunion',
    abbreviation: 'RE',
  },
  {
    country: 'Romania',
    abbreviation: 'RO',
  },
  {
    country: 'Russian Federation',
    abbreviation: 'RU',
  },
  {
    country: 'Rwanda',
    abbreviation: 'RW',
  },
  {
    country: 'Saint Helena',
    abbreviation: 'SH',
  },
  {
    country: 'Saint Kitts and Nevis',
    abbreviation: 'KN',
  },
  {
    country: 'Saint Lucia',
    abbreviation: 'LC',
  },
  {
    country: 'Saint Pierre and Miquelon',
    abbreviation: 'PM',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    abbreviation: 'VC',
  },
  {
    country: 'Samoa',
    abbreviation: 'WS',
  },
  {
    country: 'San Marino',
    abbreviation: 'SM',
  },
  {
    country: 'Sao Tome and Principe',
    abbreviation: 'ST',
  },
  {
    country: 'Saudi Arabia',
    abbreviation: 'SA',
  },
  {
    country: 'Senegal',
    abbreviation: 'SN',
  },
  {
    country: 'Serbia',
    abbreviation: 'RS',
  },
  {
    country: 'Seychelles',
    abbreviation: 'SC',
  },
  {
    country: 'Sierra Leone',
    abbreviation: 'SL',
  },
  {
    country: 'Singapore',
    abbreviation: 'SG',
  },
  {
    country: 'Slovakia',
    abbreviation: 'SK',
  },
  {
    country: 'Slovenia',
    abbreviation: 'SI',
  },
  {
    country: 'Solomon Islands',
    abbreviation: 'SB',
  },
  {
    country: 'Somalia',
    abbreviation: 'SO',
  },
  {
    country: 'South Africa',
    abbreviation: 'ZA',
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    abbreviation: 'GS',
  },
  {
    country: 'South Korea',
    abbreviation: 'KR',
  },
  {
    country: 'South Sudan',
    abbreviation: 'SS',
  },
  {
    country: 'Spain',
    abbreviation: 'ES',
  },
  {
    country: 'Sri Lanka',
    abbreviation: 'LK',
  },
  {
    country: 'Sudan',
    abbreviation: 'SD',
  },
  {
    country: 'Suriname',
    abbreviation: 'SR',
  },
  {
    country: 'Svalbard and Jan Mayen',
    abbreviation: 'SJ',
  },
  {
    country: 'Swaziland',
    abbreviation: 'SZ',
  },
  {
    country: 'Sweden',
    abbreviation: 'SE',
  },
  {
    country: 'Switzerland',
    abbreviation: 'CH',
  },
  {
    country: 'Syria',
    abbreviation: 'SY',
  },
  {
    country: 'Tajikistan',
    abbreviation: 'TJ',
  },
  {
    country: 'Tanzania',
    abbreviation: 'TZ',
  },
  {
    country: 'Thailand',
    abbreviation: 'TH',
  },
  {
    country: 'The Democratic Republic of Congo',
    abbreviation: 'CD',
  },
  {
    country: 'Timor-Leste',
    abbreviation: 'TL',
  },
  {
    country: 'Togo',
    abbreviation: 'TG',
  },
  {
    country: 'Tokelau',
    abbreviation: 'TK',
  },
  {
    country: 'Tonga',
    abbreviation: 'TO',
  },
  {
    country: 'Trinidad and Tobago',
    abbreviation: 'TT',
  },
  {
    country: 'Tunisia',
    abbreviation: 'TN',
  },
  {
    country: 'Turkey',
    abbreviation: 'TR',
  },
  {
    country: 'Turkmenistan',
    abbreviation: 'TM',
  },
  {
    country: 'Turks and Caicos Islands',
    abbreviation: 'TC',
  },
  {
    country: 'Tuvalu',
    abbreviation: 'TV',
  },
  {
    country: 'Uganda',
    abbreviation: 'UG',
  },
  {
    country: 'Ukraine',
    abbreviation: 'UA',
  },
  {
    country: 'United Arab Emirates',
    abbreviation: 'AE',
  },
  {
    country: 'United Kingdom',
    abbreviation: 'UK',
  },
  {
    country: 'United States',
    abbreviation: 'US',
  },
  {
    country: 'United States Minor Outlying Islands',
    abbreviation: 'UM',
  },
  {
    country: 'Uruguay',
    abbreviation: 'UY',
  },
  {
    country: 'Uzbekistan',
    abbreviation: 'UZ',
  },
  {
    country: 'Vanuatu',
    abbreviation: 'VU',
  },
  {
    country: 'Venezuela',
    abbreviation: 'VE',
  },
  {
    country: 'Vietnam',
    abbreviation: 'VN',
  },
  {
    country: 'Virgin Islands, British',
    abbreviation: 'VG',
  },
  {
    country: 'Virgin Islands, U.S.',
    abbreviation: 'VI',
  },
  {
    country: 'Wallis and Futuna',
    abbreviation: 'WF',
  },
  {
    country: 'Western Sahara',
    abbreviation: 'EH',
  },
  {
    country: 'Yemen',
    abbreviation: 'YE',
  },
  {
    country: 'Zambia',
    abbreviation: 'ZM',
  },
  {
    country: 'Zimbabwe',
    abbreviation: 'ZW',
  },
];
