import { useMutation } from '@tanstack/react-query';

import { api } from 'services';
import { resToActiv, resToAuthData } from './transformations';
import { ActivData, AuthData, LoginForm, PassResetForm, RegisterForm } from './types';

const login = async (data: LoginForm): Promise<AuthData> => {
  const resp = await api.post('/users/auth/authenticate', data);
  return resToAuthData(resp.data);
};

const register = async (data: RegisterForm): Promise<AuthData> => {
  const resp = await api.post('/users/auth/register', data);
  return resToAuthData(resp.data);
};

export const validateEmail = async (email: string): Promise<boolean> => {
  const resp = await api.get(`/users/email?email=${email}`);
  return resp.data.exists;
};

export const forgot = async (data: RegisterForm) => {
  const resp = await api.post('/users/auth/forgot-password', data);
  return resp.data;
};

export const reset = async (email: string, code: string, data: PassResetForm) => {
  const resp = await api.post('/users/auth/reset-password', {
    email: email,
    passwordResetCode: code,
    password: data.password,
  });
  return resp.data;
};

export const useLogin = () => {
  const { mutateAsync: postLogin, ...rest } = useMutation(login);
  return { postLogin, ...rest };
};

export const useRegister = () => {
  const { mutateAsync: postRegister, ...rest } = useMutation(register);
  return { postRegister, ...rest };
};

export const activate = async (email: string, code: string): Promise<ActivData> => {
  const resp = await api.post('/users/auth/activate', {
    email: email,
    activationCode: code,
  });
  return resToActiv(resp.data);
};
