import { PlusIcon } from '@heroicons/react/outline';
import { Button, ConfirmationModal, Container, Header, HeaderSearch, Table } from 'components';
import { Tag } from 'services';
import { columns } from './const';
import { useTagsPage } from './hooks';

export function TagsPage() {
  const {
    globalSearch,
    onSearchChange,
    onNewClick,
    onRowClick,
    tags,
    tagCategories,
    metaData,
    currentPage,
    isLoading,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    tagToDelete,
    onDeleteConfirm,
    closeConfirmationModal,
    openConfirmationModal,
  } = useTagsPage();

  return (
    <>
      <Header title="Tags">
        <div className="flex-1 flex justify-between">
          <HeaderSearch onChange={(e) => onSearchChange(e.target.value)} value={globalSearch} />

          <Button onClick={onNewClick} Icon={PlusIcon}>
            New tag
          </Button>
        </div>
      </Header>

      <Container className="overflow-hidden">
        <Table<Tag>
          data={tags}
          columns={columns(tagCategories, openConfirmationModal)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title={`Delete ${tagToDelete?.name}?`}
        body={`Are you sure you want to delete ${tagToDelete?.name}?`}
        open={!!tagToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
}
