import { ArticleForm, ArticleTranslationForm } from 'services';
import { ArticleStatus } from 'services/articles/const';
import * as yup from 'yup';

export const defaultTranslation: ArticleTranslationForm = {
  id: null,
  language: 'en',
  name: '',
  text: '',
  description: '',
  fileId: '',
  fileUrl: '',
  file: {
    id: '',
    url: '',
  },
};

export const defaultArticleForm: ArticleForm = {
  id: '',
  name: '',
  originalLanguage: '',
  authorId: '',
  authorName: '',
  tags: [],
  translations: [defaultTranslation],
  vettedAt: '',
  vettingStatus: ArticleStatus.PENDING,
};

export const ARTICLE_VALIDATION_SCHEMA = yup.object({
  translations: yup
    .array(
      yup.object({
        name: yup.string().label('Name').required(),
        description: yup.string().label('Description').required(),
        text: yup.string().label('Text').required(),
      }),
    )
    .label('Translations')
    .required(),
  authorName: yup.string().label('Publisher').required(),
});
