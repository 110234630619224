import { Tag, TagCategory } from './types';

export function resToTag(res: any): Tag {
  return {
    id: res.id ?? '',
    name: res.name ?? '',
    tagCategoryId: res.tagCategoryId ?? '',
  };
}

export function resToTagCategory(res: any): TagCategory {
  return {
    id: res.id ?? '',
    name: res.name ?? '',
  };
}

export function tagToPutReq(data: any) {
  return {
    name: data.name,
    tagCategoryId: data.tagCategoryId,
  };
}

export function tagToPostReq(data: any) {
  return {
    name: data.name,
    tagCategoryId: data.tagCategoryId,
  };
}

export function categoryToPutReq(data: any) {
  return {
    name: data.name,
  };
}

export function categoryToPostReq(data: any) {
  return {
    name: data.name,
  };
}

export function tagCategoryToPutReq(data: any) {
  return {
    name: data.name,
  };
}
