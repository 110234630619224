import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { GlobalProvider } from 'GlobalProvider';
import { AdminLayout, UnauthLayout, UserLayout } from 'components';
import {
  UserPage,
  UserPageProvider,
  UsersPage,
  UsersProvider,
  DashboardPage,
  TagsProvider,
  TagsPage,
  TagPageProvider,
  TagPage,
  TagCategoryPageProvider,
  TagCategoriesPage,
  TagCategoriesProvider,
  TagCategoryPage,
} from 'modules/admin';
import { PrivacyPolicy, SignIn, SignUp } from 'modules/common';
import {
  DocumentPage,
  DocumentsPage,
  DocumentsProvider,
  DocumentFormPage,
  DocumentProvider,
} from 'modules/user/Documents';
import { ArticlesPage, ArticlesProvider, ArticlePage, ArticleProvider, ArticleFormPage } from 'modules/user/Articles';
import { ActivationPage, WaitingForActivationPage } from 'modules/activation';
import { ForgotPasswordPage, ResetPasswordPage } from 'modules/forgotPassword';
import { ContributorsProvider, ContributorsPage, ContributorPage } from 'modules/user/Contributors';
import { DashboardPageProvider } from 'modules/admin/Dashboard/DashboardPage/DashboardPageProvider';
import {
  AdminArticlesPage,
  AdminArticlesPageProvider,
  AdminArticlesProvider,
  AdminArticleFormPage,
} from 'modules/admin/Articles';
import {
  AdminDocumentsPage,
  AdminDocumentsPageProvider,
  AdminDocumentsProvider,
  AdminDocumentFormPage,
} from 'modules/admin/Documents';
import { ProfilePage, ProfilePageProvider } from 'modules/user/Profile';
import { AdminProfilePage, AdminProfilePageProvider } from 'modules/admin/Profile';
import { QAPage, QuestionProvider } from 'modules/user';
import { QuestionsProvider } from 'modules/admin/QA/QuestionsPage';
import { QuestionsPage } from 'modules/admin/QA/QuestionsPage/QuestionsPage';
import { QuestionPage, QuestionPageProvider } from 'modules/admin/QA/QuestionPage';
import { NewslettersPage, NewslettersPageProvider, NewslettersProvider } from 'modules/user/Newsletter';
import {
  AdminNewsletterProvider,
  AdminNewslettersPage,
  AdminNewslettersPageProvider,
  NewsletterPage,
  NewsletterPageProvider,
} from 'modules/admin/Newsletter';
import { SectionsProvider, SectionsPageProvider, SectionsPage } from 'modules/admin/Sections';
import { SectionPage, SectionPageProvider } from 'modules/admin/Sections/SectionPage';
import { RestoreAccountPage, RestoreAccountPageProvider } from 'modules/admin/RestoreAccount';
import { DocumentsPageProvider } from 'modules/user/Documents/DocumentsPage/DocumentsPageProvider';
import { FAQPage } from 'modules/user/FAQ';
import { ContactPage } from 'modules/user/Contact';
import { LandingPage } from 'modules/common/LandingPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
dayjs.extend(localizedFormat);

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link
              href="https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&family=Merriweather&display=swap"
              rel="stylesheet"
            />
          </Helmet>

          <Routes>
            <Route path="/" element={<UserLayout />}>
              <Route path="documents" element={<DocumentsProvider />}>
                <Route element={<DocumentsPageProvider />}>
                  <Route index element={<DocumentsPage />}></Route>
                  <Route element={<DocumentProvider />}>
                    <Route path="/documents/:docId" element={<DocumentPage />} />
                    <Route path="/documents/new" element={<DocumentFormPage />} />
                  </Route>
                </Route>
              </Route>

              <Route path="newsletters" element={<NewslettersProvider />}>
                <Route element={<NewslettersPageProvider />}>
                  <Route index element={<NewslettersPage />} />
                </Route>
              </Route>

              <Route path="articles" element={<ArticlesProvider />}>
                <Route index element={<ArticlesPage />} />

                <Route element={<ArticleProvider />}>
                  <Route path=":id" element={<ArticlePage />} />
                  <Route path="new" element={<ArticleFormPage />} />
                </Route>
              </Route>

              <Route path="question" element={<QuestionProvider />}>
                <Route index element={<QAPage />} />
              </Route>

              <Route path="faq" element={<FAQPage />} />

              <Route path="contact" element={<ContactPage />} />

              <Route path="authors" element={<ContributorsProvider />}>
                <Route index element={<ContributorsPage />} />

                <Route path=":id" element={<ContributorPage />} />
              </Route>

              <Route path="profile" element={<ProfilePageProvider />}>
                <Route index element={<ProfilePage />} />
              </Route>
            </Route>

            <Route path="/admin/*" element={<AdminLayout />}>
              <Route element={<DashboardPageProvider />}>
                <Route index element={<DashboardPage />}></Route>
              </Route>

              <Route path="restore" element={<RestoreAccountPageProvider />}>
                <Route index element={<RestoreAccountPage />} />
              </Route>

              <Route path="newsletters" element={<AdminNewsletterProvider />}>
                <Route element={<AdminNewslettersPageProvider />}>
                  <Route index element={<AdminNewslettersPage />} />
                </Route>

                <Route path=":id" element={<NewsletterPageProvider />}>
                  <Route index element={<NewsletterPage />} />
                </Route>
              </Route>

              <Route path="users" element={<UsersProvider />}>
                <Route index element={<UsersPage />} />

                <Route path=":id" element={<UserPageProvider />}>
                  <Route index element={<UserPage />} />
                </Route>
              </Route>

              <Route path="questions" element={<QuestionsProvider />}>
                <Route index element={<QuestionsPage />} />

                <Route path=":id" element={<QuestionPageProvider />}>
                  <Route index element={<QuestionPage />} />
                </Route>
              </Route>

              <Route path="sections" element={<SectionsProvider />}>
                <Route element={<SectionsPageProvider />}>
                  <Route index element={<SectionsPage />} />
                </Route>

                <Route path=":id" element={<SectionPageProvider />}>
                  <Route index element={<SectionPage />} />
                </Route>
              </Route>

              <Route path="articles" element={<AdminArticlesProvider />}>
                <Route element={<AdminArticlesPageProvider />}>
                  <Route index element={<AdminArticlesPage />} />
                </Route>

                <Route path=":id" element={<AdminArticleFormPage />} />
                <Route path="new" element={<AdminArticleFormPage />} />
              </Route>

              <Route path="documents" element={<AdminDocumentsProvider />}>
                <Route element={<AdminDocumentsPageProvider />}>
                  <Route index element={<AdminDocumentsPage />} />
                </Route>

                <Route path=":id" element={<AdminDocumentFormPage />} />
                <Route path="new" element={<AdminDocumentFormPage />} />
              </Route>

              <Route path="tags" element={<TagsProvider />}>
                <Route index element={<TagsPage />} />

                <Route path=":id" element={<TagPageProvider />}>
                  <Route index element={<TagPage />} />
                </Route>
              </Route>

              <Route path="tags/categories" element={<TagCategoriesProvider />}>
                <Route index element={<TagCategoriesPage />} />

                <Route path=":id" element={<TagCategoryPageProvider />}>
                  <Route index element={<TagCategoryPage />} />
                </Route>
              </Route>

              <Route path="profile" element={<AdminProfilePageProvider />}>
                <Route index element={<AdminProfilePage />} />
              </Route>
            </Route>

            <Route path="/waiting-for-activation" element={<WaitingForActivationPage />} />
            <Route path="/activation" element={<ActivationPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/about-us" element={<UnauthLayout />}>
              <Route index element={<LandingPage />} />
              <Route path="asked-questions" element={<FAQPage />} />
              <Route path="contacts" element={<ContactPage />} />
            </Route>
          </Routes>
        </GlobalProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
