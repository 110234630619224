import { Container, Header } from 'components';
import { Link } from 'react-router-dom';
import { useDashboardPage } from './DashboardPageProvider';

import { DocumentTextIcon } from '@heroicons/react/outline';
import { UserIcon, FolderIcon } from '@heroicons/react/solid';
import { UserRole } from 'services';
import { useAuth } from 'GlobalProvider';

export function DashboardPage() {
  const { users, documents, articles, usersLoading, documentsLoading, articlesLoading, onViewClick } =
    useDashboardPage();
  const { user } = useAuth();

  return (
    <>
      <Header title="Dashboard">
        <Link to="/documents" className="hidden items-center md:block">
          <div className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-secondary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            User Dashboard
          </div>
        </Link>
      </Header>

      {user?.role === UserRole.ADMIN ? (
        <Container>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-14">
            <div>
              <h3 className="text-base font-medium text-gray-500 h-12">Last users to be vetted</h3>
              {!usersLoading ? (
                <ul className="divide-y divide-gray-200">
                  {users.map((item) => (
                    <li key={item.id} className="py-4 hover:bg-gray-100/[0.7]">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <UserIcon className="h-8 w-8 rounded-full text-gray-300" />
                        </div>
                        <div className="min-w-0 flex-1">
                          <p className="truncate text-sm font-medium text-gray-900">
                            {item.firstName + ' ' + item.lastName}
                          </p>
                        </div>
                        <div>
                          <button
                            className="inline-flex justify-center items-center px-2 py-1 border border-transparent text-sm font-medium shadow-sm
                          text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-primary-500 hover:bg-primary-500/[0.85] focus:ring-primary-500 rounded-3xl"
                            onClick={() => onViewClick('admin/users', item.id)}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <h1>Loading...</h1>
              )}
            </div>

            <div>
              <h3 className="text-base font-medium text-gray-500 h-12"> Last documents to be vetted</h3>
              {!documentsLoading ? (
                <ul className="divide-y divide-gray-200">
                  {documents.map((item) => (
                    <li key={item.id} className="py-4 hover:bg-gray-100/[0.7]">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <FolderIcon className="h-8 w-8 rounded-full text-gray-300" />
                        </div>
                        <div className="min-w-0 flex-1">
                          <p className="truncate text-sm font-medium text-gray-900">{item.name}</p>
                        </div>
                        <div>
                          <button
                            className="inline-flex justify-center items-center px-2 py-1 border border-transparent text-sm font-medium shadow-sm
                        text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-primary-500 hover:bg-primary-500/[0.85] focus:ring-primary-500 rounded-3xl"
                            onClick={() => onViewClick('admin/documents', item.id)}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <h1>Loading...</h1>
              )}
            </div>

            <div>
              <h3 className="text-base font-medium text-gray-500 h-12">Last articles to be vetted</h3>
              {!articlesLoading ? (
                <ul className="divide-y divide-gray-200">
                  {articles.map((item) => (
                    <li key={item.id} className="py-4 hover:bg-gray-100/[0.7]">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <DocumentTextIcon className="h-8 w-8 rounded-full text-gray-300" />
                        </div>
                        <div className="min-w-0 flex-1">
                          <p className="truncate text-sm font-medium text-gray-900">{item.name}</p>
                        </div>
                        <div>
                          <button
                            className="inline-flex justify-center items-center px-2 py-1 border border-transparent text-sm font-medium shadow-sm
                      text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-primary-500 hover:bg-primary-500/[0.85] focus:ring-primary-500 rounded-3xl"
                            onClick={() => onViewClick('admin/articles', item.id)}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <h1>Loading...</h1>
              )}
            </div>
          </div>
        </Container>
      ) : null}
    </>
  );
}
