import { Newsletter, NewsletterForm } from 'services';

export const newsletterToNewsletterForm = (newsletter: Newsletter): NewsletterForm => {
  return {
    id: newsletter.id,
    status: newsletter.status,
    publisherId: newsletter.publisherId,
    publisherAlias: newsletter.publisherAlias,
    mainArticleId: newsletter.mainArticleId,
    publishedAt: newsletter.publishedAt ?? null,
  };
};
