import { ArticleForm, Article } from 'services/articles';

export const articleToProfileForm = (article: Article): ArticleForm => {
  return {
    ...article,
    translations: article.translations.map((translation) => ({
      ...translation,
      file: {
        id: translation.fileId,
        url: translation.fileUrl,
      },
    })),
  };
};
