import { Fragment, ReactNode, useState } from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import { useAdminDefaultAppState, useAuth } from 'GlobalProvider';
import { UserRole } from 'services';
import { MenuItem } from './MenuItem';
import { NAVIGATION } from './const';
import { Button } from 'components/Button';

type AdminLayoutProps = {
  children?: ReactNode;
};

export const AdminLayout = ({ children = <Outlet /> }: AdminLayoutProps) => {
  const { isLoggedIn, user, logoutUser } = useAuth();
  const { updateAdminDefaultAppState, adminDefaultAppState } = useAdminDefaultAppState();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const fullName = `${user?.firstName} ${user?.lastName}`;

  if (!isLoggedIn) {
    return <Navigate to="/about-us" />;
  }

  if (user?.role === UserRole.USER) {
    return <Navigate to="/" />;
  }

  const toggleSidebarOpen = () => {
    setSidebarOpen((old) => !old);
  };

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={toggleSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    // eslint-disable-next-line max-len
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={toggleSidebarOpen}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img className="h-8 w-auto" src="/logo.svg" alt="logo" />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {NAVIGATION.map((item) => (
                    <MenuItem item={item} key={item.name} onClickItem={toggleSidebarOpen} />
                  ))}
                </nav>
              </div>
              <div className="block md:hidden mb-3 w-full px-4">
                <Link
                  to="/"
                  className="inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-secondary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 w-full"
                >
                  User Dashboard
                </Link>
              </div>
              <div className="relative flex items-start mb-6 mx-4">
                <div className="flex items-center h-5">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                    onChange={updateAdminDefaultAppState}
                    defaultChecked={adminDefaultAppState === 'user'}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="comments" className="font-medium text-gray-300 text-xs">
                    Default login: user dashboard
                  </label>
                </div>
              </div>
              <Button variant="danger" onClick={() => logoutUser()} className="rounded-md mb-3 mx-4">
                Logout
              </Button>
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <Link to={`/admin/profile`} className="flex-shrink-0 group block" onClick={toggleSidebarOpen}>
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={user?.avatar ? user?.avatar.url : user?.avatarFileUrl}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium text-white">{fullName}</p>
                      <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">View profile</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <div className="flex-1 flex flex-col min-h-0 bg-primary-700">
          <div className="flex-1 flex flex-col pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4 bg-primary-700 h-16">
              <div className="flex items-center gap-x-1">
                <img className="h-7 w-auto mr-2" src="/logo.svg" alt="logo" />
                <h1 className="text-white font-bold">Global CFT Expert Hub</h1>
              </div>
            </div>
            <nav className="mt-5 flex-1 px-2 space-y-1">
              {NAVIGATION.map((item) => (
                <MenuItem item={item} key={item.name} />
              ))}
            </nav>
          </div>
          <div className="flex-shrink-0 flex bg-primary-700 p-4 flex-col">
            <Link to={`/admin/profile`} className="flex-shrink-0 w-full group block mb-3">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src={user?.avatar ? user?.avatar.url : user?.avatarFileUrl}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-white">{fullName}</p>
                  <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">View profile</p>
                </div>
              </div>
            </Link>

            <Button variant="danger" onClick={() => logoutUser()} className="rounded-md mb-3">
              Logout
            </Button>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1 h-screen">
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
            onClick={toggleSidebarOpen}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <main className="flex-1 flex-col flex h-full">{children}</main>
      </div>
    </div>
  );
};
