import { RegisterForm } from './types';

export const initialRegisterForm: RegisterForm = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  alias: '',
  isPublic: false,
  language: '',
};
