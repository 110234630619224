import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { api } from 'services';
import { resToSection, sectionToPostReq, sectionToPutReq } from './transformations';
import { Section } from './types';

export const fetchSections = async (pagination: PaginationRequest): Promise<DataWithMeta<Section>> => {
  const url = paginationRequestToUrl('/sections', pagination);

  const resp = await api.get(url);

  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToSection),
  };
};

export const fetchAllSections = async (): Promise<DataWithMeta<Section>> => {
  const resp = await api.get('/sections?pageSize=9999');

  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToSection),
  };
};

export const fetchSection = async (id: string): Promise<Section> => {
  const resp = await api.get(`/sections/${id}`);
  return resToSection(resp.data);
};

export const postSection = async (data: any): Promise<Section> => {
  const resp = await api.post(`/sections`, sectionToPostReq(data));
  return resToSection(resp.data);
};

export const putSection = async (data: any): Promise<Section> => {
  const resp = await api.put(`/sections/${data.id}`, sectionToPutReq(data));
  return resToSection(resp.data);
};

export const deleteSection = async (id: string): Promise<Section> => {
  const resp = await api.delete(`/sections/${id}`);
  return resToSection(resp.data);
};
