import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/outline';
import { Button, ConfirmationModal, Container, HeaderSection, Search, Table } from 'components';
import { Document } from 'services/documents';

import { columns, sectionColumns } from './const';
import { Section } from 'services';
import { useDocumentsPageProvider } from './DocumentsPageProvider';

export const DocumentsPage = () => {
  const {
    documents,
    metaData,
    currentPage,
    usersLoading,
    documentToDelete,
    openConfirmationModal,
    closeConfirmationModal,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onDeleteConfirm,
    onNewClick,
    onSortChange,
    searchActive,
    onSectionRowClick,
    onDocumentSearchReset,
    onUserClicked,
    pagination,
    sections,
    sectionsLoading,
    sectionMetaData,
    onSectionPageChange,
    onSectionPageSizeChange,
    onSectionSortChange,
    sectionCurrentPage,
    handleSearchChange,
  } = useDocumentsPageProvider();

  return (
    <>
      <Search template datePicker tagsPicker onChange={handleSearchChange} values={pagination} />

      <HeaderSection
        title={searchActive ? 'Document search results' : 'Sections'}
        leftAction={
          searchActive && (
            <button
              onClick={onDocumentSearchReset}
              type="button"
              className="z-10 inline-flex items-center p-2 mr-2 text-black bg-transparent border border-transparent rounded-full shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-black"
            >
              <ArrowLeftIcon className="w-4 h-4 text-gray-600" aria-hidden="true" />
            </button>
          )
        }
      >
        <div className="flex items-center justify-start">
          <Button onClick={onNewClick} Icon={PlusIcon} className="ml-auto  rounded-md text-xs sm:text-sm">
            New
          </Button>
        </div>
      </HeaderSection>

      <Container>
        {searchActive ? (
          <Table<Document>
            data={documents}
            columns={columns(openConfirmationModal, onUserClicked)}
            pagination={metaData}
            currentPage={currentPage}
            isLoading={usersLoading}
            onRowClick={onRowClick}
            pageClicked={onPageChange}
            pageSizeClicked={onPageSizeChange}
            headerClicked={onSortChange}
          />
        ) : (
          <Table<Section>
            data={sections}
            columns={sectionColumns}
            pagination={sectionMetaData}
            currentPage={sectionCurrentPage}
            isLoading={sectionsLoading}
            onRowClick={onSectionRowClick}
            pageClicked={onSectionPageChange}
            pageSizeClicked={onSectionPageSizeChange}
            headerClicked={onSectionSortChange}
          />
        )}
      </Container>

      {searchActive && (
        <ConfirmationModal
          title={`Delete ${documentToDelete?.name || 'Document'} ?`}
          body={`Are you sure you want to delete ${documentToDelete?.name || 'document'} ?`}
          open={!!documentToDelete}
          onAction={onDeleteConfirm}
          onClose={closeConfirmationModal}
        />
      )}
    </>
  );
};
