import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

export enum PageType {
  Create = 'new',
  Edit = 'edit',
}

export const useIdFromParams = () => {
  const params = useParams();

  const id = useMemo(() => {
    if (params.id === 'new') {
      return PageType.Create;
    }
    return params.id || '';
  }, [params.id]);

  const isCreate = useMemo(() => {
    return id === PageType.Create;
  }, [id]);

  return { id, isCreate };
};

export const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const shortenId = (id: string | null) => {
  return id ? id.substring(id.length - 6) : '';
};

export const formatDate = (date: Date | null) => {
  return dayjs(date).format('D/MM/YYYY');
};

export const formatNumber = (value: number, digits = 2) => {
  return value.toLocaleString(undefined, { minimumFractionDigits: digits });
};

export const useFilterParams = (searchParams: any) => {
  const paramsToObject = (entries: any) => {
    const result = {} as any;
    for (const [key, value] of entries) {
      result[key] = value;
    }

    return result;
  };

  const filters = paramsToObject(searchParams.entries());

  return {
    isEmpty: Object.keys(filters).length === 0,
    filters,
  };
};
