import { useState, useEffect, useLayoutEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { ChevronDownIcon, ChevronUpIcon, SearchIcon, XIcon } from '@heroicons/react/solid';
import { debounce } from 'lodash';
import { useAllTags } from 'GlobalProvider';
import { Input, Tag, TagWrapper } from 'components';
import { PaginationRequest } from 'helpers';
import { CalendarIcon } from '@heroicons/react/outline';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';

type SearchValues = Pick<PaginationRequest, 'globalSearch' | 'tags' | 'filters'>;

export const Search = ({
  values,
  onChange,
  onSearch,
  template,
  tagsPicker,
  datePicker,
}: {
  values?: SearchValues;
  onChange?: (values: SearchValues) => void;
  onSearch?: (values: SearchValues) => void;
  template?: boolean;
  tagsPicker?: boolean;
  datePicker?: boolean;
}) => {
  const { allTags, allTagCategorys } = useAllTags();
  const [focused, setFocused] = useState(false);
  const [datePickerFocus, setDatePickerFocus] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [templateBox, setTemplateBox] = useState(false);

  const tagsStringArray = values?.tags;
  const paramsToTags = allTags?.data?.filter((element) => tagsStringArray?.includes(element.id));

  const { handleSubmit, register, formState, control, setValue, reset, watch } = useForm<any>({
    defaultValues: {
      globalSearch: '',
      tags: [],
      isTemplate: templateBox,
    },
  });

  useEffect(() => {
    reset({
      globalSearch: values?.globalSearch,
      tags: paramsToTags,
      isTemplate: templateBox,
    });
  }, [allTags, reset]);

  useLayoutEffect(() => {
    const showDatePicker = values?.filters['vettedAt[gt]'] || values?.filters['vettedAt[lt]'] ? true : false;
    const startDateValue = values?.filters['vettedAt[gt]'] ? new Date(values?.filters['vettedAt[gt]']) : null;
    const endDateValue = values?.filters['vettedAt[lt]'] ? new Date(values?.filters['vettedAt[lt]']) : null;
    const startPublishDateValue = values?.filters['publishedAt[gt]']
      ? new Date(values?.filters['publishedAt[gt]'])
      : null;
    const endPublishDateValue = values?.filters['publishedAt[lt]']
      ? new Date(values?.filters['publishedAt[lt]'])
      : null;
    const checkTemplateBox = values?.filters.isTemplate ? values.filters.isTemplate : false;
    setDatePickerFocus(showDatePicker);
    setStartDate(startDateValue);
    setEndDate(endDateValue);
    setTemplateBox(checkTemplateBox);
    if (startDateValue) {
      setStartDate(startDateValue);
      setEndDate(endDateValue);
    }
    if (startPublishDateValue) {
      setStartDate(startPublishDateValue);
      setEndDate(endPublishDateValue);
    }
  }, [values]);

  const {
    fields: selectedTags,
    remove,
    append,
  } = useFieldArray({
    control,
    name: 'tags',
    keyName: 'keyID',
  });

  const showClear = watch('globalSearch');
  const isTemplate = watch('isTemplate');

  const onSave = handleSubmit(async (values) => {
    if (onChange) {
      onChange({
        globalSearch: values.globalSearch,
        tags: values?.tags
          ?.map((tag: any) => tag.id)
          .flat()
          .toString(),
        filters: {
          isTemplate: values.isTemplate,
          'vettedAt[gt]': startDate?.toISOString(),
          'vettedAt[lt]': endDate?.toISOString(),
        },
      });
    }

    if (onSearch) {
      onSearch({
        globalSearch: values.globalSearch,
        tags: values?.tags
          ?.map((tag: any) => tag.id)
          .flat()
          .toString(),
        filters: {
          isTemplate: values.isTemplate,
          'publishedAt[gt]': startDate?.toISOString(),
          'publishedAt[lt]': endDate?.toISOString(),
        },
      });
    }
    return;
  });

  return (
    <div className="z-30 grid grid-cols-12 py-2 mt-12 lg:mt-8">
      <div
        className={clsx(
          'z-10 flex flex-col items-center content-center self-center col-span-11 sm:col-span-10 col-start-1 sm:col-start-2 pl-1 pr-4 lg:col-span-12 sm:px-0',
          { 'col-span-12': !template },
        )}
      >
        <form
          onSubmit={onSave}
          className="relative flex w-full h-12 bg-white border border-gray-300 rounded-sm shadow-sm lg:w-2/3"
        >
          <div className="flex items-center w-full ">
            <SearchIcon className="w-5 h-5 ml-3 text-gray-400" aria-hidden="true" />

            <input
              type="text"
              {...register('globalSearch', {
                onBlur: debounce(() => setFocused(false), 300),
              })}
              placeholder="Search by section, author or file name"
              className="block w-full pl-2 text-xs border-0 outline-none sm:text-sm focus:border-transparent focus:outline-none focus:ring-0 placeholder-text-xs placeholder-text-sm"
            />

            {focused && (
              <div className="absolute left-0 w-full z-20 py-1 mt-1 overflow-auto text-sm bg-white border border-gray-300 rounded-sm shadow-sm sm:text-base top-12 max-h-60 ring-1 ring-black ring-opacity-0 focus:outline-none">
                {allTags?.data.map((item: any) => (
                  <button
                    key={item.id}
                    className="flex items-center w-full hover:bg-gray-50"
                    onClick={() => {
                      if (selectedTags.find((field: any) => field.id === item.id)) {
                        setFocused((prev) => !prev);
                      } else {
                        append(item);
                        onSave();
                        setFocused((prev) => !prev);
                      }
                    }}
                  >
                    <div className="relative flex justify-between w-full px-4 py-2 cursor-pointer select-none hover:outline-none hover:text-white hover:bg-primary">
                      <span className="text-left">{item.name}</span>
                      <div className="inline-flex items-center text-right p-0.5 border border-transparent rounded-full text-gray-400 ">
                        {allTagCategorys?.data.find((x) => x.id == item.tagCategoryId)?.name}
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            )}

            {datePickerFocus && (
              <div className="absolute left-0 z-20 flex flex-col items-center justify-around w-full h-full mt-1 text-sm shadow-sm sm:flex-row sm:text-base rounded-xl bottom-12 ring-1 ring-black ring-opacity-0 focus:outline-none">
                <div className="flex">
                  <p className="block sm:hidden">from:</p>
                  <div className="flex">
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd.MM.yyyy"
                      placeholderText="Select start date"
                      customInput={
                        <input className="w-full mx-auto text-center border-b border-blue-800 focus:outline-none" />
                      }
                    />
                    <button
                      className="px-3 transparent"
                      disabled={formState.isSubmitting}
                      onClick={() => {
                        setStartDate(null);
                      }}
                      type="button"
                    >
                      <XIcon className="w-4 h-4 text-gray-500" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <span className="invisible w-2 h-px bg-blue-800 sm:visible"></span>
                <div className="flex">
                  <p className="block sm:hidden">to:</p>
                  <div className="flex">
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="dd.MM.yyyy"
                      placeholderText="Select end date"
                      customInput={
                        <input className="w-full mx-auto text-center border-b border-blue-800 focus:outline-none" />
                      }
                    />
                    <button
                      className="px-3 transparent"
                      disabled={formState.isSubmitting}
                      onClick={() => {
                        setEndDate(null);
                      }}
                      type="button"
                    >
                      <XIcon className="w-4 h-4 text-gray-500" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex p-2 items-center justify-center">
            {showClear && (
              <button
                className="pl-3 sm:px-3 transparent"
                disabled={formState.isSubmitting}
                onClick={() => {
                  setValue('globalSearch', '');
                  onSave();
                }}
                type="button"
              >
                <XIcon className="w-4 h-4 text-gray-500" aria-hidden="true" />
              </button>
            )}

            {tagsPicker && (
              <button
                className="items-center w-6 h-6 sm:w-8 sm:h-8 bg-gray-200 rounded-full"
                disabled={formState.isSubmitting}
                onClick={() => {
                  setFocused((prev) => !prev);
                  setDatePickerFocus(false);
                }}
                type="button"
              >
                {!focused ? (
                  <ChevronDownIcon className="inline w-4 h-4 sm:w-6 sm:h-6 text-primary" aria-hidden="true" />
                ) : (
                  <ChevronUpIcon className="inline w-4 h-4 sm:w-6 sm:h-6 text-primary" aria-hidden="true" />
                )}
              </button>
            )}

            {datePicker && (
              <button
                className="w-6 h-6 sm:w-8 sm:h-8 ml-2 bg-gray-200 rounded-full"
                disabled={formState.isSubmitting}
                onClick={() => {
                  setDatePickerFocus((prev) => !prev);
                  setFocused(false);
                }}
                type="button"
              >
                <CalendarIcon className="inline w-5 h-5 sm:w-6 sm:h-6 text-primary" aria-hidden="true" />
              </button>
            )}

            <button
              className="h-full ml-2 px-4 text-xs sm:text-sm text-white rounded-lg cursor-pointer font-inter bg-primary hover:bg-secondary "
              disabled={formState.isSubmitting}
              onClick={onSave}
              type="button"
            >
              Search
            </button>
          </div>
          {template && (
            <div className="relative">
              <Input
                control={control}
                name="isTemplate"
                type="checkbox"
                label="Template"
                fullWidth={false}
                checked={isTemplate}
                className="absolute ml-2 right--0 text-primary"
                id="template"
              />
            </div>
          )}
        </form>

        {selectedTags.length > 0 && (
          <TagWrapper>
            {selectedTags.map((item: any, index) => (
              <Tag
                key={item.keyID}
                onClick={() => {
                  remove(index);
                  onSave();
                }}
              >
                {item.name}
              </Tag>
            ))}
          </TagWrapper>
        )}
      </div>
    </div>
  );
};
