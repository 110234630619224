import { Container } from 'components';
import { NavLink } from 'react-router-dom';

export const LandingPage = () => {
  return (
    <Container>
      <div className="p-3 flex flex-col sm:flex-row items-center gap-8 lg:gap-24 my-12 sm:my-24 ">
        <img className="h-32 sm:h-56 w-auto" src="/logo-negative.svg" alt="Workflow" />
        <div className="space-y-6 gap-8 text-nandor">
          <p className="text-base lg:text-xl text-justify italic font-bold">
            This platform is for the hub of CSOs, activists and experts in the field of security, countering the
            financing of terrorism and its impact on civil society activities. It represents a safe virtual space that
            generates support for change, empowerment and advocacy and enables interaction. On the platform you can
            browse and share learnings, strategies and resources between members of the hub.
          </p>

          <p className="text-base lg:text-xl text-justify italic">
            User information and documents shared on this platform are not public and are not visible without an
            account. Registered users are vetted before having access to the platform and have the option to remain
            anonymous.
          </p>
        </div>
      </div>

      <div className="p-3 space-y-8 sm:space-y-16 mb-12">
        <h1 className="text-lg sm:text-2xl font-bold text-primary">What can you do on this platform?</h1>
        <div className="border-solid border-l-4 lg:border-l-8 pl-4 lg:pl-20 border-secondary rounded-xl">
          <ol className="text-base sm:text-lg list-decimal text-justify ml-5">
            <li>
              Browse the repository and templates under &quot;Documents&quot; for your work: The platform offers you
              access to a well&minus;indexed, searchable online document repository categorised by thematic areas and
              geographical scope.
            </li>
            <li>
              Share resources/documents: You can upload anything that you wish to share on AML/CFT issues that could be
              valuable for other users under &quot;Documents&quot;. This can be on a{' '}
              <i>specific topic, a specific process, or concrete action</i> you/your organisation has or is organizing
              or a resource you happen to come across. <i>As for the type of documents</i>, this can include relevant
              research; legal/policy reports; guidance on certain global/regional/national processes related to AML/CFT;
              a template of your arguments used by you/your organisation in recent advocacy efforts that can be re-used
              by other Hub members; letters you sent, advocacy documents, or strategies.
            </li>
            <li>
              Share news and updates with the community: You can share through the &quot;new community update&quot;
              button under &quot;Community Updates&quot; section. This can be anything that would not fall under
              documents. For example, a short summary of lessons learned and/or good practices that is not publicly
              published but you wish to share with the community, or an announcement for an input opportunity. Anything
              else you wish to announce/share with this community of practice can be done so via this function.
            </li>
            <li>
              Contact members: Under &quot;Contributors&quot; explore all visible users and find out what their
              expertise, interest and work areas are. Contact details will also be available in this section, for users
              that have made their details public. Individual documents will show the contributor so you can contact
              them directly to ask specific questions. Under &quot;Ask a question&quot; you can also send a general or
              topic-related question to the entire community using the platform.
            </li>
            <li>
              Find out more about the platform under&nbsp;
              <NavLink to="asked-questions" className="text-primary-800 text-base sm:text-lg font-barlow font-bold">
                &quot;Frequently Asked Questions&quot;
              </NavLink>{' '}
              or by contacting{' '}
              <NavLink to="contacts" className="text-primary-800 text-base sm:text-lg font-barlow font-bold">
                administrator
              </NavLink>
              .
            </li>
          </ol>
        </div>
        <p className="text-lg sm:text-xl italic font-bold text-center text-secondary">
          Take action: The more you use the platform and add the tags you are interested in{' '}
          <i>the more information and news will be generated for you.</i>
        </p>
      </div>

      <div className="p-3 space-y-8 sm:space-y-16 mb-12">
        <h1 className="text-lg sm:text-2xl font-bold text-primary">
          How can you become a member of this online community?
        </h1>
        <div className="border-solid border-r-4 pr-4 lg:border-r-8 lg:pr-20 border-secondary rounded-xl">
          <ul className="list-disc text-base sm:text-lg text-justify ml-5">
            <li>
              Process: In order to use this platform, you should complete a short and simple online registration form.
              The administrators will process your registration and if approved you will receive two emails as part of
              this process. <i>Please be aware that they may go to your spam/junk folder</i>.
            </li>

            <li>
              Visible user: You can <i>register as a visible user</i> with your real name, which means that all
              registered users of the platform can see who you are and how they can reach you. Additionally, all
              documents/resources that you upload on the platform will show your name as a contributor.
            </li>
            <li>
              Anonymous user: You can register <i>as an anonymous user</i> with an alias name. If you select the option
              to be an anonymous user, you are automatically given an alias. This means that you can use the platform
              without being identified by name, thus protecting your identity from all those that use the platform. You
              can use the platform in the same way as non-anonymous users.
            </li>

            <li>
              Language: The platform&apos;s default language is English; however, the platform has a{' '}
              <i>built&minus;in Google Translate function</i> that allows users to translate the information to the
              language they prefer. In addition, users can upload documents in different languages and tag them as such.
            </li>
          </ul>
        </div>
        <div className="p-3 space-y-3">
          <p className="font-barlow text-lg sm:text-xl italic font-bold text-center text-secondary">
            For more information, please click on{' '}
            <NavLink to="asked-questions" className="text-primary-800 text-xl font-barlow">
              Frequently Asked Questions
            </NavLink>
            .
          </p>
        </div>

        <div className="p-3 space-y-8 sm:space-y-16 mb-12">
          <h1 className="text-lg sm:text-2xl font-bold text-primary">Privacy Policy and Rules of the Community </h1>
          <div className="border-solid border-l-4 lg:border-l-8 pl-4 lg:pl-20 border-secondary rounded-xl">
            <p className="text-base sm:text-lg text-justify">
              The European Center for Not-for-Profit Law Stichting (ECNL) as administrator processes data and ensures
              the protection of the data in accordance with its own internal privacy policy, and The General Data
              Protection Regulation (GDPR or Algemene Verordening Gegevensbescherming (AVG) in Dutch).
            </p>
            <p className="text-base sm:text-lg text-justify">
              If you register to use the platform, ECNL collects the data you provide to be able to vet and then approve
              registration. This is data that is provided by you or is identifiable from the information you provide
              (e.g., if you give your organisation name we may be able to identify the address of the organisation).
              Please click here to find more details on Privacy Policy and the types of data we &nbsp;
              <NavLink to="/privacy-policy" className="text-primary-800">
                &quot;here&quot;
              </NavLink>{' '}
              to find more details on Privacy Policy and the types of data we process.
            </p>
          </div>
        </div>
        <div className="p-3 space-y-8 sm:space-y-16 mb-12">
          <h1 className="text-lg sm:text-2xl font-bold text-primary">Ownership</h1>
          <div className="border-solid border-r-4 pr-4 lg:border-r-8 lg:pr-20  border-secondary rounded-xl">
            <p className="text-base sm:text-lg text-justify">
              This platform is administrated by ECNL. ECNL is a non-governmental organisation based in the Hague,
              Netherlands working on empowering civil society by creating enabling legal and policy frameworks. ECNL
              currently uses funds from donors to create, develop and maintain this platform. Donors do not have access
              to personal and other sensitive information on the platform.
            </p>
            <p className="text-base sm:text-lg text-justify">
              Although ECNL is the primary administrator, ownership belongs to all community members, as we all
              contribute to the content of the Hub and the expansion of the community.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};
