import { Newsletter, NewsletterForm } from './types';

export const resToNewsletter = (res: any): Newsletter => {
  return {
    id: res.id ?? '',
    status: res.status ?? '',
    publisherId: res.publisherId ?? '',
    publisherAlias: res.publisherAlias ?? '',
    mainArticleId: res.mainArticleId ?? '',
    mainArticleName: res.mainArticleName ?? '',
    publishedAt: res.publishedAt ?? null,
  };
};

export const newsletterToPostReq = (newsletter: NewsletterForm): any => {
  return {
    publisherId: newsletter.publisherId,
    mainArticleId: newsletter.mainArticleId,
  };
};

export const newsletterToPutReq = (newsletter: NewsletterForm): any => {
  return {
    status: newsletter.status,
    publisherId: newsletter.publisherId,
    mainArticleId: newsletter.mainArticleId,
    publishedAt: newsletter.publishedAt ?? null,
  };
};
