import { api } from 'services';
import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';

import { userToPutReq, resToUser, userToRestoreReq } from './transformations';
import { User, UserStatus } from './types';

export const fetchUsers = async (pagination: PaginationRequest): Promise<DataWithMeta<User>> => {
  let url = paginationRequestToUrl('/users', pagination);

  url = url.replace('globalSearch=', 'search=');

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToUser),
  };
};

export const fetchUser = async (id: string, vetted = 'false'): Promise<User> => {
  const resp = await api.get(`/users/${id}?showNonVetted=${vetted}`);
  return resToUser(resp.data);
};

export const putUser = async (data: any): Promise<User> => {
  const resp = await api.put(`/users/${data.id}`, userToPutReq(data));
  return resToUser(resp.data);
};

export const postUser = async (data: any): Promise<User> => {
  const resp = await api.post(`/users`, userToPutReq(data));
  return resToUser(resp.data);
};

export const deleteUser = async (id: string): Promise<User> => {
  const resp = await api.delete(`/users/${id}`);
  return resToUser(resp.data);
};

export const restoreAccount = async (data: any): Promise<User> => {
  const resp = await api.post(`/users/restore`, userToRestoreReq(data));
  return resToUser(resp.data);
};

export const userSelfDelete = async (): Promise<any> => {
  const resp = await api.delete(`/users/authors`);
  return resp.data;
};

export const approveUser = async (id: string): Promise<any> => {
  const resp = await api.post(`/users/auth/vet-user`, { userId: id, vettingStatus: UserStatus.APPROVED });
  return resToUser(resp.data);
};
export const denyUser = async (id: string): Promise<any> => {
  const resp = await api.post(`/users/auth/vet-user`, { userId: id, vettingStatus: UserStatus.DENIED });
  return resToUser(resp.data);
};

export const fetchProfile = async (): Promise<User> => {
  const resp = await api.get('/users/me');
  return resToUser(resp.data);
};

export const putProfile = async (data: any): Promise<User> => {
  const resp = await api.put('/users/me', userToPutReq(data));
  return resToUser(resp.data);
};
