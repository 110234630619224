import { Switch as HSwitch } from '@headlessui/react';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

type SwitchProps<TField extends FieldValues = FieldValues> = {
  children: ReactNode;
  control: Control<TField>;
  name: Path<TField>;
  description?: ReactNode;
};

export function Switch<TField extends FieldValues = FieldValues>({
  children,
  description,
  control,
  name,
}: SwitchProps<TField>) {
  const {
    field: { value, ...field },
    fieldState,
  } = useController({ control, name });

  return (
    <div className="pt-5">
      <HSwitch.Group as="div" className="flex items-center justify-between space-x-4">
        <HSwitch
          checked={value}
          {...field}
          className={clsx(
            value ? 'bg-primary-500' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
          )}
        >
          <span
            aria-hidden="true"
            className={clsx(
              value ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            )}
          />
        </HSwitch>
        <span className="flex-grow flex flex-col">
          <HSwitch.Label as="span" className="text-sm font-medium text-gray-900" passive>
            {children}
          </HSwitch.Label>
          {description ? (
            <HSwitch.Description as="span" className="text-sm text-gray-500">
              {description}
            </HSwitch.Description>
          ) : null}
        </span>
      </HSwitch.Group>
      {fieldState.error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {fieldState.error.message}
        </p>
      )}
    </div>
  );
}
