import { Button, Input, Header, Container, Select } from 'components';
import { useNavigate } from 'react-router-dom';
import { NewsletterStatus } from 'services/newsletters/const';
import { useNewsletterPage } from './AdminNewsletterPageProvider';

export const NewsletterPage = () => {
  const navigate = useNavigate();

  const { isCreate, onSubmit, control, articles, markNewsletterAsReady, markReadyToBeSent, newsletterStatus } =
    useNewsletterPage();

  return (
    <>
      <Header title={isCreate ? 'New newsletter' : 'Edit newsletter'} onGoBackPress={() => navigate(-1)}>
        <div className="flex justify-between items-center ml-2">
          {newsletterStatus === NewsletterStatus.PENDING || isCreate ? (
            <Button onClick={onSubmit} className="ml-2">
              Save
            </Button>
          ) : null}
          {newsletterStatus === NewsletterStatus.PENDING && (
            <Button onClick={markNewsletterAsReady} className="ml-2" variant="success">
              Mark as Ready
            </Button>
          )}
          {newsletterStatus === NewsletterStatus.GENERATED && (
            <Button onClick={markReadyToBeSent} className="ml-2" variant="success">
              Ready to be sent
            </Button>
          )}
        </div>
      </Header>

      <Container>
        <form onSubmit={onSubmit}>
          <div className="bg-white shadow rounded-lg">
            <div className="space-y-6 px-4 py-5 sm:p-6">
              <div className="space-y-6">
                <Select
                  control={control}
                  name="mainArticleId"
                  options={articles}
                  label="Article"
                  disabled={isCreate === (newsletterStatus === NewsletterStatus.PENDING)}
                  required
                />

                <Input control={control} name="publisherAlias" label="Publisher" disabled />
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};
