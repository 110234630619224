import { HeaderSection } from 'components';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, ChatIcon, InboxIcon, PhoneIcon } from '@heroicons/react/outline';

import { Section } from 'components/Section';

import { useContributorPage } from './hooks';

export const ContributorPage = () => {
  const { isLoading, contributor, status } = useContributorPage();
  const navigate = useNavigate();

  return (
    <>
      <HeaderSection
        title={status === 'error' ? 'Not Found' : contributor?.alias ?? ''}
        leftAction={
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="z-10 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-black bg-transparent hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-black mr-2"
          >
            <ArrowLeftIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
          </button>
        }
      />

      {isLoading ? (
        <div className="bg-white shadow rounded-lg flex items-center justify-center h-48">Loading ...</div>
      ) : (
        <div className="bg-white shadow rounded-lg">
          <div className="space-y-2 px-4 py-5 sm:p-6">
            {contributor?.isPublic ? (
              <div className="flex lg:flex-row flex-col lg:justify-between">
                <div className="lg:w-full lg:order-1 order-2 min-w-0">
                  <div className="lg:flex block items-center mb-10 ml-4">
                    {contributor.avatarFileUrl ? (
                      <div className="w-16 h-16 flex items-center justify-center bg-gray-200 mr-5 min-w-[64px] uppercase rounded-full overflow-hidden mb-2">
                        <img
                          src={`${contributor?.avatarFileUrl}?size=64x64`}
                          alt={contributor?.avatarFileUrl}
                          className="w-16 h-16"
                        />
                      </div>
                    ) : (
                      <div className="w-16 h-16 flex items-center justify-center bg-gray-200 mr-5 min-w-[64px] uppercase rounded-full overflow-hidden mb-2"></div>
                    )}
                    <h4 className="text-lg leading-6 mb-4 mr-4 font-medium">
                      {contributor?.firstName} {contributor?.lastName}
                    </h4>
                  </div>

                  <Section
                    title={'General user information'}
                    subtitle={''}
                    className="bg-white rounded-lg !p-0 shadow mb-6"
                    headerClassName="py-5 px-6"
                    titleClassName="text-black"
                    subTitleClassName="mb-0 !text-gray-500"
                  >
                    <ul className="divide-y border-t">
                      <li className="xs:flex px-6 py-5 text-sm">
                        <div className="xs:w-1/3 text-gray-500 xs:mr-4 mr-0">Alias</div>
                        <div className="col-span-2 xs:mt-0 mt-2 text-gray-900 xs:w-2/3 min-w-0">
                          <div dangerouslySetInnerHTML={{ __html: contributor?.alias ?? '' }} />
                        </div>
                      </li>
                      <li className="xs:flex px-6 py-5 text-sm">
                        <div className="xs:w-1/3 text-gray-500 xs:mr-4 mr-0">Full name</div>
                        <div className="col-span-2 xs:mt-0 mt-2 text-gray-900 xs:w-2/3">
                          {contributor?.firstName} {contributor?.lastName}
                        </div>
                      </li>
                      <li className="xs:flex px-6 py-5 text-sm">
                        <div className="xs:w-1/3 text-gray-500 xs:mr-4 mr-0">E-mail</div>
                        <div className="col-span-2 xs:mt-0 mt-2 text-gray-900 xs:w-2/3">{contributor?.email}</div>
                      </li>
                      <li className="xs:flex px-6 py-5 text-sm">
                        <div className="xs:w-1/3 text-gray-500 xs:mr-4 mr-0">Country, state, city</div>
                        <div className="col-span-2 xs:mt-0 mt-2 text-gray-900 xs:w-2/3">
                          {contributor?.country} {contributor?.state} {contributor?.city}
                        </div>
                      </li>
                      <li className="xs:flex px-6 py-5 text-sm">
                        <div className="xs:w-1/3 text-gray-500 xs:mr-4 mr-0">Language</div>
                        <div className="col-span-2 xs:mt-0 mt-2 text-gray-900 xs:w-2/3"> {contributor?.language}</div>
                      </li>
                      <li className="xs:flex px-6 py-5 text-sm">
                        <div className="xs:w-1/3 text-gray-500 xs:mr-4 mr-0">Tags</div>
                        <div className="col-span-2 xs:mt-0 mt-2 text-gray-900 xs:w-2/3 gap-2 flex flex-wrap">
                          {contributor?.tags.map((tag) => {
                            return (
                              <span
                                key={tag.id}
                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium shadow-sm cursor-default bg-primary-500 text-white"
                              >
                                {tag.name}
                              </span>
                            );
                          })}
                        </div>
                      </li>
                      <li className="xs:flex px-6 py-5 text-sm">
                        <div className="xs:w-1/3 text-gray-500 xs:mr-4 mr-0">Contacts</div>
                        {contributor?.contacts && contributor.contacts.length > 0 && (
                          <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                            {contributor?.contacts.map((contact, index) => (
                              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm" key={index}>
                                <div className="flex w-0 flex-1 items-center">
                                  {contact.type === 'email' && (
                                    <InboxIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                  )}
                                  {contact.type === 'phone' && (
                                    <PhoneIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                  )}
                                  {contact.type === 'linkedin' && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                      />
                                    </svg>
                                  )}
                                  {contact.type === 'twitter' && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                      />
                                    </svg>
                                  )}
                                  {contact.type === 'whatsapp' && (
                                    <ChatIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                  )}
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                  <span className="ml-2 w-0 flex-1 truncate">{contact?.address}</span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    </ul>
                  </Section>
                </div>

                {/* Sidebar - content will be added later
                <div className="lg:max-w-[320px] lg:w-full lg:ml-6 lg:order-2 order-1 lg:mb-0 mb-8" /> */}
              </div>
            ) : (
              <div className="sm:col-span-1">
                <div className="py-16">
                  <div className="text-center">
                    <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                      This user is private.
                    </h1>
                    <p className="mt-2 text-base text-gray-500">
                      Please{' '}
                      <a href="mailto:admin@globalcftexperthub.org" className="text-primary-800 hover:text-primary-850">
                        Contact Administrator
                      </a>{' '}
                      for more information.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
