export enum StorageType {
  SectionAssets = 1,
  DocumentAssets = 2,
  UserAssets = 3,
}

export interface UploadResponse {
  fileName: string;
  id: string;
  name: string;
  storageTypeId: StorageType;
  url: string;
}
