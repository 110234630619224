import { Button, Card, Container, Divider, Header, Input } from 'components';
import { useNavigate } from 'react-router-dom';
import { useTagCategoryPage } from './TagCategoryPageProvider';

export function TagCategoryPage() {
  const { isCreate, control, onSubmit } = useTagCategoryPage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New category' : 'Edit category'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit}>
          Save
        </Button>
      </Header>

      <Container>
        <Card>
          <form className="space-y-10" onSubmit={onSubmit} noValidate id="tagCategoryForm">
            <Divider title="Category information" />

            <Input control={control} name="name" label="Name" placeholder="Name" />
          </form>
        </Card>
      </Container>
    </>
  );
}
