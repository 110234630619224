import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MessageType, showMessage } from 'helpers';
import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import {
  Control,
  FieldArrayWithId,
  FormState,
  useFieldArray,
  UseFieldArrayRemove,
  useForm,
  UseFormRegister,
} from 'react-hook-form';
import { Outlet } from 'react-router-dom';
import { fetchProfile, putProfile, User } from 'services';
import { defaultUserForm } from '../const';
import { PROFILE_VALIDATION_SCHEMA } from './const';
import { profileToProfileForm } from './transformation';

import { useGlobalProvider } from 'GlobalProvider';

export const AdminProfilePageContext = createContext<{
  formState: FormState<User>;
  control: Control<User, any>;
  profile: User | undefined;
  register: UseFormRegister<User>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  contacts: FieldArrayWithId<User, 'contacts', 'id'>[];
  addContact: () => void;
  removeContact: UseFieldArrayRemove;
  isLoading: boolean;
}>({} as any);

type Props = {
  children?: ReactNode;
};

export function AdminProfilePageProvider({ children = <Outlet /> }: Props) {
  const queryClient = useQueryClient();

  const { data: profile, isLoading } = useQuery(['profile'], () => fetchProfile());
  const { mutateAsync: updateProfile } = useMutation(putProfile);

  const { handleSubmit, register, formState, reset, control } = useForm<User>({
    defaultValues: defaultUserForm,
    resolver: yupResolver(PROFILE_VALIDATION_SCHEMA),
  });
  const {
    fields: contacts,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray({ control, name: 'contacts' });

  const { updateUser } = useGlobalProvider();

  const addContact = () => appendContact({ address: '', type: '' });

  const profileForm = useMemo(() => {
    if (profile) {
      return profileToProfileForm(profile);
    }
    return defaultUserForm;
  }, [profile]);

  useEffect(() => {
    reset(profileForm);
  }, [profileForm]);

  const onSubmit = handleSubmit(async (values) => {
    try {
      await updateProfile(values);
      updateUser(values);
      showMessage('Profile successfully updated.', MessageType.Success);
      queryClient.invalidateQueries(['profile']);
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, control, profile, register, onSubmit, contacts, addContact, removeContact, isLoading }),
    [formState, control, profile, register, onSubmit, contacts, addContact, removeContact, isLoading],
  );

  return <AdminProfilePageContext.Provider value={providerValue}>{children}</AdminProfilePageContext.Provider>;
}

export function useAdminProfilePage() {
  return useContext(AdminProfilePageContext);
}
