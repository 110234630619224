import { PageType } from 'helpers';
import { User, UserRole, UserStatus } from 'services';

export const defaultUserForm: User = {
  id: PageType.Create,
  firstName: '',
  lastName: '',
  email: '',
  alias: '',
  organization: '',
  isPublic: false,
  role: UserRole.USER,
  vettedAt: '',
  vettingStatus: UserStatus.PENDING,
  password: '',
  city: '',
  country: '',
  language: '',
  state: '',
  confirmPassword: '',
  contacts: [],
  tags: [],
};
