import React, { createContext, useContext, useMemo, useState } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Outlet } from 'react-router-dom';
import { postQuestion, Question } from 'services';
import { MessageType, showMessage } from 'helpers';
import { defaultQuestionForm, QUESTION_VALIDATION_SCHEMA } from './const';

export const QuestionContext = createContext<{
  formState: FormState<Question>;
  register: UseFormRegister<Question>;
  control: Control<Question, any>;
  confirmationModal: boolean;
  openConfirmationModal: () => void;
  closeConfirmationModal: () => void;
  onConfirm: () => void;
  openFormConfirmationModal: (e: React.FormEvent<HTMLFormElement>) => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const QuestionProvider = ({ children = <Outlet /> }: Props) => {
  const { register, formState, control, handleSubmit, reset } = useForm<Question>({
    defaultValues: defaultQuestionForm,
    resolver: yupResolver(QUESTION_VALIDATION_SCHEMA),
  });

  const [confirmationModal, setConfirmationModal] = useState(false);

  const openConfirmationModal = () => {
    setConfirmationModal(true);
  };

  const openFormConfirmationModal = (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    setConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const onConfirm = () => {
    onSubmit();
  };

  const onSubmit = handleSubmit(async (values) => {
    try {
      await postQuestion(values);
      showMessage('Question successfully sent.', MessageType.Success);
      reset(defaultQuestionForm);
      closeConfirmationModal();
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
      closeConfirmationModal();
    }
  });

  const providerValue = useMemo(
    () => ({
      formState,
      control,
      confirmationModal,
      register,
      openConfirmationModal,
      openFormConfirmationModal,
      closeConfirmationModal,
      onConfirm,
    }),
    [formState, register, control, openConfirmationModal, closeConfirmationModal, onConfirm],
  );

  return <QuestionContext.Provider value={providerValue}>{children}</QuestionContext.Provider>;
};

export const useQuestionProvider = () => {
  return useContext(QuestionContext);
};

interface Props {
  children?: React.ReactNode;
}
