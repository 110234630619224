import { ContentState, convertToRaw, EditorState } from 'draft-js';
import React, { memo, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { TextEditorProps } from './types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TextEditor: React.FC<TextEditorProps> = (props) => {
  const { placeholder, onChange, value } = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = value || '';
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [value, updated]);

  const onEditorStateChange = (e: EditorState) => {
    setUpdated(true);
    setEditorState(e);

    const retVal = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    return onChange(retVal);
  };

  return (
    <div
      className="editor"
      style={{
        border: '1px solid #ccc',
        minHeight: 400,
        padding: 10,
      }}
    >
      <Editor placeholder={placeholder} editorState={editorState} onEditorStateChange={onEditorStateChange} />{' '}
    </div>
  );
};

export default memo(TextEditor);
