import { Button, ConfirmationModal, HeaderSection, Input, TagPicker, Select, Tooltip } from 'components';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { MinusIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { LanguageModal } from '../../../../components/LanguageModal';
import { FileUploader } from 'components/FileUploader';
import { useDocumentsFormPage } from './hooks';
import { defaultTranslation } from './const';
import { Textarea } from 'components/Textarea';
import { useDocumentsPageProvider } from '../DocumentsPage/DocumentsPageProvider';
import { useMemo } from 'react';

export const DocumentFormPage = () => {
  const { selectSections, sectionId } = useDocumentsPageProvider();
  const navigate = useNavigate();
  const {
    control,
    onSubmit,
    openConfirmationModal,
    openLanguageModal,
    closeConfirmationModal,
    closeLanguageModal,
    fields,
    onDeleteConfirm,
    handleTabClick,
    activeTab,
    languageModal,
    onLanguageAdd,
    translationToDelete,
    isTemplate,
    resetFile,
    disclaimer,
    openDisclaimer,
    closeDisclaimer,
  } = useDocumentsFormPage();

  const sectionSelectOptions = useMemo(
    () =>
      selectSections
        .map((section) => ({
          label: section.name,
          value: section.id,
        }))
        .filter((item) => (sectionId ? item.value === sectionId : item.value !== sectionId)),
    [],
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <HeaderSection
          title="New document"
          leftAction={
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="z-10 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-black bg-transparent hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-black mr-2"
            >
              <ArrowLeftIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
            </button>
          }
        >
          <div className="flex flex-1 space-x-4 justify-between mt-4 sm:mt-0">
            <nav className="flex space-x-2 sm:ml-8" aria-label="Tabs">
              {fields.map((tab, index) => (
                <div
                  key={tab.language}
                  onClick={handleTabClick(tab.language)}
                  className={clsx(
                    tab.language === activeTab ? 'bg-blue-200 text-blue-800' : 'text-gray-500 hover:text-gray-700',
                    'px-3 py-2 text-sm rounded-md uppercase font-semibold cursor-pointer group relative',
                  )}
                  aria-current={tab.language === activeTab ? 'page' : undefined}
                >
                  {tab.language}

                  {tab.language !== 'en' && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        openConfirmationModal({ language: tab.language, index });
                      }}
                      className="absolute -top-1 -right-1 hidden items-center h-4 w-4 rounded-full text-xs font-medium bg-primary text-white justify-center group-hover:inline-flex shadow-sm"
                    >
                      <MinusIcon className="h-2 w-2 text-white" aria-hidden="true" />
                    </span>
                  )}
                </div>
              ))}

              <div
                onClick={openDisclaimer}
                className="px-3 py-2 font-medium text-sm rounded-md cursor-pointer text-gray-500 hover:text-gray-700 flex items-center hover:bg-gray-200"
              >
                <PlusIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
              </div>
            </nav>

            <div className="flex justify-between items-center">
              <Button
                onClick={() => window.open('https://translate.google.com/')}
                className="ml-2 rounded-md"
                type="button"
              >
                Translate
              </Button>

              <Button onClick={onSubmit} className="ml-4 rounded-md">
                Save
              </Button>
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="px-4 py-2 ml-4 rounded-md border border-transparent text-sm font-medium  border-gray-500  text-black bg-transparent"
              >
                Cancel
              </button>
            </div>
          </div>
        </HeaderSection>

        <div className="container bg-white shadow rounded-lg  mx-auto">
          <div className="space-y-6 px-4 py-5 sm:p-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-12">
              {fields.map((field, index) => (
                <div
                  className={clsx(field.language === activeTab ? 'block' : 'hidden', 'col-span-1 space-y-8')}
                  key={field.keyID}
                >
                  <Input control={control} name={`translations.${index}.name`} label="Name" />
                  <Textarea control={control} name={`translations.${index}.description`} label="Description" rows={5} />
                  <FileUploader
                    control={control}
                    name={`translations.${index}.file`}
                    label="File"
                    resetFile={() => resetFile(index)}
                  />
                </div>
              ))}

              <div className="space-y-8">
                <Input control={control} name="authorName" label="Publisher" disabled />
                <Input control={control} name="originalAuthor" label="Author" />

                <Select
                  control={control}
                  options={sectionSelectOptions}
                  name="sectionId"
                  label="Section"
                  placeholder="Select section"
                />

                <TagPicker control={control} name="tags" label="Add tags" />

                <div className="flex flex-col xl:flex-row gap-4 items-left">
                  <Input
                    control={control}
                    name="isTemplate"
                    label="Mark document as template"
                    type="checkbox"
                    checked={isTemplate}
                    fullWidth={false}
                    className="flex flex-row-reverse gap-3 justify-end items-center"
                    id="template"
                  />
                  <Tooltip
                    tooltipMessage="A template is a file that serves as a starting point for a new document. It can be used to create a new document with the same structure and formatting."
                    className="w-48"
                  >
                    <QuestionMarkCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <LanguageModal
        open={languageModal}
        onClose={closeLanguageModal}
        control={control}
        name="languages"
        onSubmit={onLanguageAdd}
        translations={defaultTranslation}
      />

      <ConfirmationModal
        title="Delete translation"
        body={`Are you sure you want to delete "${translationToDelete.translation}"?`}
        open={!!translationToDelete.translation}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />

      <ConfirmationModal
        title="ECNL"
        body={`We are responsible only for vetting the English version of the document`}
        open={disclaimer}
        onClose={closeDisclaimer}
        onAction={openLanguageModal}
        actionButtonLabel="Continue"
      />
    </>
  );
};
