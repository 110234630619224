import { NewsletterForm } from 'services';
import * as yup from 'yup';
import { ConfirmationModal } from './AdminNewslettersPage/types';

export const defaultNewsletterForm: NewsletterForm = {
  id: '',
  status: '',
  publisherId: '',
  publisherAlias: '',
  mainArticleId: '',
  publishedAt: null,
};

export const NEWSLETTER_VALIDATION_SCHEMA = yup.object({
  mainArticleId: yup.string().label('Article').required(),
});

export const initialConfirmationModal: ConfirmationModal = {
  action: null,
  isOpen: false,
  title: '',
  message: '',
  actionButtonLabel: 'Cancel',
};
