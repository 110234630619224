import { Button, Card, Container, Divider, Header, Input, Select } from 'components';
import { useNavigate } from 'react-router-dom';
import { useTagPage } from './TagPageProvider';

export function TagPage() {
  const { isCreate, control, onSubmit, categoriesOptions, isLoading } = useTagPage();
  const navigate = useNavigate();

  return (
    <>
      <Header title={isCreate ? 'New tag' : 'Edit tag'} onGoBackPress={() => navigate(-1)}>
        <Button type="submit" onClick={onSubmit}>
          Save
        </Button>
      </Header>

      <Container isLoading={isLoading}>
        <Card>
          <form className="space-y-10" onSubmit={onSubmit} noValidate id="tagForm">
            <Divider title="Tag information" />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Input control={control} name="name" label="Name" placeholder="Name" />

              <Select
                control={control}
                name="tagCategoryId"
                options={categoriesOptions}
                label="Category"
                placeholder="Select category"
              />
            </div>
          </form>
        </Card>
      </Container>
    </>
  );
}
