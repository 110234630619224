import { PlusIcon } from '@heroicons/react/outline';
import { Button, ConfirmationModal, Container, Header, Table } from 'components';
import { HeaderSearch } from 'components/Header/HeaderSearch';
import { User, UserRole } from 'services/users';

import { useUsersPage } from './hooks';
import { columns } from './const';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UsersPage = () => {
  const {
    users,
    metaData,
    currentPage,
    isLoading,
    globalSearch,
    userToDelete,
    user,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onDeleteConfirm,
    onNewClick,
    onSortChange,
  } = useUsersPage();

  return (
    <>
      <Header title="Users">
        <div className="flex-1 flex justify-between">
          <HeaderSearch onChange={(e) => onSearchChange(e.target.value)} value={globalSearch} />

          {user?.role === UserRole.ADMIN ? (
            <Button onClick={onNewClick} Icon={PlusIcon}>
              New user
            </Button>
          ) : null}
        </div>
      </Header>

      <Container className="overflow-hidden">
        <Table<User>
          data={users}
          columns={columns(openConfirmationModal, user)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title={`Delete ${userToDelete?.firstName || 'User'} ${userToDelete?.lastName || ''}?`}
        body={`Are you sure you want to delete ${userToDelete?.firstName || 'user'} ${userToDelete?.lastName || ''}?`}
        open={!!userToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
};
