import { Button, ConfirmationModal, Container, Header, Input, TagPicker } from 'components';
import { useNavigate } from 'react-router-dom';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';

import { ArticleStatus } from 'services/articles/const';
import TextEditor from 'components/TextEditor/TextEditor';
import { LanguageModal } from 'components';

import { useAdminArticlesFormPage } from './hooks';
import { defaultTranslation } from './const';
import { useAuth } from 'GlobalProvider';
import { UserRole } from 'services';
import { FileUploader } from 'components/FileUploader';

export const AdminArticleFormPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    control,
    isLoading,
    onSubmit,
    isCreate,
    openConfirmationModal,
    openLanguageModal,
    closeConfirmationModal,
    closeLanguageModal,
    fields,
    onDeleteConfirm,
    handleTabClick,
    activeTab,
    languageModal,
    onLanguageAdd,
    translationToDelete,
    article,
    onApproveSubmit,
    onDenySubmit,
    resetFile,
  } = useAdminArticlesFormPage();

  return (
    <>
      <Header title={isCreate ? 'New Community Update' : 'Edit Community Update'} onGoBackPress={() => navigate(-1)}>
        {(article?.vettingStatus === ArticleStatus.PENDING && user?.role === UserRole.CONTENTMANAGER) ||
        (isCreate && user?.role === UserRole.CONTENTMANAGER) ? (
          <Button onClick={onSubmit}>Save</Button>
        ) : null}
        {user?.role === UserRole.ADMIN ? (
          <div className="flex justify-between items-center">
            <Button onClick={onSubmit}>Save</Button>
            {!isCreate && article && (
              <>
                {article.vettingStatus === ArticleStatus.DENIED ? (
                  <>
                    <Button onClick={onApproveSubmit} className="ml-4" variant="success">
                      Approve
                    </Button>
                  </>
                ) : article.vettingStatus === ArticleStatus.APPROVED ? (
                  <Button onClick={onDenySubmit} className="ml-4" variant="danger">
                    Deny
                  </Button>
                ) : (
                  <>
                    <Button onClick={onApproveSubmit} className="ml-4" variant="success">
                      Approve
                    </Button>
                    <Button onClick={onDenySubmit} className="ml-4" variant="danger">
                      Deny
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        ) : null}
      </Header>
      <Container>
        <form onSubmit={onSubmit}>
          <div className="flex flex-1 space-x-4 justify-start mt-4 sm:mt-0">
            <nav className="flex space-x-2 sm:ml-8" aria-label="Tabs">
              {fields.map((tab, index) => (
                <div
                  key={tab.language}
                  onClick={handleTabClick(tab.language)}
                  className={clsx(
                    tab.language === activeTab ? 'bg-secondary text-white' : 'text-gray-500 hover:text-gray-700',
                    'px-3 py-2 text-sm rounded-md uppercase font-semibold cursor-pointer group relative',
                  )}
                  aria-current={tab.language === activeTab ? 'page' : undefined}
                >
                  {tab.language}

                  {tab.language !== 'en' && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        openConfirmationModal({ language: tab.language, index });
                      }}
                      className="absolute -top-1 -right-1 hidden items-center h-4 w-4 rounded-full text-xs font-medium bg-primary text-white justify-center group-hover:inline-flex shadow-sm"
                    >
                      <MinusIcon className="h-2 w-2 text-white" aria-hidden="true" />
                    </span>
                  )}
                </div>
              ))}

              <div
                onClick={openLanguageModal}
                className="px-3 py-2 font-medium text-sm rounded-md cursor-pointer text-gray-500 hover:text-gray-700 flex items-center hover:bg-gray-200"
              >
                <PlusIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
              </div>
            </nav>
          </div>

          {isLoading && !isCreate ? (
            <div className="bg-white overflow-hidden shadow rounded-lg flex items-center justify-center h-48">
              Loading ...
            </div>
          ) : (
            <div className="bg-white shadow rounded-lg">
              <div className="space-y-6 px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-12">
                  {fields.map((field, index) => (
                    <div
                      className={clsx(field.language === activeTab ? 'block' : 'hidden', 'col-span-1 space-y-4')}
                      key={field.keyID}
                    >
                      <Input control={control} name={`translations.${index}.name`} label="Title" />
                      <Input control={control} name={`translations.${index}.description`} label="Description" />
                      <Controller
                        control={control}
                        name={`translations.${index}.text`}
                        render={({ field: { value, onChange } }) => (
                          <TextEditor value={value || ''} onChange={onChange} placeholder="Enter text" />
                        )}
                      />

                      <div className="w-full">
                        <FileUploader
                          control={control}
                          name={`translations.${index}.file`}
                          label="File"
                          resetFile={() => resetFile(index)}
                        />
                      </div>
                    </div>
                  ))}

                  <div className="col-span-1 space-y-4">
                    <Input control={control} name="authorName" label="Publisher" />

                    <TagPicker control={control} name="tags" label="Add tags" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </Container>

      <LanguageModal
        open={languageModal}
        onClose={closeLanguageModal}
        control={control}
        name="languages"
        onSubmit={onLanguageAdd}
        translations={defaultTranslation}
      />

      <ConfirmationModal
        title="Delete translation"
        body={`Are you sure you want to delete "${translationToDelete.translation}"?`}
        open={!!translationToDelete.translation}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
};
