import { Container, Header, Table, HeaderSearch, Button, ConfirmationModal } from 'components';
import { PlusIcon } from '@heroicons/react/outline';

import { useAdminNewslettersPageProvider } from './AdminNewslettersPageProvider';

import { columns } from './const';
import { Newsletter } from 'services';

export const AdminNewslettersPage = () => {
  const {
    newsletters,
    metaData,
    currentPage,
    newslettersLoading,
    globalSearch,
    confirmationModal,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onRowClick,
    onSearchChange,
    onNewClick,
    onDeleteClick,
    onConfirmClick,
    closeConfirmationModal,
  } = useAdminNewslettersPageProvider();

  return (
    <>
      <Header title="Newsletters">
        <div className="flex-1 flex justify-between">
          <HeaderSearch onChange={(e) => onSearchChange(e.target.value)} value={globalSearch} />

          <Button onClick={onNewClick} Icon={PlusIcon}>
            New newsletter
          </Button>
        </div>
      </Header>
      <Container className="overflow-hidden">
        <Table<Newsletter>
          data={newsletters}
          columns={columns(onDeleteClick)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={newslettersLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
        <ConfirmationModal
          title={confirmationModal.title}
          body={confirmationModal.message}
          open={confirmationModal.isOpen}
          actionButtonLabel={confirmationModal.actionButtonLabel}
          onAction={onConfirmClick}
          onClose={closeConfirmationModal}
        />
      </Container>
    </>
  );
};
