import { useQuery } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useParams } from 'react-router-dom';
import { Article, fetchArticle } from 'services';

export const AdminArticlesContext = createContext<{
  formState: FormState<Article>;
  article: Article | undefined;
  isCreate: boolean;
  register: UseFormRegister<Article>;
  control: Control<Article, any>;
  isLoading: boolean;
}>({} as any);

// This will be used in case we state shared inside the module
export const AdminArticlesProvider = ({ children = <Outlet /> }: Props) => {
  const { id: articleId } = useParams();
  const isCreate = !articleId;

  const { data: article, isLoading } = useQuery(['articles', articleId], () => fetchArticle(articleId ?? ''), {
    enabled: !isCreate,
  });

  const { register, formState, control, reset } = useForm<Article>({});

  useEffect(() => {
    reset(article);
  }, [article]);

  const providerValue = useMemo(
    () => ({ formState, article, isCreate, register, control, isLoading }),
    [formState, article, isCreate, register, control, isLoading],
  );

  return <AdminArticlesContext.Provider value={providerValue}>{children}</AdminArticlesContext.Provider>;
};

export const useAdminArticles = () => {
  return useContext(AdminArticlesContext);
};

interface Props {
  children?: React.ReactNode;
}
