import { PageType } from 'helpers';
import { Tag, TagCategory } from 'services';

export const defaultTagForm: Tag = {
  id: PageType.Create,
  name: '',
  tagCategoryId: '',
};

export const defaultTagCategoryForm: TagCategory = {
  id: PageType.Create,
  name: '',
};
