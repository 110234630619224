import { createContext, useContext, useEffect, useMemo } from 'react';
import {
  Control,
  FieldArrayWithId,
  FormState,
  useFieldArray,
  UseFieldArrayRemove,
  useForm,
  UseFormRegister,
} from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { approveUser, denyUser, fetchUser, postUser, putUser, User } from 'services';

import { USER_VALIDATION_SCHEMA } from './const';
import { defaultUserForm } from 'modules/user/Profile/const';
import { profileToProfileForm } from 'modules/user/Profile/ProfilePage/transformation';

const UserPageContext = createContext<{
  formState: FormState<User>;
  user: User | undefined;
  isCreate: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onApproveSubmit: () => Promise<void>;
  register: UseFormRegister<User>;
  control: Control<User, any>;
  approveLoading: boolean;
  contacts: FieldArrayWithId<User, 'contacts', 'id'>[];
  addContact: () => void;
  removeContact: UseFieldArrayRemove;
  onDenySubmit: () => Promise<void>;
  denyLoading: boolean;
}>({} as any);

// This will be used in case we state shared inside the module
export const UserPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: userId, isCreate } = useIdFromParams();

  const { data: user } = useQuery(['users', userId], () => fetchUser(userId, 'true'), {
    enabled: !isCreate,
  });
  const { mutateAsync: updateUser } = useMutation(putUser);
  const { mutateAsync: createUser } = useMutation(postUser);
  const { mutateAsync: approveCurrentUser, isLoading: approveLoading } = useMutation(approveUser);
  const { mutateAsync: denyCurrentUser, isLoading: denyLoading } = useMutation(denyUser);

  const { handleSubmit, register, formState, reset, control } = useForm<User>({
    defaultValues: defaultUserForm,
    resolver: yupResolver(USER_VALIDATION_SCHEMA),
  });

  const {
    fields: contacts,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray({ control, name: 'contacts' });

  const addContact = () => appendContact({ address: '', type: '' });

  const userForm = useMemo(() => {
    if (user) {
      return profileToProfileForm(user);
    }
    return defaultUserForm;
  }, [user]);

  useEffect(() => {
    reset(userForm);
  }, [userForm]);

  const onSubmit = handleSubmit(
    async (values: User) => {
      if (isCreate) {
        try {
          await createUser(values);
          showMessage('User successfully created.', MessageType.Success);
          queryClient.invalidateQueries(['users']);
          navigate('/admin/users');
        } catch {
          showMessage('Theres been an error!', MessageType.Error);
        }
        return;
      }

      try {
        await updateUser(values);
        showMessage('User successfully updated.', MessageType.Success);
        queryClient.invalidateQueries(['users']);
        navigate('/admin/users');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
    },
    () => showMessage('Please fill all the fields.', MessageType.Error),
  );

  const onApproveSubmit = async () => {
    try {
      await approveCurrentUser(userId);
      showMessage('User successfully approved.', MessageType.Success);
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['user', userId]);
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  };

  const onDenySubmit = async () => {
    try {
      await denyCurrentUser(userId);
      showMessage('User successfully denied.', MessageType.Success);
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['user', userId]);
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  };

  const providerValue = useMemo(
    () => ({
      formState,
      isCreate,
      onSubmit,
      register,
      control,
      user,
      onApproveSubmit,
      approveLoading,
      onDenySubmit,
      denyLoading,
      contacts,
      addContact,
      removeContact,
    }),
    [
      formState,
      isCreate,
      onSubmit,
      register,
      control,
      user,
      onApproveSubmit,
      approveLoading,
      onDenySubmit,
      denyLoading,
      contacts,
      addContact,
      removeContact,
    ],
  );

  return <UserPageContext.Provider value={providerValue}>{children}</UserPageContext.Provider>;
};

export const useUserPage = () => {
  return useContext(UserPageContext);
};

interface Props {
  children?: React.ReactNode;
}
