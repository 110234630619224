import { PlusIcon } from '@heroicons/react/outline';
import { Button, ConfirmationModal, Container, Header, HeaderSearch, Table } from 'components';
import { TagCategory } from 'services';
import { columns } from './const';
import { useTagCategoriesPage } from './hooks';

export function TagCategoriesPage() {
  const {
    globalSearch,
    onSearchChange,
    onNewClick,
    onRowClick,
    tagCategories,
    metaData,
    currentPage,
    isLoading,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    tagToDelete,
    onDeleteConfirm,
    closeConfirmationModal,
    openConfirmationModal,
  } = useTagCategoriesPage();

  return (
    <>
      <Header title="Tag Categories">
        <div className="flex-1 flex justify-between">
          <HeaderSearch onChange={(e) => onSearchChange(e.target.value)} value={globalSearch} />

          <Button onClick={onNewClick} Icon={PlusIcon}>
            New category
          </Button>
        </div>
      </Header>

      <Container className="overflow-hidden">
        <Table<TagCategory>
          data={tagCategories}
          columns={columns(openConfirmationModal)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title={`Delete ${tagToDelete?.name}?`}
        body={`Are you sure you want to delete ${tagToDelete?.name}?`}
        open={!!tagToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
}
