import React, { createContext, useContext, useMemo, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { Control, FormState, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Section, fetchSection, postSection, putSection, SectionForm } from 'services';
import { defaultSectionForm, SECTION_VALIDATION_SCHEMA } from './const';
import { sectionToSectionForm } from './transformations';

const SectionPageContext = createContext<{
  isCreate: boolean;
  formState: FormState<Section>;
  control: Control<SectionForm, any>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}>({} as any);

// This will be used in case we state shared inside the module
export const SectionPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const { id: sectionId, isCreate } = useIdFromParams();
  const queryClient = useQueryClient();

  const { data: section } = useQuery(['sections', sectionId], () => fetchSection(sectionId), {
    enabled: !isCreate,
  });

  const { mutateAsync: updateSection } = useMutation(putSection);
  const { mutateAsync: createSection } = useMutation(postSection);

  const { formState, control, handleSubmit, reset } = useForm<SectionForm>({
    defaultValues: defaultSectionForm,
    resolver: yupResolver(SECTION_VALIDATION_SCHEMA),
  });

  const sectionForm = useMemo(() => {
    if (section) {
      return sectionToSectionForm(section);
    }
    return defaultSectionForm;
  }, [section]);

  useEffect(() => {
    reset(sectionForm);
  }, [sectionForm]);

  const onSubmit = handleSubmit(async (values: SectionForm) => {
    if (isCreate) {
      try {
        await createSection(values);
        showMessage('Section successfully sent.', MessageType.Success);
        queryClient.invalidateQueries(['sections']);
        navigate('/admin/sections');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateSection(values);
      showMessage('Section successfully updated.', MessageType.Success);
      queryClient.invalidateQueries(['sections']);
      navigate('/admin/sections');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, control, onSubmit, isCreate }),
    [formState, control, onSubmit, isCreate],
  );

  return <SectionPageContext.Provider value={providerValue}>{children}</SectionPageContext.Provider>;
};

export const useSectionPage = () => {
  return useContext(SectionPageContext);
};

interface Props {
  children?: React.ReactNode;
}
