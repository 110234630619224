import { DocumentAddIcon } from '@heroicons/react/outline';
import { Button } from 'components/Button';
import { MessageType, showMessage } from 'helpers';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { uploadDocumentAsset } from 'services';

type FileUploaderProps<TFields extends FieldValues = FieldValues> = {
  control: Control<TFields>;
  name: Path<TFields>;
  label?: string;
  resetFile?: () => void;
} & JSX.IntrinsicElements['input'];

export function FileUploader<TFields extends FieldValues = FieldValues>({
  control,
  name,
  label,
  resetFile,
  id,
  ...inputProps
}: FileUploaderProps<TFields>) {
  const { field, fieldState } = useController({ control, name });
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(async (files: File[]) => {
    setUploading(true);
    try {
      const data = await uploadDocumentAsset(files[0]);
      field.onChange({ id: data.id, url: data.url });
    } catch {
      showMessage('Something went wrong.', MessageType.Error);
    } finally {
      setUploading(false);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.pdf', '.doc', '.docx'] },
    maxFiles: 1,
    multiple: false,
    disabled: uploading || field.value.url,
    useFsAccessApi: false,
  });

  const errorMessage = (fieldState.error as any)?.url?.message;

  return (
    <>
      <div className="relative">
        {label && (
          <label className="block text-sm font-medium leading-5 text-gray-700" htmlFor={id}>
            {label}
          </label>
        )}

        {field.value.url ? (
          <>
            <div className="flex items-center justify-between">
              <a
                href={field.value.url}
                target="_blank"
                className="bg-white hover:bg-blue-200 hover:text-primary-850 text-primary-800 font-normal text-sm sm:text-base py-1 px-2 rounded-lg inline-flex mt-6 items-center border border-gray-500 hover:border-blue-800 w-fit"
                rel="noreferrer"
              >
                <DocumentAddIcon className="h-4 w-4 sm:h-5 sm:w-5 mr-2 text-gray-700" aria-hidden="true" />
                Open document
              </a>

              <Button onClick={resetFile} variant="danger" type="button" className="mt-6 rounded-lg">
                Remove
              </Button>
            </div>
            {fieldState.error && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {fieldState.error.message}
              </p>
            )}
          </>
        ) : (
          <div {...getRootProps()}>
            <div className="mt-1">
              <input {...inputProps} {...getInputProps()} id={id} />
              <div className="flex-shrink-0 group block border-gray-200 border-dashed border-2 h-32 hover:bg-gray-50">
                <div className="flex items-center justify-center cursor-pointer p-8 h-full">
                  <div>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      {uploading ? 'Uploading...' : 'Click or drop file to update'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {fieldState.error && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {errorMessage}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
}
