import clsx from 'clsx';
import { ReactNode } from 'react';

type CardProps = {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
};

export function Card({ children, className, innerClassName }: CardProps) {
  return (
    <div className={clsx('bg-white overflow-hidden shadow rounded-lg', className)}>
      <div className={clsx('px-4 py-5 sm:p-6', innerClassName)}>{children}</div>
    </div>
  );
}
