import { Question, QuestionForm } from './types';

export function resToQuestion(res: any): Question {
  return {
    id: res.id ?? '',
    title: res.title ?? '',
    text: res.text ?? '',
    answer: res.answer ?? '',
  };
}

export const questionToPostReq = (data: any) => {
  return {
    id: data.id,
    title: data.title,
    text: data.text,
    answer: data.answer,
  };
};

export const questionToPutReq = (question: QuestionForm) => {
  const payload: any = {
    id: question.id,
    title: question.title,
    text: question.text,
    answer: question.answer,
  };
  return payload;
};
