import clsx from 'clsx';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export function Textarea<TForm extends FieldValues = any>({
  name,
  control,
  className,
  label,
  fullWidth = true,
  id,
  ...props
}: Props<TForm>) {
  const { field, fieldState } = useController({ name, control });

  return (
    <div>
      {label && (
        <label className="block text-sm font-medium leading-5 text-gray-700" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="mt-1">
        <textarea
          {...field}
          {...props}
          id={id}
          className={clsx(
            className,
            `shadow-sm focus:ring-primary-500 focus:border-primary-500 block sm:text-sm border-gray-300 rounded-md`,
            { 'w-full': fullWidth },
          )}
        />
      </div>
      {fieldState.error && (
        <p className="absolute mt-1 text-sm text-red-600" id="email-error">
          {fieldState.error.message}
        </p>
      )}
    </div>
  );
}

type Props<TForm extends FieldValues = any> = {
  control: Control<TForm, any>;
  name: Path<TForm>;
  error?: boolean;
  label?: string;
  rows?: number;
  fullWidth?: boolean;
} & JSX.IntrinsicElements['textarea'];
