import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthCardLayout, Button, Input, Select, Tooltip } from 'components';
import { MessageType, showMessage } from 'helpers';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { RegisterForm, useRegister } from 'services';
import { initialRegisterForm } from 'services/auth/const';
import { VALIDATION_SCHEMA } from './const';
import { options } from 'modules/common/hooks/use-languages-options';
import { useState } from 'react';

export const SignUp = () => {
  const { postRegister } = useRegister();
  const { handleSubmit, control } = useForm<RegisterForm>({
    defaultValues: initialRegisterForm,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });
  const navigate = useNavigate();

  const [checkedTerms, setCheckedTerms] = useState(true);

  const onSubmit = handleSubmit(async (values) => {
    try {
      await postRegister(values);
      navigate('/waiting-for-activation');
      showMessage('Successfully registered.', MessageType.Success);
    } catch (error: any) {
      showMessage(error.response?.data?.title, MessageType.Error);
    }
  });

  return (
    <AuthCardLayout
      title="Register for new account"
      subTitle={
        <>
          Or{' '}
          <Link to="/login" className="font-medium text-primary-800 hover:text-primary-850">
            log in to an existing account
          </Link>
        </>
      }
    >
      <form className="space-y-6 mb-16" onSubmit={onSubmit}>
        <Input control={control} name="email" label="Email" placeholder="Email" type="email" />
        <Input control={control} name="password" label="Password" placeholder="Password" type="password" />
        <Input control={control} name="firstName" label="First Name" placeholder="First Name" />
        <Input control={control} name="lastName" label="Last Name" placeholder="Last Name" />
        <div className="flex gap-4 items-end">
          <Input control={control} name="alias" id="alias" label="Alias" placeholder="Alias" className="w-full" />
          <Tooltip
            tooltipMessage="Presenting label for all users, both anonymous and non-anonymous"
            className="w-48 z-10"
          >
            <QuestionMarkCircleIcon className="h-5 w-5 text-gray-300 mb-3" aria-hidden="true" />
          </Tooltip>
        </div>
        <div className="flex gap-4 items-end max-h-20 overflow-hidden">
          <Select control={control} name="language" id="language" label="Native language" options={options} fullWidth />
          <Tooltip
            tooltipMessage="Please choose your native language for the purpose of indication for linguistic speaker-audience relationship. The selection enables other community members to find info on spoken or written communication through your language selection."
            className="w-48"
          >
            <QuestionMarkCircleIcon className="h-5 w-5 text-gray-300 mb-3" aria-hidden="true" />
          </Tooltip>
        </div>
        <div className="flex gap-4 items-center">
          <Input
            control={control}
            name="isPublic"
            label="Is public"
            type="checkbox"
            fullWidth={false}
            className={'flex flex-row-reverse gap-2 align-bottom'}
          />
          <Tooltip
            tooltipMessage="Your profile info will be visible to all members, which are personally vetted by the admin. By not clicking on this link, you will stay anonymous and the platform will only show your alias to the members of this platform."
            className="w-48"
          >
            <QuestionMarkCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
          </Tooltip>
        </div>
        <div className="flex justify-center items-center">
          <input id="ToS" name="ToS" type="checkbox" onChange={() => setCheckedTerms((prev) => !prev)} />
          <label htmlFor="ToS" className="ml-3 font-medium text-sm cursor-pointer">
            Accept{' '}
            <Link target="_blank" to="/privacy-policy" className="text-primary-800 hover:text-primary-850">
              Terms of Service
            </Link>
          </label>
        </div>

        <Button
          fullWidth
          type="submit"
          disabled={checkedTerms}
          className={checkedTerms ? 'bg-gray-500 hover:bg-gray-500' : ''}
        >
          Create Account
        </Button>
      </form>
      <div className="flex items-center justify-center mt-2">
        <Link to="/forgot-password" className="font-medium text-sm text-primary-800 hover:text-primary-850">
          Forgot password?
        </Link>
      </div>
    </AuthCardLayout>
  );
};
