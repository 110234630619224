import { Question } from 'services';

const MAIL_TO_LINK =
  '<a class="font-normal text-primary-800 hover:underline" href="mailto:admin@globalcftexperthub.org" target="_blank">administrator</a>';

export const contentQuestions: Question[] = [
  {
    id: '1',
    text: 'What does it mean to choose the option of being an anonymous user?',
    answer:
      'If you select the option to be an anonymous user you are automatically given an alias, meaning you can use the platform without being identified by name, thus protecting your identity from users of the platform. You are able to use the platform in the same way as non-anonymous users. We would remind anonymous users that when uploading documents, they may contain identifying factors, so please bear this in mind if you choose to share your resources.',
  },
  {
    id: '2',
    text: 'Can I share this platform with my colleagues?',
    answer:
      'Please feel free to share the platform with colleagues who will find it useful and relevant for their work. To ensure the security of the platform we ask you not to share your login details with anyone, and instead encourage people to register via the registration page. In this way we can make sure that each member is registered and that everyone will go through the vetting process. In addition some parts of the platform are visible to unregistered users (landing page and frequently asked questions) i.e. if you would like to share it with someone that is not in the non-governmental sector or does not wish, for any reason, to become a member of our community.',
  },
  {
    id: '3',
    text: 'What would be useful to upload?',
    answer:
      'Anything that you wish to share on AML/CFT issues that could be valuable for other users. This can be on a specific topic, a specific process or concrete action you/your organisation has or is organising. As for the type of documents, this can be relevant research; legal/policy reports; guidance on certain global/regional/national processes related to AML/CFT; a template of your arguments used by you/your organisation in recent advocacy efforts that can be re-used by other Hub members; short summary of lessons learned and/or good practices; etc.. These documents can be outputs from you/your organisation but it can also be resources from others that you happen to come across and wish to share with the Global Hub Community.',
  },
  {
    id: '5',
    text: 'What languages does the platform support?',
    answer:
      ' The platform’s default language is English. However, the platform has a built- in google Translate function which allows users to translate the information to the language they prefer. In addition, users can upload documents in different languages and tag them as such.',
  },
  {
    id: '7',
    text: 'Who funds this platform?',
    answer:
      'ECNL uses funds from Oak Foundation funding to create, develop and maintain the platform. Oak Foundation does not have access to personal and other sensitive information that is not public on the platform.',
  },
  {
    id: '8',
    text: 'Who is vetting the content and resources?',
    answer:
      'Facilitators do not check/vouch for the accuracy of document content posted by users. However, if we see something blatantly incorrect or flagged by other users as incorrect, we will take action as per the Guiding Document: review process for uploaded documents.',
  },
  {
    id: '9',
    text: 'How can I engage and connect with other platform users?',
    answer:
      'The platform offers different ways of engaging and connecting with other platform users. \n\n You can directly reach out to an individual. For example, when looking for someone from a specific region/country or because you have follow-up questions or wish to connect based on a resource/document this person shared. You can reach out to them through any of the means the person’s user profile indicates. \n\n You can engage with other users by uploading documents/resources/templates/event announcements yourself and indicate on your profile how you would like to be contacted by them. \n\n If you do not know which specific organisation or person you need to direct a question to, there is also an option where you can reach out to the whole community with a specific question/request through the email list function.',
  },
  {
    id: '10',
    text: 'Can I start my own topic?',
    answer: `Yes, as long as it is a topic related to AML/CFT issues. You can do so by uploading the relevant document(s) under the different sections of the platform (Advocacy, Beneficial Ownership, Countering arguments…) and using the available tags. If you are not sure under which tags/headings you should upload, feel free to contact ${MAIL_TO_LINK}.`,
  },
  {
    id: '11',
    text: "What type of document do you mean with 'template'? ",
    answer:
      'Before you can use our platform, you must complete a short and simple online registration form. The administrators will process your registration and if approved you will receive two emails as part of this process. Please be aware that they may go to your spam/junk folder.',
  },
];

export const techQuestions: Question[] = [
  {
    id: '1',
    text: 'How do I register?',
    answer:
      'Before you can use our platform, you must complete a short and simple online registration form. The administrators will process your registration and if approved you will receive two emails as part of this process. Please <em>be aware that they may go to your spam/junk folder.</em>',
  },
  {
    id: '2',
    text: 'How do I upload a document?',
    answer:
      "1. When you're on the Documents page, click the box of the category you want to contribute with Your document. Select the “New document” button from the right corner of the page. \n\n 2. On the next page, provide information for the document you would like to share on the platform. Input name, description, select category and add tag if convenient. Choose the document you want to upload by selecting “Click or drop file to update” and then click “Save” button. \n\n After the added document has been vetted by the Administrator your document will be visible on the platform repository. \n",
    img: ['image7', 'image4'],
  },
  {
    id: '3',
    text: 'How do I de-register?',
    answer:
      'Log in to the platform with your email address and password. Select the "My profile" tab in the right corner and then choose the "delete account” button. For confirmation an alert modal will be shown in case you have selected to delete the account accidentally, here you can cancel the task or continue with the deleting process.',
    img: ['image12'],
  },
  {
    id: '4',
    text: 'What happens to the documents I uploaded when I de-register my account from the platform?',
    answer: `When you de-register (delete) your account from the platform, all the documents remain on the platform. Your account information, including username and password, is purged. Upon your request your access can be restored by sending a request to our ${MAIL_TO_LINK} team.`,
  },
  {
    id: '5',
    text: ' What happens to the documents I uploaded when I change the status of my account from ‘public’ to ‘private’?',
    answer:
      'If you change your account status from "public" to "private", your documents will remain in the same format, but your account information will no longer be available to other members. Only your alias will remain assigned to the documents you contributed. If you wish to reach out to a user who has a private account, you can do that by contacting administrator via email link provided on the private user preview page.',
    img: ['image1'],
  },
  {
    id: '6',
    text: 'How do I find/contact other members?',
    answer: `There are two different ways to gather contact information for other members of the platform. \n\n 1. The first option is to go to the Contributor page where you can find a list of all platform members regardless of the account type. <a href='#image6' id=image61>(1)</a> \n\n 2. If the selected user has a private account, the only way to contact that member is by sending a request to the ${MAIL_TO_LINK} team. If the selected user has a public account, you can see his contact details by selecting his alias. You will then be able to see Member’s profile details with contact information. <a href='#image9'>(3)</a> \n\n 3. The second option is to just select the user's Alias on the contributed content display (Documents or Community Updates).`,
    img: ['image6', 'image10', 'image9'],
  },
  {
    id: '7',
    text: ' How do I ask a question to other users? ',
    answer:
      "Individuals:\n\n After acquiring a member's contact details ou can use Your private communication channel to contact directly with that selected individual member based on the contact information provided for that member. \n\n To all platform users:\n\n 1. If you need to ask a general or topic related question to the entire community, you can do that by submitting a question via Question page. To reach our Question page please select “Ask a question“ in the right corner of any page you are currently on. <a href='#image5'>(1)</a> \n\n  2. Add title and an actual question you would like to share with the community and press the “Submit” button. <a href='#image3'>(2)</a>  \n\n Questions will be shared with the community via mailing list and communication on the topic you asked about can continue via direct email messaging or other communication channels members have shared.",
    img: ['image5', 'image3'],
  },
  {
    id: '8',
    text: 'How do I adjust my profile (status)?',
    answer: `1. You can select your profile picture at the top right corner of the screen and click on the 'Profile' tab. Once you're there you will be able to edit your details. <a href='#image2' >(1)</a> \n\n 2. All of the information provided on the Profile page besides First Name, Last Name and Email can be edited. If you need another account with a different name or email, please send a request to our ${MAIL_TO_LINK} team and we will assist you on creating a different user account with requested changes. <a href='#image8' >(2)</a>`,
    img: ['image2', 'image8'],
  },
  {
    id: '9',
    text: 'What does it mean to have my profile status on ‘public’?',
    answer:
      'This means that all registered users of the platform can see who you are and how they can reach you. Additionally, all documents/resources that you upload on the platform, will show your name as a contributor.',
  },
  {
    id: '10',
    text: 'How can I use the ‘Translate’ function for the information on the platform? ',
    answer:
      "Use the translate function by clicking on the “Translate” button on your screen while on the selected Document. With Google's online translation services, you can copy any piece of content and translate it to your preferred language. \n\n Note: All translations created using the Google translation service are not official and are only for assisting members in easier understanding of the Documents content.",
    img: ['image11'],
  },
];
