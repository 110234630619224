import { ArrowLeftIcon } from '@heroicons/react/outline';
import { Container, HeaderSection } from 'components';
import { useNavigate } from 'react-router-dom';

export const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <Container className="!p-0">
      <div className="bg-primary p-2">
        <img src="/logo-negative.svg" className="h-16 ml-4" />
      </div>
      <Container>
        <HeaderSection
          title="Privacy Policy"
          leftAction={
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="z-10 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-black bg-transparent hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-black mr-2"
            >
              <ArrowLeftIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
            </button>
          }
        ></HeaderSection>
        <div className="p-3 space-y-3">
          <h1 className="text-lg font-bold">Who we are (the ‘Data Controller’)</h1>
          <p className="text-base">
            The European Center for Not-for-Profit Law Stichting (ECNL) is the data controller of any personal data (in
            other words, the organisation which is responsible for the processing of your personal data and determines
            how your personal data is used). Our contact details are as follows:
          </p>
        </div>
        <div className="p-3 space-y-3">
          <p className="text-sm">
            <b>Name:</b> European Center for Not-for-Profit Law Stichting (ECNL)
          </p>
          <p className="text-sm">
            <b>Registered address:</b> Riviervismarkt 5, 2513 AM, The Hague, The Netherlands
          </p>
          <p className="text-sm">
            <b>Company registration number:</b> 73239518
          </p>
          <p className="text-sm">
            <b>Telephone:</b> +31 6 390 29 805
          </p>
          <p className="text-sm">
            <b>Website:</b>{' '}
            <a
              href="http://ecnl.org"
              target="_blank"
              rel="noreferrer"
              className="text-primary-800 hover:text-secondary"
            >
              http://ecnl.org
            </a>
          </p>
          <p className="text-sm">
            <b>Twitter page:</b>{' '}
            <a
              href="https://twitter.com/enablingNGOlaw"
              target="_blank"
              rel="noreferrer"
              className="text-primary-800 hover:text-secondary"
            >
              {' '}
              https://twitter.com/enablingNGOlaw
            </a>
          </p>
        </div>
        <div className="p-3 space-y-3">
          <h1 className="text-lg font-bold">Purpose and term of the privacy note</h1>
          <p className="text-base">
            This Privacy Notice is designed to help you understand what data is processed by European Center for
            Not-for- Profit Law Stichting (ECNL Stichting) as data controller (hereinafter: ECNL) on the GlobalCFT
            Expert Hub (hereinafter: the platform). It describes the personal data ECNL processes during the course of
            services offered by our organisation and the ways the personal data is used. Please read this document
            carefully as it will help you to understand the ways that ECNL uses personal data and its reasons for doing
            so.
          </p>
        </div>
        <div className="p-3 space-y-3">
          <h1 className="text-lg font-bold">Legal background to the privacy note</h1>
          <p className="text-base">
            ECNL processes data and ensures the protection of the data in accordance with its own internal privacy
            policy, and the The General Data Protection Regulation (GDPR or Algemene Verordening Gegevensbescherming
            (AVG) in Dutch).
          </p>
        </div>
        <div className="p-3 space-y-3">
          <h1 className="text-lg font-bold">General principles of data processing</h1>
          <p className="text-base">
            ECNL is committed to processing your personal data in accordance with applicable data protection laws and
            ECNL will perform any data processing whilst complying with the following principles:
          </p>
          <div className="px-8 space-y-3">
            <p className="text-sm">
              <b>Lawfulness, fair processing and transparency:</b> Personal data must be processed lawfully, fairly and
              in a transparent.
            </p>
            <p className="text-sm">
              <b>Purpose limitation: </b> Personal data may only be collected for a specified, explicit and legitimate
              purpose and not processed in a manner incompatible with this purposes.
            </p>
            <p className="text-sm">
              <b>Data minimisation:</b> Personal data may only be processed if adequate, relevant and limited to what is
              necessary in relation to the purpose.
            </p>
            <p className="text-sm">
              <b>Data quality (accuracy):</b> The personal data must be accurate, complete and kept up to date.Storage
              limitation: Personal data must be kept for no longer than is necessary for the above-mentioned purpose.
            </p>
            <p className="text-sm">
              <b>Integrity and confidentiality:</b> ECNL will ensure there is appropriate security to protect the
              personal data against unauthorised or unlawful processing and against accidental loss, destruction or
              damage using appropriate technical or organisational measures.
            </p>
            <p className="text-sm">
              <b>Accountability:</b> ECNL is responsible for, and must be able to demonstrate compliance with the
              above-detailed principles and aspects of data processing.
            </p>
          </div>
        </div>
        <div className="p-3 space-y-3">
          <h1 className="text-lg font-bold">Information collected by the platform</h1>
          <p className="text-base">
            For visitors to our website that do not have an user account, we generally log only timestamps, URLs, and
            HTTP requests and do internal analytical logging, including additional technical information for up to seven
            days from when the data was collected.
          </p>
          <p className="text-base">
            Circumstances in which ECNL may need to log and retain technical information for longer than seven days
            include when we believe it is reasonably necessary for the platform’s mission and functionality, including
            situations such as:
          </p>
          <ul className="pl-6 text-sm">
            <li>site testing,</li>
            <li>diagnosis of technical problems,</li>
            <li>defending against attacks to the site,</li>
            <li>handling a spike in traffic or other abnormal, short-term circumstances,</li>
            <li>research projects (in anonymized form) that serve our overall mission to defend freedom online</li>
          </ul>
          <p className="text-base">
            In those and similar situations we will delete the information as soon as it is apparent that the
            information is no longer needed for the purpose for which it was retained.
          </p>
        </div>
        <div className="p-3 space-y-3">
          <p className="text-base">
            If you register to the platform, ECNL collects the data you provide in order to be able to vet and then
            register you to the platform. This is data that is provided by you, or is identifiable from the information
            you provide ( e.g. if you give your organisation name we may be able to identify the address of the
            organisation). We also collect data based on your Please find below an overview of the types of data we
            process:
          </p>
          <div className="px-8 space-y-3">
            <p className="text-sm underline">
              <b>The information we collect:</b>
            </p>
            <ul className="pl-6 text-sm space-y-2">
              <li>Name</li>
              <li>Email address(es)</li>
              <li>Name of organisation, if applicable</li>
              <li>Other details, that are actively provided whilst signing up</li>
              <li>Contact history with ECNL</li>
              <li>Platform use / including any documents uploading by the user</li>
              <li>Any information we collect during a vetting process </li>
            </ul>
            <p className="text-sm underline">
              <b>Purposes of data collection:</b>
            </p>
            <ul className="pl-6 text-sm space-y-2">
              <li>to allow you to create an account</li>
              <li>for you to be able to use the platform</li>
              <li>for us to be able to contact you</li>
              <li>for us to send you newsletters</li>
            </ul>
          </div>
        </div>
        <div className="p-3 space-y-3">
          <h1 className="text-lg font-bold">Data processing related to the use of electronic hosting</h1>
          <p className="text-base">
            ECNL engages with various third parties to provide services for specific functions where ECNL does not have
            the capacity to perform them internally. For example, as ECNL does not possess a server, some personal data
            are obtained by ECNL electronically using services provided by the respective web hosting companies. Data is
            stored on ….and data backups occur at specified intervals, which are located electronically via a cloud
            service. Cloud service for backups is provided by….
          </p>
          <p className="text-base">
            Furthermore, ECNL grants access for the external IT operator company to the relevant electronically stored
            personal data, while another IT company performs spam filtering based on a contract made with ECNL.
          </p>
          <p className="text-base">
            ECNL only works with service providers it trusts to take care of personal data appropriately as required by
            applicable laws.
          </p>
        </div>
        <div className="p-3 space-y-3">
          <h1 className="text-lg font-bold">How the platform’s Internal Analytics Works:</h1>
          <h4 className="text-base font-bold">Retention of your personal data</h4>
          <p className="text-base">
            ECNL will only retain your personal data for as long as is necessary for the purposes described in this
            privacy policy. Retention periods are unlikely to be uniform as the time ECNL needs to retains your personal
            data will vary according to the type of the data and the purpose of the processing.
          </p>
        </div>
        <div className="p-3 space-y-3">
          <h1 className="text-lg font-bold">Rules of data processing</h1>
          <h4 className="text-base font-bold">Right of data subjects:</h4>
          <p className="text-base">
            The law gives data subjects certain rights in connection with their personal data which is processed by ECNL
            as the controller. You have the right :
          </p>
          <ul className="pl-6 text-sm space-y-2">
            <li>to ask us not to use your personal data for direct marketing purposes;</li>
            <li>
              to receive information about how we process your personal data and request to receive a copy of your
              personal data (right of access) (and in some circumstances you can ask ECNL to provide a copy to a third
              party);
            </li>
            <li>
              to request that ECNL rectifies or supplements your personal data when it is incomplete (right to
              rectification)
            </li>
            <li>
              to request to have the processing of your personal data restricted or to ask ECNL to temporarily stop
              using your personal data in certain circumstances (right to restrict data processing);
            </li>
            <li>
              to request that ECNL erases your personal data from its systems in some (but not all) circumstances where
              there is no good reason for ECNL to continue to use it (right to erasure); and
            </li>
            <li>
              to not be subject to decisions made solely on the basis of &quot;automated processing&quot; (i.e. the
              right not to be subject to decisions made solely by algorithms or computers without input from a human) in
              certain circumstances.
            </li>
          </ul>
          <p className="text-base">
            To help ECNL facilitate the exercise of your rights, ECNL may provide you with templates so that we know how
            best to support your request. If you have any questions in relation to the exercise of your rights please
            contact us.
          </p>
        </div>
        <div className="p-3 space-y-3 mb-12">
          <h1 className="text-lg font-bold">Right to complain to a supervisory authority:</h1>
          <p className="text-base">
            Whilst we hope we will be able to help you with any concern or complaint you might have concerning your
            personal data, you also have the right to lodge a complaint with a supervisory authority.
          </p>
          <p className="text-base">
            In the Netherlands you may turn to the Dutch Data Protection Authority (Autoriteit Persoonsgegevens) with
            any complaint in connection with the processing:
          </p>
          <ul className="pl-6 text-sm space-y-2">
            <li>
              Name of the authority: Dutch Data Protection Authority Abbreviated name of the authority: (Dutch DPA)
            </li>
            <li>Registered address: Bezuidenhoutseweg 30, 2594 AV Den Haag </li>
            <li>
              {' '}
              Website:{' '}
              <a
                href="https://www.autoriteitpersoonsgegevens.nl/en"
                target="_blank"
                rel="noreferrer"
                className="text-primary-800 hover:text-secondary"
              >
                https://www.autoriteitpersoonsgegevens.nl/en
              </a>{' '}
              Phone: (+31) - (0)70 - 888 85 00
            </li>
          </ul>
        </div>
      </Container>
    </Container>
  );
};
