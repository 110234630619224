import { CellProps } from 'react-table';
import { TableColumn } from 'components';
import { Article } from 'services';
import { formatDate } from 'helpers';

export const ARTICLES_TOOLTIP_MSG =
  'This is a place where you as a community member can share relevant updates about AML/CFT issues. For example, an announcement of input opportunities for your country/region/globally; or a recent relevant experience you/your organisation you might wish to share learnings from with the wider Global CFT Expert Hub community.. In short, anything that is not considered and/or does not fit into documents/materials but is valuable information to share with the community.';

export const columns = (
  deleteClicked: (rowData: Article) => void,
  userClicked: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void,
): TableColumn<Article>[] => [
  {
    Header: 'Name',
    accessor: 'name' as any,
    isSortable: true,
  },
  {
    Header: 'Publisher',
    accessor: 'authorName' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Article>) => {
      const rowData = row.original;
      return (
        <button
          className="text-sm font-semibold text-indigo-600 hover:text-indigo-500"
          onClick={(e) => userClicked(e, rowData.authorId)}
        >
          {rowData.authorName}
        </button>
      );
    },
  },
  {
    Header: 'Languages',
    accessor: 'originalLanguages' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Article>) => {
      const rowData = row.original;
      return (
        <div className="flex gap-1 flex-wrap">
          {rowData.translations.map((trans, i) => (
            <span
              key={i}
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 whitespace-nowrap"
            >
              {trans.language}
            </span>
          ))}
        </div>
      );
    },
  },
  {
    Header: 'Tags',
    accessor: 'tags' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Article>) => {
      const rowData = row.original;
      return (
        <div className="flex gap-1 flex-wrap">
          {rowData.tags.map((tag, id) => (
            <div key={id}>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 whitespace-nowrap">
                {tag.name}
              </span>
              <br />
            </div>
          ))}
        </div>
      );
    },
  },
  {
    Header: 'Date',
    accessor: 'vettedAt' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Article>) => {
      const rowData = row.original;
      if (rowData.vettedAt) {
        return <div>{formatDate(new Date(rowData.vettedAt))}</div>;
      }
      return <></>;
    },
  },
  /*
  {
    id: 'deleteButton',
    Header: '',
    Cell: ({ row }: CellProps<Article>) => {
      const rowData = row.original;

      return (
        <div className="flex justify-end">
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(ev) => {
              ev.stopPropagation();
              deleteClicked(rowData);
            }}
          >
            Delete
          </button>
        </div>
      );
    },
    width: 'auto',
  },
  */
];
