import { useEffect, useState } from 'react';
import { Container, Header } from 'components';
import { useSearchParams } from 'react-router-dom';
import { activate } from 'services';

export function ActivationPage() {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [header, setHeader] = useState('');
  const code = searchParams.get('code');
  const email = searchParams.get('email');

  const onActivation = async () => {
    if (email && code) {
      try {
        await activate(email, code);
        setMessage(
          "Your account is now active. After our administrator approves your account you'll receive an email and will be able to log in.",
        );
        setHeader('Waiting for approval...');
        setIsLoading(false);
      } catch (error: any) {
        const errorMsg = error?.response?.data?.title;
        setMessage(`This is ${errorMsg}.`);
        setHeader('Error occurred');
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);

    onActivation();
  }, []);

  return (
    <>
      <Header title={header} />

      <Container isLoading={isLoading}>
        <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">{message}</p>
      </Container>
    </>
  );
}
