import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { MessageType, PaginationFilters, showMessage, usePagination } from 'helpers';
import { deleteUser, fetchUsers, User, UserRole, UserStatus } from 'services';
import { useAuth } from 'GlobalProvider';

export const useUsersPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const initialFilters = useMemo(() => {
    const filters: PaginationFilters = {};

    if (user?.role === UserRole.CONTENTMANAGER) {
      filters['vettingStatus'] = UserStatus.APPROVED;
    }

    return filters;
  }, [user]);

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    tags,
    globalSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const [userToDelete, setUserToDelete] = useState<User | null>(null);

  const { data: queryData, isLoading: usersLoading } = useQuery(
    ['users', currentPage, pageSize, sortBy, sortDirection, tags, globalSearch, filters],
    () =>
      fetchUsers({ page: currentPage, pageSize, sortBy, sortDirection, tags, globalSearch, filters: initialFilters }),
  );
  const { mutateAsync: removeUser } = useMutation(deleteUser);

  const users = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = (row: User) => {
    navigate(`/admin/users/${row.id}`);
  };

  const onNewClick = () => {
    navigate('/admin/users/new');
  };

  const onDeleteConfirm = async () => {
    if (userToDelete) {
      try {
        await removeUser(userToDelete.id);
        showMessage('User successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries(['users']);
        setUserToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  const openConfirmationModal = (selectedUser: User | null) => {
    if (selectedUser) setUserToDelete(selectedUser);
  };

  const closeConfirmationModal = () => {
    setUserToDelete(null);
  };

  return {
    users,
    metaData,
    currentPage,
    globalSearch,
    isLoading: usersLoading,
    userToDelete,
    user,
    openConfirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onDeleteConfirm,
    onRowClick,
    onNewClick,
    onSortChange,
  };
};
