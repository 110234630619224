import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { BaseSyntheticEvent, createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { fetchTagCategory, postTagCategory, putTagCategory, TagCategory } from 'services';
import { defaultTagCategoryForm } from '../const';
import { TAG_CATEGORY_VALIDATION_SCHEMA } from './const';
import { categoryToCategoryForm } from './transformation';

const TagCategoryPageContext = createContext<{
  formState: FormState<TagCategory>;
  category: TagCategory | undefined;
  isCreate: boolean;
  onSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<TagCategory>;
  control: Control<TagCategory, any>;
}>({} as any);

type Props = {
  children?: ReactNode;
};

export function TagCategoryPageProvider({ children = <Outlet /> }: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: categoryId, isCreate } = useIdFromParams();

  const { data: category } = useQuery(['tag-categories', categoryId], () => fetchTagCategory(categoryId), {
    enabled: !isCreate,
  });
  const { mutateAsync: updateCategory } = useMutation(putTagCategory);
  const { mutateAsync: createCategory } = useMutation(postTagCategory);

  const { handleSubmit, register, formState, reset, control } = useForm<TagCategory>({
    defaultValues: defaultTagCategoryForm,
    resolver: yupResolver(TAG_CATEGORY_VALIDATION_SCHEMA),
  });

  const categoryForm = useMemo(() => {
    if (category) {
      return categoryToCategoryForm(category);
    }
    return defaultTagCategoryForm;
  }, [category]);

  useEffect(() => {
    reset(categoryForm);
  }, [categoryForm]);

  const onSubmit = handleSubmit(async (values: TagCategory) => {
    if (isCreate) {
      try {
        await createCategory(values);
        showMessage('Category successfully created.', MessageType.Success);
        queryClient.invalidateQueries(['tag-categories']);
        navigate('/admin/tags/categories');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateCategory(values);
      showMessage('Category successfully updated.', MessageType.Success);
      queryClient.invalidateQueries(['tag-categories']);
      navigate('/admin/tags/categories');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, isCreate, onSubmit, register, control, category }),
    [formState, isCreate, onSubmit, register, control, category],
  );

  return <TagCategoryPageContext.Provider value={providerValue}>{children}</TagCategoryPageContext.Provider>;
}

export const useTagCategoryPage = () => {
  return useContext(TagCategoryPageContext);
};
