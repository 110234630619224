import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { api } from 'services/api';
import { newsletterToPostReq, newsletterToPutReq, resToNewsletter } from './transformations';
import { Newsletter, NewsletterForm } from './types';

export const fetchNewsletters = async (pagination: PaginationRequest): Promise<DataWithMeta<Newsletter>> => {
  const url = paginationRequestToUrl('/newsletters', pagination);

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToNewsletter),
  };
};

export const fetchNewsletter = async (id: string): Promise<Newsletter> => {
  const resp = await api.get(`/newsletters/${id}`);
  return resToNewsletter(resp.data);
};

export const putNewsletter = async (data: NewsletterForm): Promise<Newsletter> => {
  const resp = await api.put(`/newsletters/${data.id}`, newsletterToPutReq(data));
  return resToNewsletter(resp.data);
};

export const postNewsletter = async (data: any): Promise<Newsletter> => {
  const resp = await api.post(`/newsletters`, newsletterToPostReq(data));
  return resToNewsletter(resp.data);
};

export const deleteNewsletter = async (id: string): Promise<Newsletter> => {
  const resp = await api.delete(`/newsletters/${id}`);
  return resToNewsletter(resp.data);
};
