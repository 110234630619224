import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AuthCardLayout, Button, Input } from 'components';
import { MessageType, showMessage } from 'helpers';
import { PassResetForm, reset } from 'services';
import { PASSWORD_VALIDATION_SCHEMA } from './const';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

export const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [disabled, setDisebled] = useState(false);
  const code = searchParams.get('code');
  const email = searchParams.get('email');
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm<PassResetForm>({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
    resolver: yupResolver(PASSWORD_VALIDATION_SCHEMA),
  });

  const onSubmit = handleSubmit(async (values) => {
    setDisebled(true);
    if (code && email)
      try {
        await reset(email, code, values);
        showMessage('Password reset successful.', MessageType.Success);
        navigate('/', { replace: true });
      } catch (error: any) {
        showMessage(error.response?.data?.title, MessageType.Error);
      }
  });

  return (
    <AuthCardLayout
      title="Reset your password"
      subTitle={
        <>
          Or{' '}
          <Link to="/register" className="font-medium text-primary-600 hover:text-primary-500">
            register for a new account
          </Link>
        </>
      }
    >
      <form className="space-y-6" onSubmit={onSubmit}>
        <Input control={control} name="password" label="Password" placeholder="Password" type="password" />
        <Input
          control={control}
          name="repeatPassword"
          label="Confirm Password"
          placeholder="Password"
          type="password"
        />
        <Button fullWidth type="submit" disabled={disabled}>
          Reset
        </Button>
      </form>
    </AuthCardLayout>
  );
};
