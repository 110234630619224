import { XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

type TagProps = {
  bgColor?: string;
  color?: string;
} & JSX.IntrinsicElements['button'];

export function Tag({
  className,
  children,
  type = 'button',
  bgColor = 'white',
  color = 'gray-800',
  ...buttonProps
}: TagProps) {
  return (
    <div
      className={clsx(
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium shadow-sm cursor-default border border-blue-500',
        `bg-${bgColor}`,
        `text-${color}`,
        className,
      )}
    >
      {children}
      <button className="ml-1 cursor-pointer" type={type} {...buttonProps}>
        <XIcon className="h-3 w-3 text-blue-500" aria-hidden="true" />
      </button>
    </div>
  );
}
