import { Container, HeaderSection, Search, Table } from 'components';

import { columns } from './const';
import { useContributorsPage } from './hooks';
import { Author } from 'services/contributors';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ContributorsPage = () => {
  const {
    authors,
    metaData,
    currentPage,
    isLoading,
    pagination,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onSortChange,
    onAuthorsSearch,
  } = useContributorsPage();

  return (
    <>
      <Search onChange={onAuthorsSearch} values={pagination} tagsPicker />

      <HeaderSection title="Contributors">
        {/* <Button onClick={onNewClick} Icon={PlusIcon} className="ml-auto">
          New Contributor
        </Button> */}
      </HeaderSection>

      <Container>
        <Table<Author>
          data={authors}
          columns={columns()}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>
    </>
  );
};
