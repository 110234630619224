import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { api } from 'services/api';
import { contributorToPostReq, contributorToPutReq, resToContributor } from './transformations';
import { Author } from './types';

export const fetchContributors = async (pagination: PaginationRequest): Promise<DataWithMeta<Author>> => {
  const url = paginationRequestToUrl('/users/authors', pagination);
  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToContributor),
  };
};

export const fetchContributor = async (id: string): Promise<Author> => {
  const resp = await api.get(`/users/authors/${id}`);
  return resToContributor(resp.data);
};

export const putContributor = async (data: any): Promise<Author> => {
  const resp = await api.put(`/users/authors/${data.id}`, contributorToPutReq(data));
  return resToContributor(resp.data);
};

export const postContributor = async (data: any): Promise<Author> => {
  const resp = await api.post(`/users/authors`, contributorToPostReq(data));
  return resToContributor(resp.data);
};

export const deleteContributor = async (id: string): Promise<Author> => {
  const resp = await api.delete(`/users/authors/${id}`);
  return resToContributor(resp.data);
};
