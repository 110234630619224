import { ComponentType } from 'react';
import clsx from 'clsx';

export const Button = ({ children, className, variant = 'primary', fullWidth = false, Icon, ...props }: Props) => {
  return (
    <button
      {...props}
      className={clsx(
        className,
        `inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-sm shadow-sm
        text-white focus:outline-none focus:ring-2 focus:ring-offset-2`,
        { 'w-full': fullWidth },
        { 'bg-primary-500 hover:bg-primary-500 focus:ring-primary-500': variant === 'primary' },
        { 'bg-secondary-500 hover:bg-secondary-500 focus:ring-secondary-500': variant === 'secondary' },
        { 'bg-green-500 hover:bg-green-700 focus:ring-green-500': variant === 'success' },
        { 'bg-red-600 hover:bg-red-700 focus:ring-red-500': variant === 'danger' },
      )}
    >
      {Icon && <Icon width={18} className="mr-2" />}
      {children}
    </button>
  );
};

type Props = {
  fullWidth?: boolean;
  variant?: 'primary' | 'secondary' | 'danger' | 'success';
  Icon?: ComponentType<React.ComponentProps<'svg'>>;
} & JSX.IntrinsicElements['button'];
