import { resToUser } from 'services/users';
import { AuthData } from './types';

export const resToAuthData = (res: any): AuthData => {
  return {
    token: res.token ?? '',
    user: res.user ? resToUser(res.user) : null,
  };
};

export const userToPutAuthUser = (data: any) => {
  const requestData: any = {
    firstName: data.firstName,
    lastName: data.lastName,
  };

  if (data.password) {
    requestData.password = data.password;
  }

  return requestData;
};

export const resToActiv = (res: any) => {
  return {
    token: res.token ?? '',
    user: res.user ? resToUser(res.user) : null,
    country: res.country ?? '',
    state: res.country ?? '',
    city: res.city ?? '',
  };
};
