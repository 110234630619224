import { ReactNode, FC } from 'react';
import clsx from 'clsx';

export const Section: FC<Props> = ({
  title,
  subtitle,
  className,
  headerClassName,
  titleClassName,
  subTitleClassName,
  children,
}) => {
  return (
    <section className={clsx('p-8', className)}>
      <div className="sm:flex justify-between align-items">
        <div className={headerClassName}>
          <h3 className={clsx('text-lg font-medium leading-6 text-gray-900', titleClassName)}>{title}</h3>
          <p className={clsx('mt-1 mb-1 text-sm text-gray-600', subTitleClassName)}>{subtitle}</p>
        </div>
      </div>
      {children}
    </section>
  );
};

export type Props = {
  title: string;
  subtitle: string;
  children?: ReactNode;
  className?: string;
  headerClassName?: string;
  titleClassName?: string;
  subTitleClassName?: string;
};
