import { QuestionForm } from 'services';
import * as yup from 'yup';

export const defaultQuestionForm: QuestionForm = {
  id: '',
  title: '',
  text: '',
  answer: '',
};

export const QUESTION_VALIDATION_SCHEMA = yup.object({
  title: yup.string().label('Title').required(),
  text: yup.string().label('Question').required(),
});
