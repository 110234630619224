import {
  BookmarkIcon,
  HomeIcon,
  TagIcon,
  UsersIcon,
  DocumentTextIcon,
  FolderIcon,
  QuestionMarkCircleIcon,
  NewspaperIcon,
  ViewListIcon,
  RefreshIcon,
} from '@heroicons/react/outline';

import { NavigationItem } from './types';

export const NAVIGATION: NavigationItem[] = [
  {
    to: '/admin',
    name: 'Dashboard',
    icon: HomeIcon,
    end: true,
  },
  {
    to: '/admin/users',
    name: 'Users',
    icon: UsersIcon,
  },
  {
    to: '/admin/articles',
    name: 'Community Updates',
    icon: DocumentTextIcon,
  },
  {
    to: '/admin/documents',
    name: 'Documents',
    icon: FolderIcon,
  },
  {
    to: '/admin/tags',
    name: 'Tags',
    icon: TagIcon,
    subMenu: [
      {
        to: '/admin/tags/categories',
        name: 'Categories',
        icon: BookmarkIcon,
      },
    ],
  },
  {
    to: '/admin/questions',
    name: 'Questions',
    icon: QuestionMarkCircleIcon,
  },
  {
    to: '/admin/newsletters',
    name: 'Newsletters',
    icon: NewspaperIcon,
  },
  {
    to: '/admin/sections',
    name: 'Sections',
    icon: ViewListIcon,
  },
  {
    to: '/admin/restore',
    name: 'Restore Account',
    icon: RefreshIcon,
  },
];
