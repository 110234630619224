import { Document, DocumentTranslation } from './types';

export const resToDocument = (res: any): Document => {
  return {
    id: res.id ?? '',
    name: res.name ?? '',
    isTemplate: res.isTemplate ?? false,
    originalLanguage: res.originalLanguage ?? '',
    sectionId: res.sectionId ?? '',
    sectionName: res.sectionName ?? '',
    authorId: res.authorId ?? '',
    authorName: res.authorName ?? '',
    originalAuthor: res.originalAuthor ?? '',
    tags: res.tags ?? [],
    translations: res.translations.map(resToDocumentTranslation),
    vettedAt: res.vettedAt ?? '',
    vettingStatus: res.vettingStatus ?? '',
  };
};

export const resToDocumentTranslation = (res: any): DocumentTranslation => {
  return {
    id: res.id ?? '',
    language: res.language ?? '',
    fileId: res.fileId ?? '',
    fileUrl: res.fileUrl ?? '',
    name: res.name ?? '',
    description: res.description ?? '',
  };
};

export const documentToPutReq = (data: any) => {
  // TODO: should be implemented in a better way
  const defaultLanguage = data.translations.find((translation: any) => translation.language === 'en');

  return {
    id: data.id,
    name: defaultLanguage?.name ?? '',
    isTemplate: data.isTemplate,
    originalLanguage: defaultLanguage?.language ?? '',
    sectionId: data.sectionId,
    sectionName: data.sectionName,
    authorId: data.authorId,
    authorName: data.authorName,
    originalAuthor: data.originalAuthor ?? '',
    tags: data.tags.map((tag: any) => tag.id),
    translations: data.translations.map(documentTranslationToPutReq),
    vettedAt: data.vettedAt,
  };
};

export const documentToPostReq = (data: any) => {
  // TODO: should be implemented in a better way
  const defaultLanguage = data.translations.find((translation: any) => translation.language === 'en');

  return {
    name: defaultLanguage?.name ?? '',
    isTemplate: data.isTemplate,
    originalLanguage: defaultLanguage?.language ?? '',
    sectionId: data.sectionId,
    sectionName: data.sectionName,
    authorId: data.authorId,
    authorName: data.authorName,
    originalAuthor: data.originalAuthor ?? '',
    tags: data.tags.map((tag: any) => tag.id),
    translations: data.translations.map(documentTranslationToPutReq),
  };
};

export const documentTranslationToPutReq = (data: any) => {
  return {
    id: data.id,
    language: data.language,
    fileId: data.file.id,
    fileUrl: data.file.url,
    name: data.name,
    description: data.description,
  };
};
