import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { api } from 'services/api';
import { ArticleStatus } from './const';
import { articleToPostReq, articleToPutReq, resToArticle } from './transformations';
import { Article } from './types';

export const fetchArticles = async (pagination: PaginationRequest): Promise<DataWithMeta<Article>> => {
  const url = paginationRequestToUrl('/articles', pagination);

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToArticle),
  };
};

export const fetchAllArticles = async (): Promise<DataWithMeta<Article>> => {
  const resp = await api.get('/articles?pageSize=9999');

  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToArticle),
  };
};

export const fetchArticle = async (id: string): Promise<Article> => {
  const resp = await api.get(`/articles/${id}`);
  return resToArticle(resp.data);
};

export const putArticle = async (data: any): Promise<Article> => {
  const resp = await api.put(`/articles/${data.id}`, articleToPutReq(data));
  return resToArticle(resp.data);
};

export const postArticle = async (data: any): Promise<Article> => {
  const resp = await api.post(`/articles`, articleToPostReq(data));
  return resToArticle(resp.data);
};

export const deleteArticle = async (id: string): Promise<Article> => {
  const resp = await api.delete(`/articles/${id}`);
  return resToArticle(resp.data);
};

export const approveArticle = async (id: string): Promise<Article> => {
  const resp = await api.post(`/articles/${id}/vetting`, { vettingStatus: ArticleStatus.APPROVED });
  return resToArticle(resp.data);
};

export const denyArticle = async (id: string): Promise<Article> => {
  const resp = await api.post(`/articles/${id}/vetting`, { vettingStatus: ArticleStatus.DENIED });
  return resToArticle(resp.data);
};
