import * as yup from 'yup';

import { validateEmail } from 'services';

export const VALIDATION_SCHEMA = yup.object({
  email: yup
    .string()
    .email('Email not valid.')
    .required('Email is required.')
    .test('emailUnique', 'Email already exists.', async (value) => {
      const isEmailValid = await validateEmail(value ?? '');
      return !isEmailValid;
    }),
  password: yup.string().required('Password is required.'),
  firstName: yup.string().required('First name is required.'),
  lastName: yup.string().required('Last name is required.'),
  language: yup.string().label('Language').required(),
  alias: yup.string().required('Alias is required.'),
});
