import { User } from 'services';

export const profileToProfileForm = (profile: User): User => ({
  alias: profile.alias,
  city: profile.city,
  country: profile.country,
  email: profile.email,
  firstName: profile.firstName,
  id: profile.id,
  organization: profile.organization,
  isPublic: profile.isPublic,
  language: profile.language,
  lastName: profile.lastName,
  role: profile.role,
  state: profile.state,
  vettedAt: profile.vettedAt,
  vettingStatus: profile.vettingStatus,
  contacts: profile.contacts,
  password: '',
  confirmPassword: '',
  avatarFileId: profile.avatarFileId,
  avatarFileUrl: profile.avatarFileUrl,
  avatar: profile.avatar ? { id: profile.avatar.id, url: profile.avatar.url } : null,
  tags: profile.tags,
});
