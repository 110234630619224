import { CellProps } from 'react-table';

import { TableColumn, Tooltip } from 'components';
import { Newsletter } from 'services';
import { shortenId } from 'helpers';
import clsx from 'clsx';
import { NewsletterStatus } from 'services/newsletters/const';

export const columns = (deleteClicked: (rowData: Newsletter) => void): TableColumn<Newsletter>[] => [
  {
    Header: 'ID',
    accessor: 'id' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Newsletter>) => {
      const rowData = row.original;
      return (
        <Tooltip
          popoverMessage="Copied!"
          tooltipMessage={rowData.id}
          onClick={() => navigator.clipboard.writeText(rowData.id)}
        >
          {shortenId(rowData.id)}
        </Tooltip>
      );
    },
  },
  {
    Header: 'status',
    accessor: 'status' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Newsletter>) => {
      const rowData = row.original;
      return (
        <span
          className={clsx(
            'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
            rowData.status === NewsletterStatus.PENDING
              ? 'bg-gray-200 text-gray-800'
              : rowData.status === NewsletterStatus.READY
              ? 'bg-green-100 text-green-800'
              : rowData.status === NewsletterStatus.GENERATED
              ? 'bg-yellow-200 text-yellow-800'
              : rowData.status === NewsletterStatus.READYTOBESENT
              ? 'bg-green-100 text-green-800'
              : 'bg-blue-100 text-blue-800',
          )}
        >
          {rowData.status || NewsletterStatus.PENDING}
        </span>
      );
    },
  },
  {
    Header: 'Publisher',
    accessor: 'publisherAlias' as any,
    isSortable: true,
  },
  {
    Header: 'Article',
    accessor: 'mainArticleName' as any,
    isSortable: true,
  },
  {
    id: 'deleteButton',
    Header: '',
    Cell: ({ row }: CellProps<Newsletter>) => {
      const rowData = row.original;

      return (
        <div className="flex justify-end">
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(ev) => {
              ev.stopPropagation();
              deleteClicked(rowData);
            }}
          >
            Delete
          </button>
        </div>
      );
    },
    width: 'auto',
  },
];
