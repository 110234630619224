import { ArrowLeftIcon } from '@heroicons/react/outline';
import { Container, HeaderSection } from 'components';
import { useNavigate } from 'react-router-dom';

export const ContactPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeaderSection
        title="Contact"
        leftAction={
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="z-10 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-black bg-transparent hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-black mr-2"
          >
            <ArrowLeftIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
          </button>
        }
      ></HeaderSection>
      <Container className="mx-auto container">
        <div className="p-0 lg:pl-[33%]">
          {' '}
          <div className="mb-12 flex justify-center flex-col">
            <h1 className="block text-xl font-medium leading-5 text-gray-500 mb-3">Contact Administrator via mail</h1>
            <a
              href="mailto:admin@globalcftexperthub.org"
              className="text-sm font-semibold text-primary-800 hover:text-primary-850 underline underline-offset-2"
            >
              admin@globalcftexperthub.org
            </a>
          </div>
          <div className="text-sm font-semibold text-gray-700 mb-12">
            <h1 className="block text-xl font-medium leading-5 text-gray-500 mb-3">Our mailing address is:</h1>
            <p>European Center for Not-for-Profit Law</p>
            <p>Riviervismarkt 5, 2513 AM,</p>
            <p>The Hague, Netherlands</p>
          </div>
          <div className="mb-12 justify-center items-center">
            <h1 className="block text-xl font-medium leading-5 text-gray-500 mb-3">Social media links:</h1>

            <ul className="text-sm font-semibold text-primary-800 hover:text-primary-850 underline underline-offset-2 sm:flex">
              <li className="mt-2 pl-3 lg:pl-0 flex items-center">
                <figure className="h-5 w-5 flex-shrink-0 text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-hidden="true">
                    <path
                      fill="#189D98"
                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                    />
                  </svg>
                </figure>
                <a href="https://twitter.com/enablingngolaw" target="_blank" rel="noreferrer" className="mx-3">
                  Twitter
                </a>
              </li>
              <li className="mt-2 pl-3 flex">
                <figure className="h-5 w-5 flex-shrink-0 text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 150 265"
                    className="h-10 w-6 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  >
                    <path
                      fill="#189D98"
                      d="m154 17.5c-1.82-6.73-7.07-12-13.8-13.8-9.04-3.49-96.6-5.2-122 0.1-6.73 1.82-12 7.07-13.8 13.8-4.08 17.9-4.39 56.6 0.1 74.9 1.82 6.73 7.07 12 13.8 13.8 17.9 4.12 103 4.7 122 0 6.73-1.82 12-7.07 13.8-13.8 4.35-19.5 4.66-55.8-0.1-75z"
                    />
                    <path d="m105 55-40.8-23.4v46.8z" fill="#fff" />
                  </svg>
                </figure>
                <a
                  href="https://www.youtube.com/@europeancenterfornot-for-p1243"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-3"
                >
                  Youtube
                </a>
              </li>
              <li className="mt-2 pl-3 flex">
                <figure className="h-5 w-5 flex-shrink-0 text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-hidden="true">
                    <path
                      fill="#189D98"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    />
                  </svg>
                </figure>
                <a
                  href="https://www.linkedin.com/company/european-center-for-not-for-profit-law/"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-3"
                >
                  {' '}
                  LinkedIn
                </a>
              </li>
              <li className="mt-2 pl-3 flex">
                <figure className="h-6 w-5 flex-shrink-0 text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 297" aria-hidden="true">
                    <path
                      fill="#189D98"
                      d="M211.80734 139.0875c-3.18125 16.36625-28.4925 34.2775-57.5625 37.74875-15.15875 1.80875-30.08375 3.47125-45.99875 2.74125-26.0275-1.1925-46.565-6.2125-46.565-6.2125 0 2.53375.15625 4.94625.46875 7.2025 3.38375 25.68625 25.47 27.225 46.39125 27.9425 21.11625.7225 39.91875-5.20625 39.91875-5.20625l.8675 19.09s-14.77 7.93125-41.08125 9.39c-14.50875.7975-32.52375-.365-53.50625-5.91875C9.23234 213.82 1.40609 165.31125.20859 116.09125c-.365-14.61375-.14-28.39375-.14-39.91875 0-50.33 32.97625-65.0825 32.97625-65.0825C49.67234 3.45375 78.20359.2425 107.86484 0h.72875c29.66125.2425 58.21125 3.45375 74.8375 11.09 0 0 32.975 14.7525 32.975 65.0825 0 0 .41375 37.13375-4.59875 62.915"
                    />
                    <path
                      fill="#fff"
                      d="M177.50984 80.077v60.94125h-24.14375v-59.15c0-12.46875-5.24625-18.7975-15.74-18.7975-11.6025 0-17.4175 7.5075-17.4175 22.3525v32.37625H96.20734V85.42325c0-14.845-5.81625-22.3525-17.41875-22.3525-10.49375 0-15.74 6.32875-15.74 18.7975v59.15H38.90484V80.077c0-12.455 3.17125-22.3525 9.54125-29.675 6.56875-7.3225 15.17125-11.07625 25.85-11.07625 12.355 0 21.71125 4.74875 27.8975 14.2475l6.01375 10.08125 6.015-10.08125c6.185-9.49875 15.54125-14.2475 27.8975-14.2475 10.6775 0 19.28 3.75375 25.85 11.07625 6.36875 7.3225 9.54 17.22 9.54 29.675"
                    />
                  </svg>
                </figure>
                <a href="https://eupolicy.social/@ecnl" target="_blank" rel="noreferrer" className="mx-3">
                  Mastodon
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <div className="bg-gray-200 w-full h-12 text-center p-3 mb-12">
        <p className="text-sm font-semibold text-grey-700">
          Copyright &copy;{new Date().getFullYear()} European Center for Not-for-Profit Law (ECNL). All rights reserved.
        </p>
      </div>
    </>
  );
};
