import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MessageType, showMessage, usePagination } from 'helpers';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteTag, fetchAllTagCategories, fetchTags, Tag } from 'services';

export function useTagsPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    tags: paginationTags,
    sortDirection,
    globalSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const [tagToDelete, setTagToDelete] = useState<Tag | null>(null);

  const { data: queryData, isLoading: tagsLoading } = useQuery(
    ['tags', currentPage, pageSize, sortBy, sortDirection, globalSearch, paginationTags, filters],
    () =>
      fetchTags({ page: currentPage, pageSize, sortBy, sortDirection, globalSearch, filters, tags: paginationTags }),
  );
  const { data: queryCategoriesData, isLoading: categoriesLoading } = useQuery(
    ['tag-categories', 1, 9999, filters],
    () => fetchAllTagCategories(),
  );

  const { mutateAsync: removeTag } = useMutation(deleteTag);

  const tags = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const tagCategories = useMemo(() => queryCategoriesData?.data ?? [], [queryCategoriesData?.data]);

  const onRowClick = (row: Tag) => {
    navigate(`/admin/tags/${row.id}`);
  };

  const onNewClick = () => {
    navigate('/admin/tags/new');
  };

  const onDeleteConfirm = async () => {
    if (tagToDelete) {
      try {
        await removeTag(tagToDelete.id);
        showMessage('Tag successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries(['tags']);
        setTagToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  const openConfirmationModal = (selectedTag: Tag | null) => {
    if (selectedTag) setTagToDelete(selectedTag);
  };

  const closeConfirmationModal = () => {
    setTagToDelete(null);
  };

  return {
    currentPage,
    globalSearch,
    tags,
    tagCategories,
    metaData,
    isLoading: tagsLoading || categoriesLoading,
    onSortChange,
    onPageSizeChange,
    onPageChange,
    onSearchChange,
    onNewClick,
    onRowClick,
    openConfirmationModal,
    closeConfirmationModal,
    onDeleteConfirm,
    tagToDelete,
  };
}
