import { ReactNode } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

const navigation = [
  { name: 'Login', to: '/login' },
  { name: 'Register', to: '/register' },
];

export const UnauthLayout = ({ children = <Outlet /> }: Props) => {
  return (
    <div className="min-h-screen flex flex-col h-screen background overflow-scroll">
      <Disclosure as="nav" className="bg-primary border-b border-gray-200 z-20 sticky top-0">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex justify-between w-full">
                  <div className="flex items-center">
                    <img className="block lg:hidden h-8 w-auto" src="/logo.svg" alt="Workflow" />
                    <img className="hidden lg:block h-8 w-auto" src="/logo.svg" alt="Workflow" />
                  </div>
                  <div className="hidden sm:-my-px sm:ml-3 md:ml-6 sm:flex sm:space-x-4 md:space-x-8">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.to}
                        className={({ isActive }) =>
                          [
                            'border-transparent font-inter text-blue-200 hover:border-gray-300 hover:text-blue-50',
                            'inline-flex items-center px-1 pt-1 border-b-4 text-base font-semibold leading-5',
                            isActive ? 'border-secondary-500 border-b-4 !text-white justify-center' : undefined,
                          ]
                            .filter(Boolean)
                            .join(' ')
                        }
                        // aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-primary inline-flex items-center justify-center p-2 rounded-md text-blue-200 hover:text-white focus:outline-none">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden z-10">
              <div className="pt-2 pb-3 px-4 space-y-2 flex flex-col">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.to}
                    className={({ isActive }) =>
                      [
                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                        isActive
                          ? 'border-secondary-500 text-white bg-primary-700'
                          : 'border-transparent text-blue-200 hover:border-blue-50 hover:text-blue-50 hover:bg-primary-700',
                      ]
                        .filter(Boolean)
                        .join(' ')
                    }
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="px-8 flex flex-col flex-1 h-screen z-10">
        <main className="flex-1 flex-col flex h-full w-full max-w-7xl mx-auto font-barlow">
          {/* Replace with your content */}
          {children}
          {/* /End replace */}
        </main>
      </div>
    </div>
  );
};
interface Props {
  children?: ReactNode;
}
