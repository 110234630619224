import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { api } from 'services/api';
import {
  categoryToPostReq,
  categoryToPutReq,
  resToTag,
  resToTagCategory,
  tagToPostReq,
  tagToPutReq,
} from './transformations';
import { Tag, TagCategory } from './types';

export const fetchTags = async (pagination: PaginationRequest): Promise<DataWithMeta<Tag>> => {
  const url = paginationRequestToUrl('/tags', pagination);

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToTag),
  };
};

export const fetchTag = async (id: string): Promise<Tag> => {
  const resp = await api.get(`/tags/${id}`);
  return resToTag(resp.data);
};

export const putTag = async (data: any): Promise<Tag> => {
  const resp = await api.put(`/tags/${data.id}`, tagToPutReq(data));
  return resToTag(resp.data);
};

export const postTag = async (data: any): Promise<Tag> => {
  const resp = await api.post(`/tags`, tagToPostReq(data));
  return resToTag(resp.data);
};

export const deleteTag = async (id: string): Promise<Tag> => {
  const resp = await api.delete(`/tags/${id}`);
  return resToTag(resp.data);
};

export const fetchTagCategories = async (pagination: PaginationRequest): Promise<DataWithMeta<TagCategory>> => {
  const url = paginationRequestToUrl('/tag-categories', pagination);

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToTagCategory),
  };
};

export const fetchAllTagCategories = async (): Promise<DataWithMeta<TagCategory>> => {
  const resp = await api.get('/tag-categories?pageSize=9999');

  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToTagCategory),
  };
};

export const fetchTagCategory = async (id: string): Promise<TagCategory> => {
  const resp = await api.get(`/tag-categories/${id}`);
  return resToTagCategory(resp.data);
};

export const putTagCategory = async (data: any): Promise<TagCategory> => {
  const resp = await api.put(`/tag-categories/${data.id}`, categoryToPutReq(data));
  return resToTagCategory(resp.data);
};

export const postTagCategory = async (data: any): Promise<TagCategory> => {
  const resp = await api.post(`/tag-categories`, categoryToPostReq(data));
  return resToTagCategory(resp.data);
};

export const deleteTagCategory = async (id: string): Promise<TagCategory> => {
  const resp = await api.delete(`/tag-categories/${id}`);
  return resToTagCategory(resp.data);
};

export const fetchAllTags = async (): Promise<DataWithMeta<Tag>> => {
  const resp = await api.get('/tags?pageSize=9999');

  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToTag),
  };
};
