import React, { createContext, useContext, useMemo, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { Control, FormState, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Question, fetchQuestion, postQuestion, putQuestion, QuestionForm } from 'services';
import { defaultQuestionForm, QUESTION_VALIDATION_SCHEMA } from '../consts';
import { questionToQuestionForm } from './transformations';

const QuestionPageContext = createContext<{
  isCreate: boolean;
  formState: FormState<Question>;
  control: Control<QuestionForm, any>;
  question?: Question;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}>({} as any);

// This will be used in case we state shared inside the module
export const QuestionPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const { id: questionId, isCreate } = useIdFromParams();
  const queryClient = useQueryClient();

  const { data: question } = useQuery(['questions', questionId], () => fetchQuestion(questionId), {
    enabled: !isCreate,
  });

  const { mutateAsync: updateQuestion } = useMutation(putQuestion);
  const { mutateAsync: createQuestion } = useMutation(postQuestion);

  const { formState, control, handleSubmit, reset } = useForm<Question>({
    defaultValues: defaultQuestionForm,
    resolver: yupResolver(QUESTION_VALIDATION_SCHEMA),
  });

  const questionForm = useMemo(() => {
    if (question) {
      return questionToQuestionForm(question);
    }
    return defaultQuestionForm;
  }, [question]);

  useEffect(() => {
    reset(questionForm);
  }, [questionForm]);

  const onSubmit = handleSubmit(async (values: QuestionForm) => {
    if (isCreate) {
      try {
        await createQuestion(values);
        showMessage('Question successfully sent.', MessageType.Success);
        queryClient.invalidateQueries(['questions']);
        navigate('/admin/questions');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateQuestion(values);
      showMessage('Question successfully updated.', MessageType.Success);
      queryClient.invalidateQueries(['questions']);
      navigate('/admin/questions');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, control, onSubmit, isCreate, question }),
    [formState, control, onSubmit, isCreate, question],
  );

  return <QuestionPageContext.Provider value={providerValue}>{children}</QuestionPageContext.Provider>;
};

export const useQuestionPage = () => {
  return useContext(QuestionPageContext);
};

interface Props {
  children?: React.ReactNode;
}
