import { api } from 'services';
import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';

import { documentToPostReq, documentToPutReq, resToDocument } from './transformations';
import { Document } from './types';
import { DocumentStatus } from './const';

export const fetchDocuments = async (pagination: PaginationRequest): Promise<DataWithMeta<Document>> => {
  const url = paginationRequestToUrl('/documents', pagination);

  const resp = await api.get(url);
  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToDocument),
  };
};

export const fetchDocument = async (id: string): Promise<Document> => {
  const resp = await api.get(`/documents/${id}`);
  return resToDocument(resp.data);
};

export const putDocument = async (data: any): Promise<Document> => {
  const resp = await api.put(`/documents/${data.id}`, documentToPutReq(data));
  return resToDocument(resp.data);
};

export const postDocument = async (data: any): Promise<Document> => {
  const resp = await api.post(`/documents`, documentToPostReq(data));
  return resToDocument(resp.data);
};

export const deleteDocument = async (id: string): Promise<Document> => {
  const resp = await api.delete(`/documents/${id}`);
  return resToDocument(resp.data);
};

export const approveDocument = async (id: string): Promise<Document> => {
  const resp = await api.post(`/documents/${id}/vetting`, { vettingStatus: DocumentStatus.APPROVED });
  return resToDocument(resp.data);
};

export const denyDocument = async (id: string): Promise<Document> => {
  const resp = await api.post(`/documents/${id}/vetting`, { vettingStatus: DocumentStatus.DENIED });
  return resToDocument(resp.data);
};
