import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MessageType, PaginationRequest, showMessage, SortDirection, usePagination } from 'helpers';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { fetchAllSections, fetchSections, Section } from 'services';
import { deleteDocument, Document, fetchDocuments } from 'services/documents';
import { DefaultPaginationRequest } from 'helpers';
import _ from 'lodash';
import { DocumentStatus } from 'services/documents/const';

export const DocumentsPageContext = createContext<{
  sections: Section[];
  sectionsLoading: boolean;
  sectionMetaData: any;
  onSectionSearchChange: (searchValue: string) => void;
  onSectionPageChange: (newPage: number) => void;
  onSectionPageSizeChange: (newPageSize: number) => void;
  onSectionSortChange: (accessor: string, sort: SortDirection) => void;
  sectionCurrentPage: number;
  documents: Document[];
  metaData: any;
  currentPage: number;
  usersLoading: boolean;
  documentToDelete: Document | null;
  openConfirmationModal: (selectedDocument: Document | null) => void;
  closeConfirmationModal: () => void;
  onSearchChange: (searchValue: string) => void;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onDeleteConfirm: () => Promise<void>;
  onRowClick: (row: Document) => void;
  onNewClick: () => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  pagination: PaginationRequest;
  searchActive: boolean;
  onSectionRowClick: (row: Section) => void;
  onDocumentSearchReset: () => void;
  onUserClicked: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void;
  sectionId: string;
  selectSections: Section[];
  handleSearchChange: (values: any) => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const DocumentsPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [documentToDelete, setDocumentToDelete] = useState<Document | null>(null);
  const [searchActive, setSearchActive] = useState<boolean>(false);

  const { mutateAsync: removeDocument } = useMutation(deleteDocument);

  const [sectionId, setSectionId] = useState('');

  const {
    currentPage: sectionCurrentPage,
    pageSize: sectionPageSize,
    sortBy: sectionSortBy,
    sortDirection: sectionSortDirection,
    filters: sectionFilters,
    tags: sectionTags,
    globalSearch: sectionGlobalSearch,
    onSearchChange: onSectionSearchChange,
    onPageChange: onSectionPageChange,
    onPageSizeChange: onSectionPageSizeChange,
    onSortChange: onSectionSortChange,
  } = usePagination();

  const {
    currentPage,
    globalSearch,
    pagination,
    setPagination,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFiltersChange,
  } = usePagination();

  const { data: sectionData, isLoading: sectionsLoading } = useQuery(
    ['sections', sectionCurrentPage, sectionPageSize, sectionSortBy, sectionSortDirection, sectionFilters],
    () =>
      fetchSections({
        page: sectionCurrentPage,
        pageSize: sectionPageSize,
        sortBy: sectionSortBy,
        sortDirection: sectionSortDirection,
        filters: sectionFilters,
        tags: sectionTags,
        globalSearch: sectionGlobalSearch,
      }),
  );

  const { data: selectSectionsData } = useQuery(['selectSections'], () => fetchAllSections());

  const selectSections = useMemo(() => selectSectionsData?.data ?? [], [selectSectionsData?.data]);
  const sections = useMemo(() => sectionData?.data ?? [], [sectionData?.data]);
  const sectionMetaData = useMemo(() => sectionData?.meta, [sectionData?.meta]);

  const { data: queryData, isLoading: usersLoading } = useQuery(['documents', pagination], () =>
    fetchDocuments({ ...pagination, filters: { ...pagination.filters, vettingStatus: DocumentStatus.APPROVED } }),
  );

  useEffect(() => {
    if (_.isEqual(pagination, DefaultPaginationRequest)) {
      setSearchActive(false);
    } else {
      setSearchActive(true);
    }
  }, [pagination]);

  const documents = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onSectionRowClick = (row: Section) => {
    onFiltersChange({ sectionId: row.id });
    setSearchActive(true);
    setSectionId(row.id);
  };

  const onDocumentSearchReset = () => {
    setPagination(DefaultPaginationRequest);
    setSearchActive(false);
    setSectionId('');
  };

  const handleSearchChange = (values: any) => {
    setPagination((oldPagination: PaginationRequest) => ({
      ...oldPagination,
      globalSearch: values.globalSearch,
      tags: values.tags,
      filters: {
        ...oldPagination.filters,
        ...values.filters,
      },
      sectionId: sectionId,
      page: 1,
    }));
    setSearchActive(true);
  };

  const onUserClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    return navigate(`/authors/${id}`);
  };

  const onRowClick = (row: Document) => {
    navigate(`/documents/${row.id}`);
    setSectionId(row.id);
  };

  const onNewClick = () => {
    navigate('/documents/new');
  };

  const onDeleteConfirm = async () => {
    if (documentToDelete) {
      try {
        await removeDocument(documentToDelete.id);
        showMessage('Document successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries(['documents']);
        setDocumentToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  const openConfirmationModal = (selectedDocument: Document | null) => {
    if (selectedDocument) setDocumentToDelete(selectedDocument);
  };

  const closeConfirmationModal = () => {
    setDocumentToDelete(null);
  };

  const providerValue = useMemo(
    () => ({
      sections,
      sectionsLoading,
      sectionMetaData,
      onSectionSearchChange,
      onSectionPageChange,
      onSectionPageSizeChange,
      onSectionSortChange,
      sectionCurrentPage,
      documents,
      metaData,
      currentPage,
      globalSearch,
      usersLoading,
      documentToDelete,
      openConfirmationModal,
      closeConfirmationModal,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onDeleteConfirm,
      onRowClick,
      onNewClick,
      onSortChange,
      pagination,
      searchActive,
      onSectionRowClick,
      onDocumentSearchReset,
      onUserClicked,
      sectionId,
      selectSections,
      handleSearchChange,
    }),
    [
      sections,
      sectionsLoading,
      sectionMetaData,
      onSectionSearchChange,
      onSectionPageChange,
      onSectionPageSizeChange,
      onSectionSortChange,
      sectionCurrentPage,
      documents,
      metaData,
      currentPage,
      globalSearch,
      usersLoading,
      documentToDelete,
      openConfirmationModal,
      closeConfirmationModal,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onDeleteConfirm,
      onRowClick,
      onNewClick,
      onSortChange,
      pagination,
      searchActive,
      onSectionRowClick,
      onDocumentSearchReset,
      onUserClicked,
      sectionId,
      selectSections,
      handleSearchChange,
    ],
  );

  return <DocumentsPageContext.Provider value={providerValue}>{children}</DocumentsPageContext.Provider>;
};

export const useDocumentsPageProvider = () => {
  return useContext(DocumentsPageContext);
};

interface Props {
  children?: React.ReactNode;
}
