import { useNavigate } from 'react-router-dom';

import { Button, Divider, Input, Card, Select, AvatarUploader, TagPicker, Switch, Header, Container } from 'components';
import { useUserPage } from './UserPageProvider';
import { useLanguagesOptions } from 'modules/common';
import { UserStatus } from 'services';
import { countryOptions } from 'modules/common/hooks/use-countries-options';
import { roleOptions } from './const';
import { useAuth } from 'GlobalProvider';
import { UserRole } from 'services';

export const UserPage = () => {
  const { control, onSubmit, user, onApproveSubmit, onDenySubmit, isCreate } = useUserPage();
  const { user: me } = useAuth();
  const languagesOptions = useLanguagesOptions();
  const navigate = useNavigate();

  return (
    <>
      <Header title="User" onGoBackPress={() => navigate(-1)}>
        {me?.role === UserRole.ADMIN ? (
          <div className="flex justify-between items-center">
            <Button type="submit" className="ml-auto" onClick={onSubmit}>
              Save
            </Button>
            {!isCreate && user && (
              <>
                {user?.vettingStatus === UserStatus.DENIED ? (
                  <>
                    <Button onClick={onApproveSubmit} className="ml-4" variant="success" type="button">
                      Approve
                    </Button>
                  </>
                ) : user?.vettingStatus === UserStatus.APPROVED ? (
                  <Button onClick={onDenySubmit} className="ml-4" variant="danger" type="button">
                    Deny
                  </Button>
                ) : (
                  <>
                    <Button onClick={onApproveSubmit} className="ml-4" variant="success" type="button">
                      Approve
                    </Button>
                    <Button onClick={onDenySubmit} className="ml-4" variant="danger" type="button">
                      Deny
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        ) : null}
      </Header>
      <Container className="mb-3">
        <form onSubmit={onSubmit}>
          <div className="space-y-10">
            <Card innerClassName="space-y-10">
              <Divider title="User info" />
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <Input control={control} name="firstName" id="firstName" label="First name" />
                <Input control={control} name="lastName" id="lastName" label="Last name" />
                <Input control={control} name="alias" id="alias" label="Alias" />
                <Input control={control} name="email" id="email" label="Email" type="email" />
                <Select control={control} name="country" id="country" label="Country" options={countryOptions} />
                <Input control={control} name="state" id="state" label="State" />
                <Input control={control} name="city" id="city" label="City" />
                <AvatarUploader control={control} name="avatar" label="Avatar" />
              </div>
            </Card>

            <Card innerClassName="space-y-10">
              <Divider title="Password" />
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <Input control={control} name="password" id="password" label="Password" type="password" />
                <Input
                  control={control}
                  name="confirmPassword"
                  id="confirmPassword"
                  label="Confirm password"
                  type="password"
                />
              </div>
            </Card>

            <Card className="overflow-visible" innerClassName="space-y-10">
              <Divider title="Tags" />

              <TagPicker control={control} name="tags" label="Add tags" />
            </Card>

            <Card innerClassName="space-y-10">
              <Divider title="Settings" />
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <Switch control={control} name="isPublic" description="Make profile visible to other users">
                  Public
                </Switch>

                <Select control={control} name="language" id="language" label="Language" options={languagesOptions} />
                <div>&nbsp;</div>
                <Select control={control} name="role" id="role" label="Role" options={roleOptions} />
              </div>
            </Card>
          </div>
        </form>
      </Container>
    </>
  );
};
