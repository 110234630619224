import {
  Button,
  ConfirmationModal,
  Input,
  TagPicker,
  Select,
  LanguageModal,
  Header,
  Container,
  Tooltip,
} from 'components';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { MinusIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { FileUploader } from 'components/FileUploader';
import { useAdminDocumentsFormPage } from './hooks';
import { defaultTranslation } from './const';
import { DocumentStatus } from 'services/documents/const';
import { UserRole } from 'services';
import { useAuth } from 'GlobalProvider';

export const AdminDocumentFormPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    control,
    isLoading,
    onSubmit,
    isCreate,
    openConfirmationModal,
    openLanguageModal,
    closeConfirmationModal,
    closeLanguageModal,
    fields,
    onDeleteConfirm,
    handleTabClick,
    activeTab,
    languageModal,
    onLanguageAdd,
    translationToDelete,
    sections,
    document,
    onApproveSubmit,
    onDenySubmit,
    isTemplate,
    resetFile,
  } = useAdminDocumentsFormPage();

  return (
    <>
      <Header title={isCreate ? 'New document' : 'Edit document'} onGoBackPress={() => navigate(-1)}>
        <div className="flex justify-between items-center">
          <Button onClick={() => window.open('https://translate.google.com/')} className="mx-4">
            Translate
          </Button>

          {(user?.role === UserRole.CONTENTMANAGER && document?.vettingStatus === DocumentStatus.PENDING) ||
          isCreate ? (
            <Button onClick={onSubmit}>Save</Button>
          ) : null}

          {user?.role === UserRole.ADMIN ? (
            <>
              {!isCreate && document && (
                <>
                  <Button onClick={onSubmit}>Save</Button>
                  {document.vettingStatus === DocumentStatus.DENIED ? (
                    <>
                      <Button onClick={onApproveSubmit} className="ml-4" variant="success">
                        Approve
                      </Button>
                    </>
                  ) : document.vettingStatus === DocumentStatus.APPROVED ? (
                    <Button onClick={onDenySubmit} className="ml-4" variant="danger">
                      Deny
                    </Button>
                  ) : (
                    <>
                      <Button onClick={onApproveSubmit} className="ml-4" variant="success">
                        Approve
                      </Button>
                      <Button onClick={onDenySubmit} className="ml-4" variant="danger">
                        Deny
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          ) : null}
        </div>
      </Header>
      <Container>
        <form onSubmit={onSubmit}>
          <div className="flex flex-1 space-x-4 justify-start mt-4 sm:mt-0">
            <nav className="flex space-x-2 sm:ml-8" aria-label="Tabs">
              {fields.map((tab, index) => (
                <div
                  key={tab.language}
                  onClick={handleTabClick(tab.language)}
                  className={clsx(
                    tab.language === activeTab ? 'bg-secondary text-white' : 'text-gray-500 hover:text-gray-700',
                    'px-3 py-2 text-sm rounded-md uppercase font-semibold cursor-pointer group relative',
                  )}
                  aria-current={tab.language === activeTab ? 'page' : undefined}
                >
                  {tab.language}

                  {tab.language !== 'en' && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        openConfirmationModal({ language: tab.language, index });
                      }}
                      className="absolute -top-1 -right-1 hidden items-center h-4 w-4 rounded-full text-xs font-medium bg-primary text-white justify-center group-hover:inline-flex shadow-sm"
                    >
                      <MinusIcon className="h-2 w-2 text-white" aria-hidden="true" />
                    </span>
                  )}
                </div>
              ))}

              <div
                onClick={openLanguageModal}
                className="px-3 py-2 font-medium text-sm rounded-md cursor-pointer text-gray-500 hover:text-gray-700 flex items-center hover:bg-gray-200"
              >
                <PlusIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
              </div>
            </nav>
          </div>

          {isLoading && !isCreate ? (
            <div className="bg-white overflow-hidden shadow rounded-lg flex items-center justify-center h-48">
              Loading ...
            </div>
          ) : (
            <div className="bg-white shadow rounded-lg">
              <div className="space-y-6 px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-12">
                  {fields.map((field, index) => (
                    <div
                      className={clsx(field.language === activeTab ? 'block' : 'hidden', 'col-span-1 space-y-4')}
                      key={field.keyID}
                    >
                      <Input control={control} name={`translations.${index}.name`} label="Name" />
                      <Input control={control} name={`translations.${index}.description`} label="Description" />
                      <FileUploader
                        control={control}
                        name={`translations.${index}.file`}
                        label="File"
                        resetFile={() => resetFile(index)}
                      />
                    </div>
                  ))}

                  <div className="space-y-6">
                    <Input control={control} name="authorName" label="Publisher" disabled />
                    <Input control={control} name="originalAuthor" label="Author" />

                    <Select
                      control={control}
                      options={sections}
                      name="sectionId"
                      label="Section"
                      placeholder="Select section"
                    />

                    <TagPicker control={control} name="tags" label="Add tags" />

                    <div className="flex flex-col xl:flex-row gap-4 items-left">
                      <Input
                        control={control}
                        name="isTemplate"
                        label="Mark document as template"
                        type="checkbox"
                        checked={isTemplate}
                        fullWidth={false}
                        className="flex flex-row-reverse gap-3 justify-end items-center"
                        id="template"
                      />

                      <Tooltip
                        tooltipMessage="A template is a file that serves as a starting point for a new document. It can be used to create a new document with the same structure and content."
                        className="w-48"
                      >
                        <QuestionMarkCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </Container>

      <LanguageModal
        open={languageModal}
        onClose={closeLanguageModal}
        control={control}
        name="languages"
        onSubmit={onLanguageAdd}
        translations={defaultTranslation}
      />

      <ConfirmationModal
        title="Delete translation"
        body={`Are you sure you want to delete "${translationToDelete.translation}"?`}
        open={!!translationToDelete.translation}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
};
