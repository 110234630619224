import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { fetchContributor } from 'services/contributors';

export const useContributorPage = () => {
  const { id } = useParams();

  const {
    data: contributor,
    isLoading,
    status,
  } = useQuery(['author', id], () => fetchContributor(id ?? ''), {
    enabled: !!id,
    retry: 0,
  });

  return {
    contributor,
    isLoading,
    status,
  };
};
