import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MessageType, showMessage, usePagination } from 'helpers';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteTagCategory, fetchTagCategories, TagCategory } from 'services';

export function useTagCategoriesPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    tags,
    globalSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const [tagToDelete, setTagToDelete] = useState<TagCategory | null>(null);

  const { data: queryData, isLoading: categoriesLoading } = useQuery(
    ['tag-categories', currentPage, pageSize, sortBy, sortDirection, tags, globalSearch, filters],
    () => fetchTagCategories({ page: currentPage, pageSize, sortBy, sortDirection, tags, globalSearch, filters }),
  );
  const { mutateAsync: removeCategory } = useMutation(deleteTagCategory);

  const tagCategories = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = (row: TagCategory) => {
    navigate(`/admin/tags/categories/${row.id}`);
  };

  const onNewClick = () => {
    navigate('/admin/tags/categories/new');
  };

  const onDeleteConfirm = async () => {
    if (tagToDelete) {
      try {
        await removeCategory(tagToDelete.id);
        showMessage('Category successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries(['tag-categories']);
        setTagToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  const openConfirmationModal = (selectedTag: TagCategory | null) => {
    if (selectedTag) setTagToDelete(selectedTag);
  };

  const closeConfirmationModal = () => {
    setTagToDelete(null);
  };

  return {
    currentPage,
    tags,
    globalSearch,
    tagCategories,
    metaData,
    isLoading: categoriesLoading,
    onSortChange,
    onPageSizeChange,
    onPageChange,
    onSearchChange,
    onNewClick,
    onRowClick,
    openConfirmationModal,
    closeConfirmationModal,
    onDeleteConfirm,
    tagToDelete,
  };
}
