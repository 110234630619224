import React, { createContext, useContext, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Outlet } from 'react-router-dom';
import { restoreAccount, RestoreAccountForm } from 'services';
import { defaultRestoreForm, RESTORE_VALIDATION_SCHEMA } from './const';
import { MessageType, showMessage } from 'helpers';

export const RestoreAccountContext = createContext<{
  formState: FormState<RestoreAccountForm>;
  register: UseFormRegister<RestoreAccountForm>;
  control: Control<RestoreAccountForm, any>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}>({} as any);

// This will be used in case we state shared inside the module
export const RestoreAccountPageProvider = ({ children = <Outlet /> }: Props) => {
  const { register, formState, control, handleSubmit, reset } = useForm<RestoreAccountForm>({
    defaultValues: defaultRestoreForm,
    resolver: yupResolver(RESTORE_VALIDATION_SCHEMA),
  });

  const onSubmit = handleSubmit(async (values) => {
    try {
      await restoreAccount(values);
      showMessage('Account successfully restore.', MessageType.Success);
      reset(defaultRestoreForm);
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, register, control, onSubmit }),
    [formState, register, control, onSubmit],
  );

  return <RestoreAccountContext.Provider value={providerValue}>{children}</RestoreAccountContext.Provider>;
};

export const useRestoreAccountPageProvider = () => {
  return useContext(RestoreAccountContext);
};

interface Props {
  children?: React.ReactNode;
}
