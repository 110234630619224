import clsx from 'clsx';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { SelectOption } from './types';

type SelectProps<TField extends FieldValues = FieldValues> = {
  control: Control<TField>;
  name: Path<TField>;
  error?: boolean;
  label?: string;
  fullWidth?: boolean;
  options: SelectOption[];
} & JSX.IntrinsicElements['select'];

export function Select<TField extends FieldValues = FieldValues>({
  control,
  name,
  label,
  id,
  className,
  fullWidth = true,
  options,
  placeholder,
  ...selectProps
}: SelectProps<TField>) {
  const { field, fieldState } = useController({ name, control });

  return (
    <div className={clsx({ 'w-full': fullWidth })}>
      {label ? (
        <label htmlFor={id} className="block text-xs sm:text-sm font-medium text-gray-700">
          {label}
        </label>
      ) : null}
      <div className="mt-1 block">
        <select
          {...field}
          {...selectProps}
          id={id}
          name={name}
          className={clsx(
            className,
            `shadow-sm focus:ring-primary-500 focus:border-primary-500 block text-xs sm:text-sm border-gray-300 rounded-md block`,
            { 'w-full': fullWidth },
          )}
          value={field.value || 'DEFAULT'}
        >
          <option value="DEFAULT" disabled hidden>
            {placeholder}
          </option>

          {options.map((option) => (
            <option value={option.value} disabled={option.disabled} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {fieldState.error && (
        <p className="mt-1 text-sm text-red-600" id="email-error">
          {fieldState.error.message}
        </p>
      )}
    </div>
  );
}
