import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  api,
  unauthorizedHandler,
  AuthData,
  User,
  fetchAllTags,
  Tag,
  fetchProfile,
  fetchAllTagCategories,
  TagCategory,
} from 'services';
import { DataWithMeta, LocalStorage } from 'helpers';
import { useNavigate } from 'react-router-dom';

const GlobalContext = React.createContext<{
  isLoggedIn: boolean;
  user: User | null;
  adminDefaultAppState: string | null;
  allTags: DataWithMeta<Tag> | undefined;
  allTagCategorys: DataWithMeta<TagCategory> | undefined;
  loginUser: (authData: AuthData) => void;
  logoutUser: () => void;
  updateUser: (user: User) => void;
  updateAdminDefaultAppState: (e: any) => void;
  redirectAfterLogin: string | null;
  onSetRedirectAfterLogin: (path: string | null) => void;
}>({
  isLoggedIn: true,
  user: null,
  adminDefaultAppState: null,
  allTags: undefined,
  allTagCategorys: undefined,
  redirectAfterLogin: null,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  loginUser: (authData: AuthData) => {},
  logoutUser: () => {},
  updateUser: (user: User) => {},
  updateAdminDefaultAppState: (e: any) => {},
  onSetRedirectAfterLogin: (path: string | null) => {},
});

export const GlobalProvider = ({ children }: Props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!LocalStorage.getToken());
  const [user, setUser] = useState<User | null>(LocalStorage.getUser());
  const [adminDefaultAppState, setAdminDefaultAppState] = useState<string | null>(
    LocalStorage.getAdminDefaultAppState(),
  );
  const [redirectAfterLogin, setRedirectAfterLogin] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: allTags } = useQuery(['tagsAll'], () => fetchAllTags());
  const { data: allTagCategorys } = useQuery(['tagCategoryAll'], () => fetchAllTagCategories());
  const { data: profile } = useQuery(['profile'], () => fetchProfile(), { enabled: isLoggedIn });

  const loginUser = useCallback(
    (authData: AuthData) => {
      LocalStorage.setToken(authData.token);
      setUser(authData.user);
      setIsLoggedIn(true);

      if (adminDefaultAppState === 'admin') {
        navigate('/admin');
      } else {
        navigate('/documents');
      }
    },
    [adminDefaultAppState],
  );

  const logoutUser = useCallback(() => {
    LocalStorage.removeToken();
    queryClient.removeQueries();
    setUser(null);
    setIsLoggedIn(false);
  }, [queryClient]);

  const updateUser = useCallback((updatedUser: User) => {
    LocalStorage.setUser(updatedUser);
    setUser(updatedUser);
  }, []);

  // Sync user state with local storage
  useEffect(() => {
    if (user) {
      LocalStorage.setUser(user);
    } else {
      LocalStorage.removeUser();
    }
  }, [user]);

  const updateAdminDefaultAppState = useCallback((e: any) => {
    if (e.target.checked) {
      LocalStorage.setAdminDefaultAppState('user');
      setAdminDefaultAppState('user');
    } else {
      LocalStorage.setAdminDefaultAppState('admin');
      setAdminDefaultAppState('admin');
    }
  }, []);

  // Handle when server returns 401
  useEffect(() => {
    const interceptor = api.interceptors.response.use((config: any) => config, unauthorizedHandler(logoutUser));
    return () => {
      api.interceptors.request.eject(interceptor);
    };
  }, [logoutUser]);

  const onSetRedirectAfterLogin = useCallback((path: string | null) => {
    setRedirectAfterLogin(path);
  }, []);

  // Wrapped in useMemo because rerendering cycles
  const value = useMemo(
    () => ({
      isLoggedIn,
      user,
      redirectAfterLogin,
      loginUser,
      logoutUser,
      updateUser,
      updateAdminDefaultAppState,
      adminDefaultAppState,
      allTags,
      allTagCategorys,
      onSetRedirectAfterLogin,
    }),
    [
      isLoggedIn,
      user,
      redirectAfterLogin,
      loginUser,
      logoutUser,
      updateUser,
      updateAdminDefaultAppState,
      onSetRedirectAfterLogin,
      adminDefaultAppState,
      allTags,
    ],
  );

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalProvider = () => {
  return useContext(GlobalContext);
};

export const useAuth = () => {
  const { isLoggedIn, user, redirectAfterLogin, loginUser, logoutUser, updateUser, onSetRedirectAfterLogin } =
    useGlobalProvider();
  return { isLoggedIn, user, redirectAfterLogin, loginUser, logoutUser, updateUser, onSetRedirectAfterLogin };
};

export const useAdminDefaultAppState = () => {
  const { updateAdminDefaultAppState, adminDefaultAppState } = useGlobalProvider();
  return { updateAdminDefaultAppState, adminDefaultAppState };
};

export const useAllTags = () => {
  const { allTags, allTagCategorys } = useGlobalProvider();
  return { allTags, allTagCategorys };
};

interface Props {
  children: React.ReactNode;
}
