import { SelectOption } from 'components';
import * as yup from 'yup';

export const PROFILE_VALIDATION_SCHEMA = yup.object({
  email: yup.string().label('E-mail').required().email(),
  firstName: yup.string().label('First name').required(),
  lastName: yup.string().label('Last name').required(),
  alias: yup.string().label('Alias').required(),
  language: yup.string().label('Language').required(),
  password: yup.string().label('Password').notRequired(),
  confirmPassword: yup
    .string()
    .label('Confirm Password')
    .test('passwords-match', 'Passwords must match', (value, context) => {
      const { password } = context.parent;
      return !password || password === value;
    }),
  isPublic: yup.bool().label('Public').required(),
  contacts: yup
    .array(
      yup.object({
        type: yup.string().label('Type').required(),
        address: yup
          .string()
          .label('Value')
          .required()
          .test('whitespace', 'Value is a required field', (val) => val?.trim().length !== 0),
      }),
    )
    .label('Contacts')
    .required()
    .min(1, '${label} must have at least one item'),
  tags: yup
    .array(
      yup.object({
        id: yup.string().required(),
      }),
    )
    .label('Tags')
    .required(),
  country: yup.string().label('Country').notRequired(),
  state: yup.string().label('State').notRequired(),
  city: yup.string().label('City').notRequired(),
});

export const CONTACT_OPTIONS: SelectOption[] = [
  {
    value: 'phone',
    label: 'Phone',
  },
  {
    value: 'email',
    label: 'E-mail',
  },
  {
    value: 'linkedin',
    label: 'LinkedIn',
  },
  {
    value: 'twitter',
    label: 'Twitter',
  },
  {
    value: 'whatsapp',
    label: 'WhatsApp',
  },
];
