import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { MetaData, PaginationRequest, SortDirection, usePagination } from 'helpers';
import { Newsletter, fetchNewsletters } from 'services';

const NewslettersContext = createContext<{
  newsletters: Newsletter[];
  metaData?: MetaData;
  currentPage: number;
  newslettersLoading: boolean;
  pagination: {
    page: number;
    pageSize: number;
    globalSearch: string;
    sortBy: string;
    sortDirection: SortDirection;
    filters: any;
    tags: any;
  };
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  onRowClick: (row: Newsletter) => void;
  onNewslettersSearch: (values: any) => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const NewslettersPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();

  const {
    currentPage,
    globalSearch,
    pagination,
    setPagination,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onSearchChange,
  } = usePagination();

  const { data: queryData, isLoading: newslettersLoading } = useQuery(['newsletters', pagination], () =>
    fetchNewsletters({
      ...pagination,
      filters: { ...pagination.filters, status: 'published' },
    }),
  );

  const newsletters = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onNewslettersSearch = (values: any) => {
    setPagination((oldPagination: PaginationRequest) => ({
      ...oldPagination,
      globalSearch: values.globalSearch,
      tags: values.tags,
      filters: {
        ...oldPagination.filters,
        ...values.filters,
      },
      page: 1,
    }));
  };

  const onRowClick = useCallback(
    (row: Newsletter) => {
      navigate(`/newsletters/${row.id}`);
    },
    [navigate],
  );

  const providerValue = useMemo(
    () => ({
      newsletters,
      metaData,
      currentPage,
      globalSearch,
      pagination,
      newslettersLoading,
      onPageChange,
      onPageSizeChange,
      onSortChange,
      onRowClick,
      onSearchChange,
      onNewslettersSearch,
    }),
    [
      newsletters,
      metaData,
      currentPage,
      globalSearch,
      pagination,
      newslettersLoading,
      onPageChange,
      onPageSizeChange,
      onSortChange,
      onRowClick,
      onSearchChange,
      onNewslettersSearch,
    ],
  );

  return <NewslettersContext.Provider value={providerValue}>{children}</NewslettersContext.Provider>;
};

export const useNewslettersPageProvider = () => {
  return useContext(NewslettersContext);
};

interface Props {
  children?: React.ReactNode;
}
