import { Tag } from 'services/tags';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  alias: string;
  organization: string;
  isPublic: boolean;
  role: string;
  vettedAt: string;
  vettingStatus: UserStatus | null;
  password?: string;
  confirmPassword?: string;
  country: string;
  city: string;
  state: string;
  language: string;
  contacts: UserContact[];
  avatarFileId?: string;
  avatarFileUrl?: string;
  avatar?: { id: string; url: string } | null;
  tags: Tag[];
}

export interface UserContact {
  type: string;
  address: string;
}

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user',
  CONTENTMANAGER = 'contentManager',
}

export enum UserStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
}

export interface RestoreAccountForm {
  email: string;
}
