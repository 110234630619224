import { Button, HeaderSection } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { DocumentTranslation } from 'services/documents';
import { ArrowLeftIcon, DocumentAddIcon } from '@heroicons/react/outline';
import { useDocumentProvider } from '../DocumentProvider';

export const DocumentPage = () => {
  const { document, isLoading } = useDocumentProvider();
  const [activeTab, setActiveTab] = useState('');
  const [activeTranslation, setActiveTranslation] = useState<DocumentTranslation | null>(null);

  useEffect(() => {
    if (document) {
      setActiveTab(document.originalLanguage);
    }
  }, [document]);

  useEffect(() => {
    if (activeTab && document) {
      const tran = document.translations.find((t) => t.language === activeTab);
      if (tran) {
        setActiveTranslation(tran);
      }
    }
  }, [activeTab, document]);

  const handleTabClick = (tab: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  const navigate = useNavigate();

  const onUserClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    return navigate(`/authors/${id}`);
  };
  return (
    <>
      <HeaderSection
        title="Document"
        leftAction={
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="z-10 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-black bg-transparent hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-black mr-2"
          >
            <ArrowLeftIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
          </button>
        }
      >
        <div className="flex flex-1 space-x-4 justify-between mt-4 sm:mt-0">
          <nav className="flex space-x-2 sm:ml-8" aria-label="Tabs">
            {document?.translations.map((tab) => (
              <div
                key={tab.language}
                onClick={handleTabClick(tab.language)}
                className={clsx(
                  tab.language === activeTab ? 'bg-blue-200 text-blue-800' : 'text-gray-500 hover:text-gray-700',
                  'px-3 py-2 text-sm rounded-md uppercase font-semibold cursor-pointer',
                )}
                aria-current={tab.language === activeTab ? 'page' : undefined}
              >
                {tab.language}
              </div>
            ))}
          </nav>
          <div className="flex justify-between items-center">
            <Button onClick={() => window.open('https://translate.google.com/')} className="ml-2 rounded-md">
              Translate
            </Button>
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="px-4 py-2 ml-4 rounded-md border border-transparent text-sm font-medium  border-gray-500  text-black bg-transparent"
            >
              Cancel
            </button>
          </div>
        </div>
      </HeaderSection>

      {!document || isLoading ? (
        <div className="bg-white overflow-hidden shadow rounded-lg flex items-center justify-center h-48">
          Loading ...
        </div>
      ) : (
        <div className="container bg-white overflow-hidden shadow rounded-lg mb-4 mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-3 border border-gray-100 rounded-lg ">
            <div className="col-span-2 flex flex-col justify-between p-6 border border-gray-100">
              <div>
                <h1 className="font-bold text-nandor font-heading text-3xl">{activeTranslation?.name}</h1>
                <p className="mt-6 text-md text-nandor">{activeTranslation?.description}</p>
              </div>
              <a
                href={activeTranslation?.fileUrl}
                target="_blank"
                className="bg-white hover:bg-blue-200 hover:text-primary-850 text-primary-800 font-normal text-sm sm:text-base py-1 px-2 rounded-lg inline-flex mt-6 items-center border border-gray-500 hover:border-blue-800 w-fit"
                rel="noreferrer"
              >
                <DocumentAddIcon className="h-4 w-4 sm:h-5 sm:w-5 mr-2 text-gray-700" aria-hidden="true" />
                Open document
              </a>
            </div>

            <div className=" p-6 border border-gray-100 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4">
              <div className="col-span-1 lg:col-span-2">
                <label className="block text-sm font-medium leading-5 text-gray-500" htmlFor="tags">
                  PUBLISHER
                </label>
                <button
                  className="text-sm font-semibold text-indigo-600 hover:text-indigo-500 underline underline-offset-2
                  "
                  onClick={(e) => onUserClicked(e, document.authorId)}
                >
                  {document.authorName}
                </button>
              </div>
              {document.originalAuthor ? (
                <div className="col-span-1 lg:col-span-2">
                  <label className="block text-sm font-medium leading-5 text-gray-500" htmlFor="tags">
                    ORIGINAL AUTHOR
                  </label>

                  <p className="text-sm font-semibold">{document.originalAuthor}</p>
                </div>
              ) : null}

              {document.tags.length > 0 && (
                <div className="col-span-1  lg:col-span-2">
                  <label className="block text-sm font-medium leading-5 text-gray-500" htmlFor="tags">
                    TAGS
                  </label>
                  <div className="flex gap-1 flex-wrap mt-2">
                    {document.tags.map((tag, i) => (
                      <div
                        key={i}
                        className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 whitespace-nowrap"
                      >
                        {tag.name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {document.isTemplate && (
                <div className="col-span-1  lg:col-span-2">
                  <label className="block text-sm font-medium leading-5 text-gray-500" htmlFor="tags">
                    TEMPLATE
                  </label>
                  <div className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800 whitespace-nowrap mt-2">
                    This is template document.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
