import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { NavigationItem } from './types';

type MenuItemProps = {
  item: NavigationItem;
  onClickItem?: () => void;
};

export function MenuItem({ item, onClickItem }: MenuItemProps) {
  const location = useLocation();

  return (
    <>
      <NavLink
        to={item.to}
        className={({ isActive }) =>
          clsx('group flex items-center px-2 py-2 text-base font-medium rounded-md', {
            'bg-primary text-white': isActive && location.pathname === item.to,
            'text-gray-300 hover:bg-primary hover:text-white': !isActive || (isActive && location.pathname !== item.to),
          })
        }
        onClick={onClickItem ? () => (!item.subMenu || item.subMenu.length < 1 ? onClickItem() : null) : undefined}
      >
        {({ isActive }) => (
          <>
            <item.icon
              className={clsx('mr-4 flex-shrink-0 h-6 w-6', {
                'text-gray-300': isActive,
                'text-gray-400 group-hover:text-gray-300': !isActive,
              })}
              aria-hidden="true"
            />
            {item.name}
          </>
        )}
      </NavLink>

      {item.subMenu && item.subMenu.length > 0 ? (
        <Transition.Root
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={!!item.subMenu?.length && typeof item.to === 'string' && location.pathname.includes(item.to)}
          className="space-y-1"
        >
          {item.subMenu.map((subItem, index) => (
            <Transition.Child
              key={subItem.name}
              as={Fragment}
              enter={`transition-all ease-linear duration-100 delay-[${index * 50}ms]`}
              enterFrom="h-0 py-0"
              enterTo="h-9 py-2"
              leave="transition-all ease-linear duration-75"
              leaveFrom="h-9 py-2"
              leaveTo="h-0 py-0"
            >
              <NavLink
                to={subItem.to}
                key={subItem.name}
                className={({ isActive }) =>
                  clsx('group flex items-center px-2 text-sm py-2', {
                    'bg-primary rounded-md text-white': isActive,
                    'text-gray-300 hover:text-white py-2': !isActive,
                  })
                }
                onClick={onClickItem}
              >
                {({ isActive }) => (
                  <>
                    <subItem.icon
                      className={clsx('ml-3 mr-4 flex-shring-0 h-4 w-4', {
                        'text-gray-300': isActive,
                        'text-gray-400 group-hover:text-gray-300': !isActive,
                      })}
                      aria-hidden="true"
                    />
                    {subItem.name}
                  </>
                )}
              </NavLink>
            </Transition.Child>
          ))}
        </Transition.Root>
      ) : null}
    </>
  );
}
