import { CellProps } from 'react-table';

import { TableColumn } from 'components';
import { Author } from 'services/contributors';
import clsx from 'clsx';

export const columns = (): TableColumn<Author>[] => [
  {
    Header: 'Alias',
    accessor: 'alias' as any,
    isSortable: true,
  },
  {
    Header: 'Name',
    accessor: 'isPublic' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Author>) => {
      const { isPublic, firstName, lastName } = row.original;
      return isPublic ? <div>{`${firstName}  ${lastName}`}</div> : <div className="italic">Private</div>;
    },
  },
  {
    Header: 'Contacts',
    accessor: 'contacts' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Author>) => {
      const rowData = row.original;
      return (
        <div className="flex gap-1 flex-wrap">
          {rowData.contacts.map((contact, i) => (
            <span
              key={i}
              className={clsx(
                'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
                'bg-green-100 text-green-800',
              )}
            >
              {contact.address}
            </span>
          ))}
        </div>
      );
    },
  },
];
