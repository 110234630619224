import { Combobox } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { fetchAllTags, Tag as TagType } from 'services';
import { Tag } from './Tag';
import { TagWrapper } from './TagWrapper';

type TagPickerProps<TFields extends FieldValues = FieldValues> = {
  control: Control<TFields>;
  name: Path<TFields>;
  label?: string;
};

export function TagPicker<TFields extends FieldValues = FieldValues>({
  control,
  name,
  label,
}: TagPickerProps<TFields>) {
  const { field, fieldState } = useController({ name, control });
  const { value: selectedTags, onChange } = field;
  const [query, setQuery] = useState('');

  const { data: allTags } = useQuery(['tagsAll'], () => fetchAllTags());
  const filteredTags = (() => {
    if (!allTags) {
      return [];
    }
    return allTags.data.filter(
      (tag: TagType) =>
        !selectedTags.find((selectedTag: TagType) => selectedTag.id === tag.id) &&
        tag.name.toLowerCase().includes(query.toLowerCase()),
    );
  })();

  const addTag = useCallback(
    (newTag: TagType) => {
      onChange(selectedTags.concat([newTag]));
    },
    [selectedTags],
  );

  const removeTag = useCallback(
    (id: string) => {
      onChange(selectedTags.filter((tag: TagType) => tag.id !== id));
    },
    [selectedTags],
  );

  return (
    <div>
      <Combobox as="div" onChange={addTag} value={null} className="mt-4">
        {label ? <Combobox.Label className="block text-sm font-medium text-gray-700">{label}</Combobox.Label> : null}
        <div className="relative mt-1">
          <Combobox.Input
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={() => ''}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>

          {filteredTags.length > 0 ? (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredTags.map((tag) => (
                <Combobox.Option
                  key={tag.id}
                  value={tag}
                  className={({ active }) =>
                    clsx(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-primary-500 text-white' : 'text-gray-900',
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span className={clsx('block truncate', selected && 'font-semibold')}>{tag.name}</span>

                      {selected && (
                        <span
                          className={clsx(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-primary-500',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          ) : null}
        </div>
      </Combobox>

      <TagWrapper>
        {selectedTags &&
          selectedTags.map((tag: TagType) => (
            <Tag key={tag.id} color="white" bgColor="primary" onClick={() => removeTag(tag.id)}>
              {tag.name}
            </Tag>
          ))}
      </TagWrapper>

      {fieldState.error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {fieldState.error.message}
        </p>
      )}
    </div>
  );
}
