import { api } from 'services/api';
import { StorageType, UploadResponse } from './types';

export async function uploadFile(file: File, storageType: StorageType) {
  const data = new FormData();
  data.append('storageTypeId', storageType.toString());
  data.append('file', file);

  const resp = await api.post<UploadResponse>('/storage/files', data);
  return resp.data;
}

export const uploadSectionAsset = (file: File) => uploadFile(file, StorageType.SectionAssets);
export const uploadDocumentAsset = (file: File) => uploadFile(file, StorageType.DocumentAssets);
export const uploadUserAsset = (file: File) => uploadFile(file, StorageType.UserAssets);
