import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { Question } from 'services/qa/types';

export const FAQ = ({ questions, title }: Props) => {
  return (
    <div className="shadow-md">
      <div className="w-full py-4 px-4 sm:py-6 sm:px-6 lg:px-8">
        <Disclosure as="div" className="mx-auto w-full divide-y-2 divide-gray-200">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                <h2
                  className={clsx(
                    open ? 'text-red-400 pb-6' : 'text-primary-500',
                    'text-center text-xl font-bold sm:text-xl',
                  )}
                >
                  {title}
                </h2>
                <span className="ml-6 flex h-7 items-center">
                  <ChevronDownIcon
                    className={clsx(
                      open ? '-rotate-180 text-red-400' : 'rotate-0 text-primary-500',
                      'h-6 w-6 transform',
                    )}
                    aria-hidden="true"
                  />
                </span>
              </Disclosure.Button>
              <Disclosure.Panel as="dd">
                <dl className="space-y-4 divide-y divide-gray-200">
                  {questions.map((question) => (
                    <Disclosure as="div" key={question.id}>
                      {({ open }) => (
                        <>
                          <dt className={clsx(open ? 'bg-blue-100' : 'bg-white', 'text-lg py-3')}>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                              <span className="font-medium text-gray-900 ml-4">{question.text}</span>
                              <span className="ml-6 flex h-7 items-center mr-4">
                                <ChevronDownIcon
                                  className={clsx(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <div>
                              <div
                                className="text-base text-gray-500 ml-4 whitespace-pre-line"
                                dangerouslySetInnerHTML={{ __html: question.answer }}
                                id={question.id}
                              ></div>
                              <div>
                                {question.img
                                  ? question.img.map((item, i, arr) => {
                                      return (
                                        <div key={item} className="mt-6 text-base text-gray-500 ml-4" id={item}>
                                          {arr.length > 1 && <a href={`#${question.id}`}>{i + 1}</a>}
                                          <img
                                            src={require(`../../assets/faq-images/${item}.png`)}
                                            alt="explanation image"
                                            className="mt-4 ml-4"
                                            key={item}
                                          ></img>
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

interface Props {
  questions: Question[];
  title: string;
}
