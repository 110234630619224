import React, { createContext, useContext, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { usePagination, SortDirection, MetaData } from 'helpers';
import { Article, fetchArticles, fetchUsers, User, UserStatus } from 'services';
import { Document, fetchDocuments } from 'services/documents';
import { ArticleStatus } from 'services/articles/const';
import { DocumentStatus } from 'services/documents/const';
import { useAuth } from 'GlobalProvider';
import { UserRole } from 'services';

export const DashboardPageContext = createContext<{
  users: User[];
  usersMetaData?: MetaData;
  documents: Document[];
  documentsMetaData?: MetaData;
  articles: Article[];
  articlesMetaData?: MetaData;
  usersLoading: boolean;
  documentsLoading: boolean;
  articlesLoading: boolean;
  onViewClick: (route: string, id: string) => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const DashboardPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const { currentPage, pageSize, sortBy, sortDirection, globalSearch, tags, pagination } = usePagination();

  const { data: usersData, isLoading: usersLoading } = useQuery(
    ['users', pagination],
    () =>
      fetchUsers({
        page: currentPage,
        sortBy: 'id',
        tags,
        sortDirection: SortDirection.Desc,
        pageSize: 10,
        globalSearch,
        filters: { vettingStatus: UserStatus.PENDING },
      }),
    {
      enabled: user?.role === UserRole.ADMIN,
    },
  );

  const { data: documentsData, isLoading: documentsLoading } = useQuery(
    ['documents', pagination],
    () =>
      fetchDocuments({
        page: currentPage,
        pageSize,
        sortBy,
        tags,
        sortDirection,
        globalSearch,
        filters: { vettingStatus: DocumentStatus.PENDING },
      }),
    {
      enabled: user?.role === UserRole.ADMIN,
    },
  );

  const { data: articlesData, isLoading: articlesLoading } = useQuery(
    ['articles', pagination],
    () =>
      fetchArticles({
        page: currentPage,
        pageSize,
        sortBy,
        tags,
        sortDirection,
        globalSearch,
        filters: { vettingStatus: ArticleStatus.PENDING },
      }),
    {
      enabled: user?.role === UserRole.ADMIN,
    },
  );

  const users = useMemo(() => usersData?.data ?? [], [usersData?.data]);
  const usersMetaData = useMemo(() => usersData?.meta, [usersData?.meta]);
  const documents = useMemo(() => documentsData?.data ?? [], [documentsData?.data]);
  const documentsMetaData = useMemo(() => documentsData?.meta, [documentsData?.meta]);
  const articles = useMemo(() => articlesData?.data ?? [], [articlesData?.data]);
  const articlesMetaData = useMemo(() => articlesData?.meta, [articlesData?.meta]);

  const onViewClick = (route: string, id: string) => {
    navigate(`/${route}/${id}`);
  };

  const providerValue = useMemo(
    () => ({
      users,
      usersMetaData,
      usersLoading,
      documents,
      documentsMetaData,
      documentsLoading,
      articles,
      articlesMetaData,
      articlesLoading,
      onViewClick,
    }),
    [
      users,
      usersMetaData,
      usersLoading,
      documents,
      documentsMetaData,
      documentsLoading,
      articles,
      articlesMetaData,
      articlesLoading,
      onViewClick,
    ],
  );

  return <DashboardPageContext.Provider value={providerValue}>{children}</DashboardPageContext.Provider>;
};

export const useDashboardPage = () => {
  return useContext(DashboardPageContext);
};

interface Props {
  children?: React.ReactNode;
}
