import { DocumentForm, Document } from 'services/documents';

export const documentToProfileForm = (document: Document): DocumentForm => {
  return {
    ...document,
    translations: document.translations.map((translation) => ({
      ...translation,
      file: {
        id: translation.fileId,
        url: translation.fileUrl,
      },
    })),
  };
};
