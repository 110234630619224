import { Author } from './types';

export function resToContributor(res: any): Author {
  return {
    id: res.id ?? '',
    alias: res.alias ?? '',
    firstName: res.firstName ?? '',
    lastName: res.lastName ?? '',
    email: res.email ?? '',
    isPublic: res.isPublic ?? false,
    vettedAt: res.vettedAt ?? false,
    role: res.role ?? '',
    avatarFileUrl: res.avatarFileUrl ?? '',
    avatarFileId: res.avatarFileId ?? '',
    language: res.language ?? '',
    country: res.country ?? '',
    city: res.city ?? '',
    contacts: res.contacts ?? [],
    state: res.state ?? '',
    tags: res.tags ?? [],
  };
}

export const contributorToPutReq = (data: any) => {
  const mustData = {
    name: data.name,
    author: data.authorName,
    languages: data.originalLanguage,
    tags: data.tags,
  };

  return mustData;
};

export const contributorToPostReq = (data: any) => {
  return {
    name: data.name,
    author: data.authorName,
    languages: data.originalLanguage,
    tags: data.tags,
  };
};
