import { Button, ConfirmationModal, Container, HeaderSection, Search, Table } from 'components';

import { useArticlesPage } from './hooks';
import { ARTICLES_TOOLTIP_MSG, columns } from './const';
import { Article } from 'services/articles';
import { PlusIcon } from '@heroicons/react/outline';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ArticlesPage = () => {
  const {
    articles,
    metaData,
    currentPage,
    isLoading,
    articleToDelete,
    pagination,
    onRowClick,
    onNewClick,
    onDeleteConfirm,
    openConfirmationModal,
    closeConfirmationModal,
    onUserClicked,
    handleSearchChange,
    onSortChange,
    onPageChange,
    onPageSizeChange,
  } = useArticlesPage();

  return (
    <>
      <Search onChange={handleSearchChange} values={pagination} tagsPicker datePicker />

      <HeaderSection title="Community Updates" containerClassName="z-20" tooltipMessage={ARTICLES_TOOLTIP_MSG}>
        <div className="flex items-center justify-start w-full h-full">
          <Button onClick={onNewClick} Icon={PlusIcon} className="ml-auto rounded-md text-xs sm:text-sm">
            New
          </Button>
        </div>
      </HeaderSection>

      <Container>
        <Table<Article>
          data={articles}
          columns={columns(openConfirmationModal, onUserClicked)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title={`Delete ${articleToDelete?.name}?`}
        body={`Are you sure you want to delete ${articleToDelete?.name}?`}
        open={!!articleToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
};
