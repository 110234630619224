import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SelectOption } from 'components';
import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { BaseSyntheticEvent, createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { Control, FormState, useForm, UseFormRegister } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';
import { fetchTag, fetchAllTagCategories, postTag, putTag, Tag } from 'services';
import { defaultTagForm } from '../const';
import { TAG_VALIDATION_SCHEMA } from './const';
import { tagToTagForm } from './transformation';

const TagPageContext = createContext<{
  formState: FormState<Tag>;
  tag: Tag | undefined;
  categoriesOptions: SelectOption[];
  isLoading: boolean;
  isCreate: boolean;
  onSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  register: UseFormRegister<Tag>;
  control: Control<Tag, any>;
}>({} as any);

type Props = {
  children?: ReactNode;
};

export function TagPageProvider({ children = <Outlet /> }: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: tagId, isCreate } = useIdFromParams();

  const { data: tag } = useQuery(['tags', tagId], () => fetchTag(tagId), {
    enabled: !isCreate,
  });
  const { mutateAsync: updateTag } = useMutation(putTag);
  const { mutateAsync: createTag } = useMutation(postTag);

  const { data: queryCategoriesData, isLoading: categoriesLoading } = useQuery(['tag-categories', 1, 9999], () =>
    fetchAllTagCategories(),
  );
  const tagCategories = useMemo(() => queryCategoriesData?.data ?? [], [queryCategoriesData?.data]);
  const categoriesOptions = useMemo<SelectOption[]>(
    () =>
      tagCategories.map((cat) => ({
        value: cat.id,
        label: cat.name,
      })),
    [tagCategories],
  );

  const { handleSubmit, register, formState, reset, control } = useForm<Tag>({
    defaultValues: defaultTagForm,
    resolver: yupResolver(TAG_VALIDATION_SCHEMA),
  });

  const tagForm = useMemo(() => {
    if (tag) {
      return tagToTagForm(tag);
    }
    return defaultTagForm;
  }, [tag]);

  useEffect(() => {
    reset(tagForm);
  }, [tagForm]);

  const onSubmit = handleSubmit(async (values: Tag) => {
    if (isCreate) {
      try {
        await createTag(values);
        showMessage('Tag successfully created.', MessageType.Success);
        queryClient.invalidateQueries(['tags']);
        navigate('/admin/tags');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateTag(values);
      showMessage('Tag successfully updated.', MessageType.Success);
      queryClient.invalidateQueries(['tags']);
      navigate('/admin/tags');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ formState, isCreate, onSubmit, register, control, tag, categoriesOptions, isLoading: categoriesLoading }),
    [formState, isCreate, onSubmit, register, control, tag, categoriesOptions, categoriesLoading],
  );

  return <TagPageContext.Provider value={providerValue}>{children}</TagPageContext.Provider>;
}

export const useTagPage = () => {
  return useContext(TagPageContext);
};
