import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MessageType, showMessage } from 'helpers';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Control,
  FieldArrayWithId,
  FormState,
  useFieldArray,
  UseFieldArrayRemove,
  useForm,
  UseFormRegister,
} from 'react-hook-form';
import { Outlet } from 'react-router-dom';
import { fetchProfile, putProfile, User, userSelfDelete } from 'services';
import { defaultUserForm } from '../const';
import { initialConfirmationModal, PROFILE_VALIDATION_SCHEMA } from './const';
import { profileToProfileForm } from './transformation';

import { useGlobalProvider, useAuth } from 'GlobalProvider';
import { ConfirmationModal } from './types';

export const ProfilePageContext = createContext<{
  formState: FormState<User>;
  control: Control<User, any>;
  profile: User | undefined;
  register: UseFormRegister<User>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  contacts: FieldArrayWithId<User, 'contacts', 'id'>[];
  addContact: () => void;
  removeContact: UseFieldArrayRemove;
  isLoading: boolean;
  deleteProfile: () => void;
  confirmationModal: ConfirmationModal;
  closeConfirmationModal: () => void;
  onSelfDeleteClick: () => Promise<void>;
  onDeleteClick: () => void;
  profileForm: User;
}>({} as any);

type Props = {
  children?: ReactNode;
};

export function ProfilePageProvider({ children = <Outlet /> }: Props) {
  const queryClient = useQueryClient();

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>(initialConfirmationModal);
  const { data: profile, isLoading } = useQuery(['profile'], () => fetchProfile());
  const { logoutUser } = useAuth();
  const { mutateAsync: updateProfile } = useMutation(putProfile);
  const { mutateAsync: deleteProfile } = useMutation(userSelfDelete);

  const { handleSubmit, register, formState, reset, control } = useForm<User>({
    defaultValues: defaultUserForm,
    resolver: yupResolver(PROFILE_VALIDATION_SCHEMA),
  });
  const {
    fields: contacts,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray({ control, name: 'contacts' });

  const { updateUser } = useGlobalProvider();

  const addContact = () => appendContact({ address: '', type: '' });

  const profileForm = useMemo(() => {
    if (profile) {
      return profileToProfileForm(profile);
    }
    return defaultUserForm;
  }, [profile]);

  useEffect(() => {
    reset(profileForm);
  }, [profileForm]);

  const closeConfirmationModal = useCallback(() => {
    setConfirmationModal(initialConfirmationModal);
  }, []);

  const onDeleteClick = () => {
    setConfirmationModal({
      action: 'delete',
      isOpen: true,
      title: 'Alert',
      message: `Are you sure you want to delete your account ?`,
      actionButtonLabel: 'Delete',
    });
  };

  const onSelfDeleteClick = useCallback(async () => {
    try {
      await deleteProfile();
      showMessage('Profile successfully deleted.', MessageType.Success);
      queryClient.invalidateQueries(['profile']);
      closeConfirmationModal();
      logoutUser();
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  }, []);

  const onSubmit = handleSubmit(async (values) => {
    try {
      await updateProfile(values);
      updateUser(values);
      showMessage('Profile successfully updated.', MessageType.Success);
      queryClient.invalidateQueries(['profile']);
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({
      formState,
      control,
      profile,
      register,
      onSubmit,
      contacts,
      addContact,
      removeContact,
      isLoading,
      deleteProfile,
      confirmationModal,
      closeConfirmationModal,
      onSelfDeleteClick,
      onDeleteClick,
      profileForm,
    }),
    [
      formState,
      control,
      profile,
      register,
      onSubmit,
      contacts,
      addContact,
      removeContact,
      isLoading,
      deleteProfile,
      confirmationModal,
      closeConfirmationModal,
      onSelfDeleteClick,
      onDeleteClick,
      profileForm,
    ],
  );

  return <ProfilePageContext.Provider value={providerValue}>{children}</ProfilePageContext.Provider>;
}

export function useProfilePage() {
  return useContext(ProfilePageContext);
}
