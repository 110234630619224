import { Button, Modal, Select } from 'components';
import { useLanguagesOptions } from 'modules/common';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { defaultTranslation } from '../const';

export const LanguageModal = ({ open, onClose, onSubmit }: any) => {
  const languagesOptions = useLanguagesOptions();

  const { handleSubmit, control, reset } = useForm();

  const onAddPress = handleSubmit((data: any) => onSubmit({ ...defaultTranslation, language: data.language }));

  useEffect(() => {
    if (!open) {
      reset(defaultTranslation);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} title="Add language">
      <form onSubmit={onAddPress} className="flex">
        <Select control={control} name="language" id="language" options={languagesOptions} />

        <Button type="submit" className="ml-2">
          Add
        </Button>
      </form>
    </Modal>
  );
};
