import { Button, HeaderSection, Input, Header, Container } from 'components';
import { useNavigate } from 'react-router-dom';
import { useQuestionPage } from './QuestionPageProvider';

export const QuestionPage = () => {
  const navigate = useNavigate();

  const { control, onSubmit, isCreate } = useQuestionPage();

  return (
    <>
      <Header title={isCreate ? 'New question' : 'Edit question'} onGoBackPress={() => navigate(-1)}>
        <Button onClick={onSubmit} className="ml-auto">
          Save
        </Button>
      </Header>

      <Container>
        <form onSubmit={onSubmit}>
          <div>
            <HeaderSection title="Question"></HeaderSection>
          </div>
          <div className="bg-white shadow rounded-lg">
            <div className="space-y-6 px-4 py-5 sm:p-6">
              <div className="space-y-6">
                <Input control={control} name="title" label="Title" required />

                <Input control={control} name="text" label="Question" required />
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};
