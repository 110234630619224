import { api } from 'services/api';
import { Question, QuestionForm } from './types';
import { DataWithMeta, PaginationRequest, paginationRequestToUrl } from 'helpers';
import { resToQuestion, questionToPostReq, questionToPutReq } from './transformations';

export const fetchQuestions = async (pagination: PaginationRequest): Promise<DataWithMeta<Question>> => {
  const url = paginationRequestToUrl('/questions', pagination);

  const resp = await api.get(url);

  return {
    meta: resp.data.meta,
    data: resp.data.data.map(resToQuestion),
  };
};

export const fetchQuestion = async (id: string): Promise<Question> => {
  const resp = await api.get(`/questions/${id}`);
  return resToQuestion(resp.data);
};

export const postQuestion = async (data: any): Promise<Question> => {
  const resp = await api.post(`/questions`, questionToPostReq(data));
  return resToQuestion(resp.data);
};

export const putQuestion = async (data: QuestionForm): Promise<Question> => {
  const resp = await api.put(`/questions/${data.id}`, questionToPutReq(data));

  return resToQuestion(resp.data);
};

export const deleteQuestion = async (id: string): Promise<Question> => {
  const resp = await api.delete(`/questions/${id}`);
  return resToQuestion(resp.data);
};
