import React, { createContext, useContext, useMemo, useState, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MetaData, SortDirection, usePagination, MessageType, showMessage } from 'helpers';
import { Section, fetchSections, deleteSection } from 'services';

const SectionsContext = createContext<{
  sections: Section[];
  metaData?: MetaData;
  currentPage: number;
  sectionsLoading: boolean;
  sectionToDelete: Section | null;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  openConfirmationModal: (selectedSection: Section | null) => void;
  closeConfirmationModal: () => void;
  onDeleteConfirm: () => void;
  onRowClick: (row: Section) => void;
  onNewClick: () => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const SectionsPageProvider = ({ children = <Outlet /> }: Props) => {
  const { currentPage, pageSize, sortBy, sortDirection, pagination, onPageChange, onPageSizeChange, onSortChange } =
    usePagination();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [sectionToDelete, setSectionToDelete] = useState<Section | null>(null);

  const { data: queryData, isLoading: sectionsLoading } = useQuery(
    ['sections', currentPage, pageSize, sortBy, sortDirection],
    () => fetchSections(pagination),
  );

  const { mutateAsync: removeSection } = useMutation(deleteSection);

  const sections = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = useCallback(
    (row: Section) => {
      navigate(`/admin/sections/${row.id}`);
    },
    [navigate],
  );

  const onNewClick = useCallback(() => {
    navigate('/admin/sections/new');
  }, []);

  const onDeleteConfirm = useCallback(async () => {
    if (sectionToDelete) {
      try {
        await removeSection(sectionToDelete.id);
        showMessage('Section successfully deleted.', MessageType.Success);
        setSectionToDelete(null);
        queryClient.invalidateQueries(['sections']);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  }, [queryClient, removeSection, sectionToDelete]);

  const openConfirmationModal = useCallback((selectedSection: Section | null) => {
    if (selectedSection) setSectionToDelete(selectedSection);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setSectionToDelete(null);
  }, []);

  const providerValue = useMemo(
    () => ({
      sections,
      metaData,
      sectionsLoading,
      currentPage,
      sectionToDelete,
      onPageChange,
      onPageSizeChange,
      onSortChange,
      openConfirmationModal,
      closeConfirmationModal,
      onDeleteConfirm,
      onRowClick,
      onNewClick,
    }),
    [
      sections,
      metaData,
      sectionsLoading,
      currentPage,
      sectionToDelete,
      onPageChange,
      onPageSizeChange,
      onSortChange,
      openConfirmationModal,
      closeConfirmationModal,
      onDeleteConfirm,
      onRowClick,
      onNewClick,
    ],
  );

  return <SectionsContext.Provider value={providerValue}>{children}</SectionsContext.Provider>;
};

export const useSectionsProvider = () => {
  return useContext(SectionsContext);
};

interface Props {
  children?: React.ReactNode;
}
