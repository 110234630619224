import { Article, ArticleTranslation } from './types';

export function resToArticle(res: any): Article {
  return {
    id: res.id ?? '',
    authorId: res.authorId ?? '',
    authorName: res.authorName ?? '',
    name: res.name ?? '',
    originalLanguage: res.originalLanguage ?? '',
    tags: res.tags ?? [],
    translations: res.translations.map(resToArticleTranslation),
    vettedAt: res.vettedAt ?? '',
    vettingStatus: res.vettingStatus ?? '',
  };
}

export const resToArticleTranslation = (res: any): ArticleTranslation => {
  return {
    id: res.id ?? '',
    description: res.description ?? '',
    language: res.language ?? '',
    name: res.name ?? '',
    text: res.text ?? '',
    fileId: res.fileId ?? '',
    fileUrl: res.fileUrl ?? '',
  };
};

export const articleToPutReq = (data: any) => {
  // TODO: should be implemented in a better way
  const defaultLanguage = data.translations.find((translation: any) => translation.language === 'en');

  const mustData = {
    name: defaultLanguage?.name ?? '',
    authorId: data.authorId,
    originalLanguage: defaultLanguage?.language ?? '',
    tags: data.tags.map((tag: any) => tag.id),
    translations: data.translations.map(articleTranslationToPostReq),
  };

  return mustData;
};

export const articleToPostReq = (data: any) => {
  // TODO: should be implemented in a better way
  const defaultLanguage = data.translations.find((translation: any) => translation.language === 'en');

  return {
    name: defaultLanguage?.name ?? '',
    authorId: data.authorId,
    originalLanguage: defaultLanguage?.language ?? '',
    tags: data.tags.map((tag: any) => tag.id),
    translations: data.translations.map(articleTranslationToPostReq),
  };
};

export const articleTranslationToPostReq = (data: any) => {
  return {
    id: data.id,
    language: data.language,
    name: data.name,
    description: data.description,
    text: data.text,
    fileId: data.file.id || null,
    fileUrl: data.file.url,
  };
};
