import { PlusIcon } from '@heroicons/react/outline';
import { Button, ConfirmationModal, Container, Header, Table, HeaderSearch } from 'components';
import { useAdminDocumentsPage } from './AdminDocumentsPageProvider';
import { Document } from 'services/documents';
import { columns } from './const';
import { useAuth } from 'GlobalProvider';

export function AdminDocumentsPage() {
  const { user } = useAuth();
  const {
    documents,
    metaData,
    currentPage,
    globalSearch,
    isLoading,
    confirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onNewClick,
    onSortChange,
    onConfirmClick,
    onDeleteClick,
  } = useAdminDocumentsPage();
  return (
    <>
      <Header title="Documents">
        <div className="flex justify-between flex-1">
          <HeaderSearch onChange={(e) => onSearchChange(e.target.value)} value={globalSearch} />

          <Button onClick={onNewClick} Icon={PlusIcon}>
            New document
          </Button>
        </div>
      </Header>

      <Container className="overflow-hidden">
        <Table<Document>
          data={documents}
          columns={columns(onDeleteClick, user)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title={confirmationModal.title}
        body={confirmationModal.message}
        open={confirmationModal.isOpen}
        actionButtonLabel={confirmationModal.actionButtonLabel}
        onAction={onConfirmClick}
        onClose={closeConfirmationModal}
      />
    </>
  );
}
