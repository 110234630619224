import { Container, Header } from 'components';

export function WaitingForActivationPage() {
  return (
    <>
      <Header title="Waiting for activation..." />

      <Container>
        <p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-500">
          Thanks for signing in. <br />
          <br /> Please check your email after administator approves your account.
        </p>
      </Container>
    </>
  );
}
