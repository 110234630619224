import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { PaginationRequest, usePagination } from 'helpers';
import { Author, fetchContributors } from 'services/contributors';

export const useContributorsPage = () => {
  const navigate = useNavigate();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    tags,
    globalSearch,
    filters,
    pagination,
    setPagination,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const { data: queryData, isLoading: contributorsLoading } = useQuery(
    ['authors', currentPage, pageSize, sortBy, sortDirection, globalSearch, tags, filters],
    () => fetchContributors({ page: currentPage, pageSize, sortBy, sortDirection, tags, globalSearch, filters }),
  );

  const authors = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = (row: Author) => {
    navigate(`/authors/${row.id}`);
  };

  const onAuthorsSearch = (values: any) => {
    setPagination((oldPagination: PaginationRequest) => ({
      ...oldPagination,
      globalSearch: values.globalSearch ?? '',
      tags: values.tags ?? [],
      filters: {
        ...oldPagination.filters,
        ...values.filters,
      },
      page: 1,
    }));
  };

  const onNewClick = () => {
    navigate('/authors/new');
  };

  return {
    authors,
    metaData,
    currentPage,
    globalSearch,
    tags,
    isLoading: contributorsLoading,
    filters,
    pagination,
    onNewClick,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    onSortChange,
    onAuthorsSearch,
  };
};
