import clsx from 'clsx';
import { ReactNode, Fragment, useMemo, useEffect } from 'react';
import { Outlet, Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { useAuth } from 'GlobalProvider';
import { UserRole } from 'services';
const navigation = [
  { name: 'Documents', to: '/documents' },
  { name: 'Community Updates', to: '/articles' },
  { name: 'Contributors', to: '/authors' },
  { name: 'Newsletters', to: '/newsletters' },
  { name: 'FAQ', to: '/faq' },
  { name: 'Contact', to: '/contact' },
];
export const UserLayout = ({ children = <Outlet /> }: Props) => {
  const { isLoggedIn, user, logoutUser, onSetRedirectAfterLogin, redirectAfterLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const userNavigation = useMemo(
    () => [
      { name: 'Profile', href: '/profile' },
      { name: 'Sign out', onClick: logoutUser },
    ],
    [logoutUser],
  );

  useEffect(() => {
    const isLocationArticles = location.pathname.includes('articles');
    if (!isLoggedIn && isLocationArticles) {
      onSetRedirectAfterLogin(location.pathname);
      navigate(
        { pathname: '/login' },
        {
          replace: true,
        },
      );
    } else if (!isLoggedIn && !isLocationArticles) {
      navigate(
        { pathname: '/about-us' },
        {
          replace: true,
        },
      );
    } else if (isLoggedIn && redirectAfterLogin) {
      navigate(redirectAfterLogin);
      onSetRedirectAfterLogin(null);
    }
  }, [isLoggedIn]);

  return (
    <div className="min-h-screen flex flex-col h-screen background overflow-scroll ">
      <Disclosure as="nav" className="bg-primary border-b border-gray-200 z-50 sticky top-0">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <NavLink to="/documents">
                      <img className="h-8 w-auto" src="/logo.svg" alt="Workflow" />
                    </NavLink>
                  </div>
                  <div className="hidden sm:-my-px sm:ml-3 md:ml-6 sm:flex sm:space-x-4 md:space-x-8">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.to}
                        className={({ isActive }) =>
                          [
                            'border-transparent font-inter text-blue-200 hover:border-gray-300 hover:text-blue-50',
                            'inline-flex items-center px-1 pt-1 border-b-4 text-base font-semibold leading-5',
                            isActive ? 'border-secondary-500 border-b-4 !text-white justify-center' : undefined,
                          ]
                            .filter(Boolean)
                            .join(' ')
                        }
                        // aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {user?.role !== UserRole.USER && (
                    <Link to="/admin" className="flex-shrink-0 group block mr-0 md:mr-3">
                      <div className="inline-flex items-center px-1 md:px-3 py-2 border border-transparent text-xs leading-3 md:leading-4 font-medium rounded-md shadow-sm text-white bg-secondary-500 hover:bg-orange-700 ">
                        Admin Dashboard
                      </div>
                    </Link>
                  )}
                  <button
                    onClick={() => navigate('/question')}
                    type="button"
                    className="bg-primary p-1 rounded-full text-blue-200 hover:text-white focus:outline-none focus:text-white"
                  >
                    <span className="">Ask a question</span>
                  </button>
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-0 md:ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full border-2 border-white"
                          src={user?.avatar ? user?.avatar.url : user?.avatarFileUrl}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-400"
                      enterFrom="transform opacity-0 scale-0"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => {
                              const LinkComponent = item.onClick ? 'a' : NavLink;
                              return (
                                <LinkComponent
                                  href={item.onClick ? '#' : undefined}
                                  to={item.href as string}
                                  className={clsx(active && 'bg-blue-100', 'block px-4 py-2 text-sm text-gray-700')}
                                  onClick={item.onClick}
                                >
                                  {item.name}
                                </LinkComponent>
                              );
                            }}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-primary inline-flex items-center justify-center p-2 rounded-md text-blue-200 hover:text-white focus:outline-none">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden z-10">
              <div className="pt-2 pb-3 px-4 space-y-2 flex flex-col">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.to}
                    className={({ isActive }) =>
                      [
                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                        isActive
                          ? 'border-secondary-500 text-white bg-primary-700'
                          : 'border-transparent text-blue-200 hover:border-blue-50 hover:text-blue-50 hover:bg-primary-700',
                      ]
                        .filter(Boolean)
                        .join(' ')
                    }
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>

              <div className="pt-4 pb-3 border-t-4 border-blue-200 bg-primary-700">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full border-2 border-white"
                      src={user?.avatar ? user?.avatar.url : user?.avatarFileUrl}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">{user?.firstName + ' ' + user?.lastName}</div>
                    <div className="text-sm font-medium text-blue-200">{user?.email}</div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full text-blue-200 hover:text-white focus:outline-none"
                    onClick={() => navigate('/question')}
                  >
                    <span className="sr-only">View notifications</span>
                    <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 flex justify-between z-30">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={(item.onClick ? 'a' : Link) as any}
                      href={item.onClick ? '#' : undefined}
                      to={item.href}
                      className="block px-4 py-2 text-base font-medium text-secondary-500 hover:text-sun"
                      onClick={item.onClick}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="px-8 flex flex-col flex-1 z-10">
        <main className="flex-1 flex-col flex h-full w-full max-w-7xl mx-auto">
          {/* Replace with your content */}
          {children}
          {/* /End replace */}
        </main>
      </div>
      {/* Footer */}
      <div className="bg-primary z-20 mt-8 w-full">
        <div className="flex flex-col justify-between text-xs text-blue-200 max-w-7xl mx-auto p-6 sm:p-8 lg:p-10">
          <div className="relative flex lg:flex-row flex-col justify-between items-center w-full mb-8">
            <p
              className="absolute text-sm left-50 sm:left-0 font-bold cursor-pointer transition duration-200 hover:text-gray-100"
              onClick={() => navigate('/privacy-policy')}
            >
              Privacy Policy
            </p>
            <div className="flex flex-1 justify-end mt-10 lg:mt-0">
              <span className="w-6 h-6 mr-3">
                <a href="https://twitter.com/enablingngolaw" target="_blank" rel="noreferrer">
                  <img src="/img/twitter-logo.png" alt="twitter" />
                </a>
              </span>
              <span className="w-6 h-6 mx-3">
                <a
                  href="https://www.linkedin.com/company/european-center-for-not-for-profit-law/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/img/linkedin-logo.png" alt="linkedIn" />
                </a>
              </span>
              <span className="w-6 h-6 mx-3">
                <a href="https://eupolicy.social/@ecnl" target="_blank" rel="noreferrer">
                  <img src="/img/mastodon-logo.png" alt="mastodon" />
                </a>
              </span>
              <span className="w-6 h-6 ml-3">
                <a
                  className="w-6 h-6"
                  href="https://www.youtube.com/@europeancenterfornot-for-p1243"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/img/youtube-logo.png" alt="youtube" />
                </a>
              </span>
            </div>
          </div>
          <div className="flex flex-col lg:w-2/4 w-full lg:items-start lg:text-left text-center items-center">
            <p className="mb-4 w-3/4 lg:w-full">
              This platform is administrated by ECNL. ECNL is a non-governmental organisation based in the Hague,
              Netherlands working on empowering civil society by creating enabling legal and policy frameworks.
            </p>
            <p>
              Copyright &copy; {new Date().getFullYear()} ECNL Stiching – European Center for Not-for-Profit Law
              Stiching | All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
interface Props {
  children?: ReactNode;
}
