import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { Tooltip } from 'components/Tooltip';
import { ReactNode } from 'react';

type HeaderSectionProps = {
  title: string;
  children?: ReactNode;
  leftAction?: ReactNode;
  containerClassName?: string;
  tooltipMessage?: string;
};

export function HeaderSection({ children, title, leftAction, containerClassName, tooltipMessage }: HeaderSectionProps) {
  return (
    <div
      className={clsx(
        'w-full sm:container mx-auto p-4 sm:p-6 lg:p-8 mb-2 sm:mb-10 mt-10 flex items-start h-fit',
        containerClassName,
      )}
    >
      <div className="flex items-start h-fit relative w-full space-between">
        {leftAction}
        <div className="flex flex-col sm:flex-row">
          <h3 className="text-2xl sm:text-3xl heading tracking-widest text-nandor font-heading uppercase z-10 mr-4 relative">
            {title}
          </h3>
          {tooltipMessage && (
            <Tooltip className="w-96 p-2" tooltipMessage={tooltipMessage}>
              <QuestionMarkCircleIcon className="h-6 w-6 text-nandor cursor-pointer" aria-hidden="true" />
            </Tooltip>
          )}
        </div>
      </div>
      <div className="flex z-10">{children}</div>
    </div>
  );
}
