import { Container, HeaderSection } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useArticleProvider } from '../ArticleProvider';
import { useArticlesPage } from '../ArticlesPage/hooks';
import clsx from 'clsx';

import { ArrowLeftIcon, DocumentAddIcon } from '@heroicons/react/outline';
import { ArticleTranslation } from 'services';

export const ArticlePage = () => {
  const { article, isLoading } = useArticleProvider();
  const { onUserClicked } = useArticlesPage();
  const [activeTab, setActiveTab] = useState('');
  const [activeTranslation, setActiveTranslation] = useState<ArticleTranslation | null>(null);

  useEffect(() => {
    if (article) {
      setActiveTab(article.originalLanguage);
    }
  }, [article]);

  useEffect(() => {
    if (activeTab && article) {
      const tran = article.translations.find((t) => t.language === activeTab);
      if (tran) {
        setActiveTranslation(tran);
      }
    }
  }, [activeTab, document]);

  const handleTabClick = (tab: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  const navigate = useNavigate();

  return (
    <>
      <HeaderSection
        title="Article"
        leftAction={
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="z-10 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-black bg-transparent hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-black mr-2"
          >
            <ArrowLeftIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
          </button>
        }
      >
        <div className="flex flex-1 space-x-4 justify-between mt-4 sm:mt-0">
          <nav className="flex space-x-2 sm:ml-8" aria-label="Tabs">
            {article?.translations.map((tab) => (
              <div
                key={tab.language}
                onClick={handleTabClick(tab.language)}
                className={clsx(
                  tab.language === activeTab ? 'bg-blue-200 text-blue-800' : 'text-gray-500 hover:text-gray-700',
                  'px-3 py-2 text-sm rounded-md uppercase font-semibold cursor-pointer group relative',
                )}
                aria-current={tab.language === activeTab ? 'page' : undefined}
              >
                {tab.language}
              </div>
            ))}
          </nav>
          <div className="flex justify-between items-center">
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="px-4 py-2 ml-4 rounded-md border border-transparent text-sm font-medium  border-gray-500  text-black bg-transparent"
            >
              Cancel
            </button>
          </div>
        </div>
      </HeaderSection>

      {isLoading || !article ? (
        <div className="bg-white overflow-hidden shadow rounded-lg flex items-center justify-center h-48">
          Loading ...
        </div>
      ) : (
        <Container>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="space-y-6 px-4 py-5 sm:p-6">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 sm:gap-12">
                <div className="col-span-2">
                  <h1 className="font-bold text-nandor font-heading text-3xl">{activeTranslation?.name}</h1>
                  <p className="mt-4 text-md text-nandor">{activeTranslation?.description}</p>
                </div>

                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium leading-5 text-gray-700" htmlFor="tags">
                      Publisher
                    </label>
                    <button
                      className="text-sm font-semibold text-indigo-600 hover:text-indigo-500 underline underline-offset-2"
                      onClick={(e) => onUserClicked(e, article.authorId)}
                    >
                      {article.authorName}
                    </button>
                  </div>

                  <div>
                    <label className="block text-sm font-medium leading-5 text-gray-700" htmlFor="tags">
                      Tags
                    </label>
                    <div className="flex gap-1 flex-wrap mt-2">
                      {article.tags.map((tag, i) => (
                        <div
                          key={i}
                          className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 whitespace-nowrap"
                        >
                          {tag.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="shadow-sm block sm:text-sm border-gray-300 rounded-md w-full p-5 pb-2"></div>
              {activeTranslation && (
                <div dangerouslySetInnerHTML={{ __html: activeTranslation.text }} className="no-tailwindcss-base"></div>
              )}
              {activeTranslation?.fileUrl && (
                <a
                  href={activeTranslation?.fileUrl}
                  target="_blank"
                  className="bg-white hover:bg-blue-200 hover:text-primary-850 text-primary-800 font-normal text-sm sm:text-base py-1 px-2 rounded-lg inline-flex mt-6 items-center border border-gray-500 hover:border-blue-800 w-fit"
                  rel="noreferrer"
                >
                  <DocumentAddIcon className="h-4 w-4 sm:h-5 sm:w-5 mr-2 text-gray-700" aria-hidden="true" />
                  Open document
                </a>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
