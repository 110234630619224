import { DocumentForm, DocumentTranslationForm } from 'services/documents';
import { DocumentStatus } from 'services/documents/const';
import * as yup from 'yup';

export const defaultTranslation: DocumentTranslationForm = {
  id: null,
  language: 'en',
  name: '',
  description: '',
  fileId: '',
  fileUrl: '',
  file: {
    id: '',
    url: '',
  },
};

export const defaultDocumentForm: DocumentForm = {
  id: '',
  name: '',
  isTemplate: false,
  originalLanguage: '',
  sectionId: '',
  sectionName: '',
  authorId: '',
  authorName: '',
  originalAuthor: '',
  tags: [],
  translations: [defaultTranslation],
  vettedAt: '',
  vettingStatus: DocumentStatus.PENDING,
};

export const DOCUMENT_VALIDATION_SCHEMA = yup.object({
  translations: yup
    .array(
      yup.object({
        name: yup.string().label('Name').required(),
        description: yup.string().label('Description').required(),
        file: yup.object({
          url: yup.string().label('File').required(),
        }),
      }),
    )
    .label('Translations')
    .required(),
  sectionId: yup.string().label('Section').required(),
  authorName: yup.string().label('Publisher').required(),
});
