import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface DataWithMeta<T = any> {
  meta: MetaData;
  data: T[];
}

export interface MetaData {
  pageNumber: number;
  pageSize: number;
  totalRows: number;
  sortBy: string | null;
  totalPages: number;
  sortDirection: SortDirection;
}

export interface PaginationFilters {
  [key: string]: any;
}

export enum SortDirection {
  None = '',
  Asc = 'asc',
  Desc = 'desc',
}

export interface PaginationRequest {
  page: number;
  pageSize: number;
  globalSearch: string;
  sortBy: string;
  sortDirection: SortDirection;
  filters: Record<string, any>;
  tags: string[];
}

export const DefaultPaginationRequest: PaginationRequest = {
  page: 1,
  pageSize: 10,
  globalSearch: '',
  sortBy: '',
  sortDirection: SortDirection.None,
  filters: {},
  tags: [],
};

export const paginationRequestToUrlParams = (pagination: PaginationRequest) => {
  const urlParams = new URLSearchParams();

  urlParams.append('page', pagination.page.toString());
  urlParams.append('pageSize', pagination.pageSize.toString());
  urlParams.append('globalSearch', pagination.globalSearch);
  urlParams.append('sortBy', pagination.sortBy);
  urlParams.append('sortDirection', pagination.sortDirection);
  urlParams.append('filters', JSON.stringify(pagination.filters));
  urlParams.append('tags', JSON.stringify(pagination.tags));
  return urlParams;
};

export const urlParamsToPaginationRequest = (urlParams: URLSearchParams) => {
  const page = urlParams.get('page');
  const pageSize = urlParams.get('pageSize');
  const globalSearch = urlParams.get('globalSearch');
  const sortBy = urlParams.get('sortBy');
  const sortDirection = urlParams.get('sortDirection') as SortDirection;
  const filters = urlParams.get('filters');
  const tags = urlParams.get('tags');

  return {
    page: page ? parseInt(page, 10) : DefaultPaginationRequest.page,
    pageSize: pageSize ? parseInt(pageSize, 10) : DefaultPaginationRequest.pageSize,
    globalSearch: globalSearch ?? DefaultPaginationRequest.globalSearch,
    sortBy: sortBy ?? DefaultPaginationRequest.sortBy,
    sortDirection: sortDirection ?? DefaultPaginationRequest.sortDirection,
    filters: filters ? JSON.parse(filters) : DefaultPaginationRequest.filters,
    tags: tags ? JSON.parse(tags) : DefaultPaginationRequest.tags,
  };
};

export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState(urlParamsToPaginationRequest(searchParams));

  useEffect(() => {
    setSearchParams(paginationRequestToUrlParams(pagination));
  }, [pagination]);

  const onPageChange = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: newPage,
    }));
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
      pageSize: newPageSize,
    }));
  };

  const onSortChange = (accessor: string, sort: SortDirection) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      sortBy: accessor,
      sortDirection: sort,
      page: 1,
    }));
  };

  const onSearchChange = (searchValue: string) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      globalSearch: searchValue,
      page: 1,
    }));
  };

  const onSearchReset = () => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      globalSearch: '',
      page: 1,
    }));
  };

  const onFiltersChange = (newFilters: PaginationFilters) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      filters: newFilters,
      page: 1,
    }));
  };

  const onTagsChange = (newTags: string[]) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      tags: newTags,
      page: 1,
    }));
  };

  return {
    pagination,
    currentPage: pagination.page,
    pageSize: pagination.pageSize,
    sortBy: pagination.sortBy,
    sortDirection: pagination.sortDirection,
    globalSearch: pagination.globalSearch,
    filters: pagination.filters,
    tags: pagination.tags,
    setPagination,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onSearchReset,
    onFiltersChange,
    onTagsChange,
  };
};

export const paginationRequestToUrl = (url: string, pagination: PaginationRequest) => {
  let newUrl = `${url}?page=${pagination.page}&pageSize=${pagination.pageSize}`;

  if (pagination.sortBy) newUrl += `&sortBy=${pagination.sortBy}`;
  if (pagination.sortDirection) newUrl += `&sortDirection=${pagination.sortDirection}`;
  if (pagination.globalSearch) newUrl += `&globalSearch=${pagination.globalSearch}`;
  if (pagination.tags.length) newUrl += `&tags=${pagination.tags}`;
  if (pagination.filters) {
    const preparedFilters = Object.keys(pagination.filters).reduce((acc, key) => {
      if (pagination.filters[key]) {
        acc += `${key}=${pagination.filters[key]},`;
      }
      return acc;
    }, '');

    if (preparedFilters) {
      newUrl += `&filter=${encodeURI(preparedFilters.slice(0, -1))}`;
    }
  }

  return newUrl;
};
