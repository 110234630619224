import { PlusIcon, QuestionMarkCircleIcon, TrashIcon } from '@heroicons/react/outline';
import {
  AvatarUploader,
  Button,
  Card,
  Divider,
  Input,
  Select,
  Switch,
  TagPicker,
  Tooltip,
  Container,
  Header,
} from 'components';
import { useLanguagesOptions } from 'modules/common';
import { countryOptions } from 'modules/common/hooks/use-countries-options';
import { CONTACT_OPTIONS } from './const';
import { useAdminProfilePage } from './AdminProfilePageProvider';

export function AdminProfilePage() {
  const { control, contacts, addContact, removeContact, onSubmit } = useAdminProfilePage();
  const languagesOptions = useLanguagesOptions();

  return (
    <>
      <Header title="Profile">
        <Button onClick={onSubmit} className="ml-auto">
          Save
        </Button>
      </Header>
      <Container>
        <form onSubmit={onSubmit}>
          <div className="space-y-10 mb-10">
            <Card innerClassName="space-y-10">
              <Divider title="Basic info" />

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="flex flex-row">
                  <Input
                    control={control}
                    name="firstName"
                    id="firstName"
                    label="First name"
                    disabled
                    className="w-full mr-2"
                  />
                  <div className="mt-8 mr-2 w-4">
                    <Tooltip
                      tooltipMessage='To change your First name contact administrator with click on "?"'
                      className="w-60"
                    >
                      <a
                        href="mailto:admin@globalcftexperthub.org"
                        className="text-primary-700 hover:text-secondary-500"
                      >
                        <QuestionMarkCircleIcon className="h-5 w-5 text-primary-800" aria-hidden="true" />
                      </a>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex flex-row">
                  <Input
                    control={control}
                    name="lastName"
                    id="lastName"
                    label="Last name"
                    disabled
                    className="w-full mr-2"
                  />
                  <div className="mt-8 mr-2 w-4">
                    <Tooltip
                      tooltipMessage='To change your Last name contact administrator with click on "?"'
                      className="w-60"
                    >
                      <a
                        href="mailto:admin@globalcftexperthub.org"
                        className="text-primary-700 hover:text-secondary-500"
                      >
                        <QuestionMarkCircleIcon className="h-5 w-5 text-primary-800" aria-hidden="true" />
                      </a>
                    </Tooltip>
                  </div>
                </div>
                <Input control={control} name="alias" id="alias" label="Alias" />
                <Input control={control} name="email" id="email" label="Email" type="email" />
                <Input control={control} name="organization" id="organization" label="Organization" />
                <Select
                  control={control}
                  name="country"
                  id="country"
                  label="Country"
                  options={countryOptions}
                  placeholder="Select country"
                />
                <Input control={control} name="state" id="state" label="State" />
                <Input control={control} name="city" id="city" label="City" />
                <AvatarUploader control={control} name="avatar" label="Avatar" />
              </div>
            </Card>

            <Card innerClassName="space-y-10">
              <Divider title="Password" />
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <Input control={control} name="password" id="password" label="Password" type="password" />
                <Input
                  control={control}
                  name="confirmPassword"
                  id="confirmPassword"
                  label="Confirm password"
                  type="password"
                />
              </div>
            </Card>

            <Card innerClassName="space-y-10">
              <Divider title="Contacts" />
              <div className="space-y-4">
                {contacts.map((contact, index) => (
                  <div
                    className="py-4 space-y-4 lg:space-y-0 flex flex-col lg:flex-row lg:space-x-4 lg:items-start border-b"
                    key={contact.id}
                  >
                    <div className="flex-auto">
                      <Select
                        control={control}
                        name={`contacts.${index}.type`}
                        id={`contacts-${index}-type`}
                        label="Type"
                        options={CONTACT_OPTIONS}
                        placeholder="Pick type"
                      />
                    </div>
                    <div className="flex-auto">
                      <Input
                        control={control}
                        name={`contacts.${index}.address`}
                        id={`contacts-${index}-address`}
                        label="Value"
                      />
                    </div>
                    <div className="flex-grow-0">
                      <Button
                        variant="danger"
                        Icon={TrashIcon}
                        onClick={() => removeContact(index)}
                        className="mt-6 rounded-md"
                        fullWidth
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ))}
                <div className="text-center lg:text-right">
                  <Button variant="secondary" Icon={PlusIcon} type="button" className="rounded-md" onClick={addContact}>
                    Add contact
                  </Button>
                </div>
              </div>
            </Card>

            <Card className="overflow-visible" innerClassName="space-y-10">
              <Divider title="Tags" />

              <TagPicker control={control} name="tags" label="Add tags" />
            </Card>

            <Card innerClassName="space-y-10 mb-4">
              <Divider title="Settings" />
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <Switch control={control} name="isPublic" description="Make profile visible to other users">
                  Public
                </Switch>

                <Select control={control} name="language" id="language" label="Language" options={languagesOptions} />
              </div>
            </Card>
          </div>
        </form>
      </Container>
    </>
  );
}
