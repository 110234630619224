import { createContext, useContext, useMemo, useState, useCallback } from 'react';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { Outlet, useNavigate } from 'react-router-dom';

import { MessageType, showMessage, SortDirection, usePagination } from 'helpers';
import { deleteArticle, fetchArticles, Article, approveArticle } from 'services';

import { initialConfirmationModal } from './const';
import { ConfirmationModal } from './types';

export const AdminArticlesPageContext = createContext<{
  articles: Article[];
  metaData: any;
  currentPage: number;
  globalSearch: string;
  tags: any;
  isLoading: boolean;
  activeArticle: any;
  confirmationModal: ConfirmationModal;
  onSearchChange: (search: string) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onRowClick: (row: Article) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  onNewClick: () => void;
  onDeleteClick: (row: Article) => void;
  onApproveClick: (row: Article) => void;
  onConfirmClick: () => Promise<void>;
  closeConfirmationModal: () => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const AdminArticlesPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    tags,
    globalSearch,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const [activeArticle, setActiveArticle] = useState<Article | null>(null);
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>(initialConfirmationModal);

  const { data: queryData, isLoading: articlesLoading } = useQuery(
    ['articles', currentPage, pageSize, sortBy, sortDirection, tags, globalSearch, filters],
    () => fetchArticles({ page: currentPage, pageSize, sortBy, sortDirection, tags, globalSearch, filters }),
  );

  const { mutateAsync: removeArticle } = useMutation(deleteArticle);
  const { mutateAsync: approve } = useMutation(approveArticle);

  const articles = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = useCallback(
    (row: Article) => {
      navigate(`/admin/articles/${row.id}`);
    },
    [navigate],
  );

  const closeConfirmationModal = useCallback(() => {
    setActiveArticle(null);
    setConfirmationModal(initialConfirmationModal);
  }, []);

  const onNewClick = useCallback(() => {
    navigate('/admin/articles/new');
  }, [navigate]);

  const deleteConfirm = useCallback(async () => {
    if (activeArticle) {
      try {
        await removeArticle(activeArticle.id);
        showMessage('Article successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries(['articles']);
        closeConfirmationModal();
      } catch (err: any) {
        const errorMsg = err?.response?.data?.title;
        showMessage(errorMsg, MessageType.Error);
      }
    }
  }, [activeArticle, removeArticle, queryClient]);

  const approveConfirm = useCallback(async () => {
    if (activeArticle) {
      try {
        await approve(activeArticle.id);
        showMessage('Article successfully approved.', MessageType.Success);
        queryClient.invalidateQueries(['articles']);
        closeConfirmationModal();
      } catch (err: any) {
        const errorMsg = err?.response?.data?.title;
        showMessage(errorMsg, MessageType.Error);
      }
    }
  }, [activeArticle, approve, queryClient]);

  const onConfirmClick = async () => {
    if (confirmationModal.action === 'delete') {
      deleteConfirm();
    } else {
      approveConfirm();
    }
  };

  const onDeleteClick = (row: Article) => {
    setActiveArticle(row);
    setConfirmationModal({
      action: 'delete',
      isOpen: true,
      title: `Delete ${row?.name || 'Community Update'} ?`,
      message: `Are you sure you want to delete ${activeArticle?.name || 'Community Update'} ?`,
      actionButtonLabel: 'Delete',
    });
  };

  const onApproveClick = (row: Article) => {
    setActiveArticle(row);
    setConfirmationModal({
      action: 'approve',
      isOpen: true,
      title: `Approve ${row?.name || 'Community Update'} ?`,
      message: `Are you sure you want to approve ${activeArticle?.name || 'Community Update'} ?`,
      actionButtonLabel: 'Approve',
    });
  };

  const providerValue = useMemo(
    () => ({
      articles,
      metaData,
      currentPage,
      tags,
      globalSearch,
      isLoading: articlesLoading,
      activeArticle,
      confirmationModal,
      closeConfirmationModal,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onDeleteClick,
      onApproveClick,
      onConfirmClick,
      onRowClick,
      onNewClick,
      onSortChange,
    }),
    [
      articles,
      metaData,
      currentPage,
      tags,
      globalSearch,
      articlesLoading,
      activeArticle,
      confirmationModal,
      closeConfirmationModal,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onDeleteClick,
      onApproveClick,
      onConfirmClick,
      onRowClick,
      onNewClick,
      onSortChange,
    ],
  );

  return <AdminArticlesPageContext.Provider value={providerValue}>{children}</AdminArticlesPageContext.Provider>;
};

export const useAdminArticlesPage = () => {
  return useContext(AdminArticlesPageContext);
};

interface Props {
  children?: React.ReactNode;
}
