import { Button, Input, Header, Container } from 'components';
import { useNavigate } from 'react-router-dom';

import { useSectionPage } from './SectionPageProvider';

export const SectionPage = () => {
  const navigate = useNavigate();

  const { control, onSubmit, isCreate } = useSectionPage();

  return (
    <>
      <Header title={isCreate ? 'New section' : 'Edit section'} onGoBackPress={() => navigate(-1)}>
        <Button onClick={onSubmit} className="ml-auto">
          Save
        </Button>
      </Header>

      <Container>
        <form onSubmit={onSubmit}>
          <div className="bg-white shadow rounded-lg">
            <div className="space-y-6 px-4 py-5 sm:p-6">
              <div className="space-y-6">
                <Input control={control} name="name" label="Section" required />
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};
