import { Button, Container, Header, Input } from 'components';
import { useNavigate } from 'react-router-dom';
import { useRestoreAccountPageProvider } from './RestoreAccountPageProvider';
import { useAuth } from 'GlobalProvider';
import { UserRole } from 'services';

export const RestoreAccountPage = () => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const { control, onSubmit } = useRestoreAccountPageProvider();

  return (
    <>
      <Header title="Restore Account" onGoBackPress={() => navigate(-1)}>
        {user?.role === UserRole.ADMIN && (
          <Button onClick={onSubmit} className="ml-auto">
            Restore
          </Button>
        )}
      </Header>
      <Container>
        <form onSubmit={onSubmit}>
          <div className="bg-white shadow rounded-lg">
            <div className="space-y-6 px-4 py-5 sm:p-6">
              <div className="space-y-6">
                <Input control={control} name="email" label="Email" disabled={user?.role !== UserRole.ADMIN} />
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};
