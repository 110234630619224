import { TableColumn, Tooltip } from 'components';
import { shortenId } from 'helpers';
import { CellProps } from 'react-table';
import { TagCategory } from 'services';

export const columns = (deleteClicked: (rowData: TagCategory) => void): TableColumn<TagCategory>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    isSortable: true,
    Cell: ({ row }: CellProps<TagCategory>) => {
      const rowData = row.original;
      return (
        <Tooltip
          popoverMessage="Copied!"
          tooltipMessage={rowData.id}
          onClick={() => navigator.clipboard.writeText(rowData.id)}
        >
          {shortenId(rowData.id)}
        </Tooltip>
      );
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    isSortable: true,
  },
  {
    id: 'deleteButton',
    Header: '',
    Cell: ({ row }: CellProps<TagCategory>) => {
      const rowData = row.original;

      return (
        <div className="flex justify-end">
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(ev) => {
              ev.stopPropagation();
              deleteClicked(rowData);
            }}
          >
            Delete
          </button>
        </div>
      );
    },
    width: 'auto',
  },
];
