import { SelectOption } from 'components';
import { useMemo } from 'react';
import { LANGUAGES } from '../const';

export const options: SelectOption[] = LANGUAGES.map((lang) => ({
  label: lang.name,
  value: lang.code,
}));

export function useLanguagesOptions(includeEmpty = true) {
  return useMemo<SelectOption[]>(() => {
    if (includeEmpty) {
      return [{ value: '', label: 'Pick one', disabled: true }, ...options];
    }
    return options;
  }, [includeEmpty]);
}
