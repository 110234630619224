import { Container, Header, Table, ConfirmationModal, Button } from 'components';
import { PlusIcon } from '@heroicons/react/solid';

import { useQuestionsProvider } from './QuestionsPageProvider';

import { columns } from './const';
import { Question } from 'services';

export const QuestionsPage = () => {
  const {
    questions,
    metaData,
    questionsLoading,
    currentPage,
    questionToDelete,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    openConfirmationModal,
    closeConfirmationModal,
    onRowClick,
    onDeleteConfirm,
    onNewClick,
  } = useQuestionsProvider();

  return (
    <>
      <Header title="Questions">
        <div className="flex-1 flex justify-end">
          <Button onClick={onNewClick} Icon={PlusIcon}>
            New
          </Button>
        </div>
      </Header>

      <Container>
        <Table<Question>
          data={questions ?? []}
          columns={columns(openConfirmationModal)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={questionsLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title={`Delete ${questionToDelete?.title || ''} question?`}
        body="Are you sure you want to delete question?"
        open={!!questionToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
};
