import { Question, QuestionForm } from 'services';

export const questionToQuestionForm = (question: Question): QuestionForm => {
  return {
    id: question.id,
    title: question.title,
    text: question.text,
    answer: question.answer,
  };
};
