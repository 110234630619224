import { Tag } from 'services/tags';
import { User, UserRole, UserStatus } from './types';

export const resToUser = (res: any): User => {
  return {
    id: res.id ?? '',
    email: res.email ?? '',
    firstName: res.firstName ?? '',
    lastName: res.lastName ?? '',
    alias: res.alias ?? '',
    organization: res.organization ?? '',
    isPublic: res.isPublic ?? false,
    role: res.role ?? UserRole.USER,
    vettedAt: res.vettedAt ?? null,
    vettingStatus: res.vettingStatus ?? UserStatus.PENDING,
    city: res.city ?? '',
    country: res.country ?? '',
    language: res.language ?? '',
    state: res.state ?? '',
    contacts: res.contacts ?? [],
    tags: res.tags ?? [],
    avatar: res.avatarFileId && res.avatarFileUrl ? { id: res.avatarFileId, url: res.avatarFileUrl } : null,
  };
};

export const userToPutReq = (data: any) => {
  const mustData = {
    alias: data.alias,
    avatarFileId: data.avatar ? data.avatar.id : null,
    avatarFileUrl: data.avatar ? data.avatar.url : null,
    city: data.city,
    organization: data.organization,
    contacts: data.contacts,
    country: data.country,
    email: data.email,
    firstName: data.firstName,
    isPublic: data.isPublic,
    language: data.language,
    lastName: data.lastName,
    role: data.role,
    state: data.state,
    tags: (data.tags || []).map((tag: Tag) => tag.id),
  };

  if (data.password) {
    return {
      ...mustData,
      password: data.password,
      confirmPassword: data.confirmPassword,
    };
  }

  return mustData;
};

export const userToRestoreReq = (data: any) => {
  const userEmail = {
    email: data.email,
  };

  return userEmail;
};
