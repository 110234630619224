import { useQuery } from '@tanstack/react-query';
import React, { createContext, useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Document, fetchDocument } from 'services/documents';

export const AdminDocumentsContext = createContext<{
  document: Document | undefined;
  isCreate: boolean;
  isLoading: boolean;
}>({} as any);

// This will be used in case we state shared inside the module
export const AdminDocumentsProvider = ({ children = <Outlet /> }: Props) => {
  const { id: documentId } = useParams();
  const isCreate = !documentId;

  const { data: document, isLoading } = useQuery(['documents', documentId], () => fetchDocument(documentId ?? ''), {
    enabled: !isCreate,
  });

  const providerValue = useMemo(() => ({ document, isCreate, isLoading }), [document, isCreate, isLoading]);

  return <AdminDocumentsContext.Provider value={providerValue}>{children}</AdminDocumentsContext.Provider>;
};

export const useAdminDocuments = () => {
  return useContext(AdminDocumentsContext);
};

interface Props {
  children?: React.ReactNode;
}
