import { Container, Header, Table, ConfirmationModal, Button } from 'components';
import { PlusIcon } from '@heroicons/react/solid';

import { useSectionsProvider } from './SectionsPageProvider';

import { columns } from './const';
import { Section } from 'services';

export const SectionsPage = () => {
  const {
    sections,
    metaData,
    sectionsLoading,
    currentPage,
    sectionToDelete,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    openConfirmationModal,
    closeConfirmationModal,
    onRowClick,
    onDeleteConfirm,
    onNewClick,
  } = useSectionsProvider();

  return (
    <>
      <Header title="Sections">
        <div className="flex-1 flex justify-end">
          <Button onClick={onNewClick} Icon={PlusIcon}>
            New section
          </Button>
        </div>
      </Header>

      <Container>
        <Table<Section>
          data={sections ?? []}
          columns={columns(openConfirmationModal)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={sectionsLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title={`Delete ${sectionToDelete?.name || ''} section?`}
        body="Are you sure you want to delete section?"
        open={!!sectionToDelete}
        onAction={onDeleteConfirm}
        onClose={closeConfirmationModal}
      />
    </>
  );
};
