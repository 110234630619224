import { Button, Container, HeaderSection, Input, ConfirmationModal } from 'components';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useQuestionProvider } from './QAPageProvider';

export const QAPage = () => {
  const navigate = useNavigate();

  const { control, confirmationModal, onConfirm, openConfirmationModal, closeConfirmationModal } =
    useQuestionProvider();

  return (
    <>
      <div>
        <Container>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              openConfirmationModal();
            }}
          >
            <div>
              <HeaderSection
                title="Question"
                leftAction={
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="z-10 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-black bg-transparent hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-black mr-2"
                  >
                    <ArrowLeftIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
                  </button>
                }
              >
                <div className="flex flex-1 space-x-4 justify-end mt-4 sm:mt-0">
                  <Button className="ml-auto" type="submit">
                    Submit
                  </Button>
                </div>
              </HeaderSection>
            </div>
            <Container className="text-sm font-semibold text-gray-500">
              <p>
                If you need to ask a general or topic-related question to the entire community, you can submit a
                question. Add the title and the actual question you would like to share with the community and press the
                “Submit” button. Questions will be shared with the community via a mailing list and communication on the
                topic you asked about can continue via direct email messaging or other communication channels members
                have shared.
              </p>
            </Container>
            <div className="bg-white shadow rounded-lg">
              <div className="space-y-6 px-4 py-5 sm:p-6">
                <div className="space-y-6">
                  <Input control={control} name="title" label="Title" />

                  <Input control={control} name="text" label="Question" />
                </div>
              </div>
            </div>
          </form>
        </Container>
      </div>
      <ConfirmationModal
        title="Send question"
        body={'Are you sure you want to send this question to all users?'}
        open={confirmationModal}
        onAction={onConfirm}
        onClose={closeConfirmationModal}
        actionButtonLabel="Send"
      />
    </>
  );
};
