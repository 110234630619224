import * as yup from 'yup';

import { PageType } from 'helpers';
import { SelectOption } from 'components';

const isCreate = (id: string) => {
  return id === PageType.Create;
};

export const USER_VALIDATION_SCHEMA = yup.object({
  email: yup.string().when('id', {
    is: isCreate,
    then: yup.string().required('E-mail is required'),
  }),
  firstName: yup.string().label('First name').required(),
  lastName: yup.string().label('Last name').required(),
  alias: yup.string().label('Alias').required(),
  language: yup.string().label('Language').required(),
  password: yup.string().when('id', {
    is: isCreate,
    then: yup.string().required('Password is required'),
  }),
  confirmPassword: yup
    .string()
    .label('Confirm Password')
    .test('passwords-match', 'Passwords must match', (value, context) => {
      const { password } = context.parent;
      return !password || password === value;
    }),
  isPublic: yup.bool().label('Public').required(),
  tags: yup
    .array(
      yup.object({
        id: yup.string().required(),
      }),
    )
    .label('Tags')
    .required(),
  country: yup.string().label('Country').notRequired(),
  state: yup.string().label('State').notRequired(),
  city: yup.string().label('City').notRequired(),
});

export const CONTACT_OPTIONS: SelectOption[] = [
  { value: '', label: 'Pick one', disabled: true },
  {
    value: 'phone',
    label: 'Phone',
  },
  {
    value: 'email',
    label: 'E-mail',
  },
  {
    value: 'linkedin',
    label: 'LinkedIn',
  },
  {
    value: 'twitter',
    label: 'Twitter',
  },
  {
    value: 'whatsapp',
    label: 'WhatsApp',
  },
];

export const roleOptions: SelectOption[] = [
  { value: 'admin', label: 'Admin' },
  { value: 'user', label: 'User' },
  { value: 'contentManager', label: 'Content Manager' },
];
