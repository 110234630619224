import { ArrowLeftIcon } from '@heroicons/react/outline';
import { Container, FAQ, HeaderSection } from 'components';
import { useNavigate } from 'react-router-dom';
import { contentQuestions, techQuestions } from './const';

export const FAQPage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <HeaderSection
        title="Frequently Asked Questions"
        leftAction={
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="z-10 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-black bg-transparent hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 hover:text-black mr-2"
          >
            <ArrowLeftIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
          </button>
        }
      ></HeaderSection>
      <Container>
        <FAQ questions={contentQuestions} title="Content" />
        <FAQ questions={techQuestions} title="Tech" />
      </Container>
    </Container>
  );
};
