import { PlusIcon } from '@heroicons/react/outline';
import { Button, ConfirmationModal, Container, Header, Table, HeaderSearch } from 'components';
import { useAdminArticlesPage } from './AdminArticlesPageProvider';
import { Article } from 'services';
import { columns } from './const';
import { useAuth } from 'GlobalProvider';

export function AdminArticlesPage() {
  const { user } = useAuth();
  const {
    articles,
    metaData,
    currentPage,
    globalSearch,
    isLoading,
    confirmationModal,
    closeConfirmationModal,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onConfirmClick,
    onDeleteClick,
    onRowClick,
    onNewClick,
    onSortChange,
  } = useAdminArticlesPage();
  return (
    <>
      <Header title="Community Updates">
        <div className="flex justify-between flex-1">
          <HeaderSearch onChange={(e) => onSearchChange(e.target.value)} value={globalSearch} />

          <Button onClick={onNewClick} Icon={PlusIcon}>
            New Community Update
          </Button>
        </div>
      </Header>

      <Container className="overflow-hidden">
        <Table<Article>
          data={articles}
          columns={columns(onDeleteClick, user)}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={isLoading}
          onRowClick={onRowClick}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
        />
      </Container>

      <ConfirmationModal
        title={confirmationModal.title}
        body={confirmationModal.message}
        open={confirmationModal.isOpen}
        actionButtonLabel={confirmationModal.actionButtonLabel}
        onAction={onConfirmClick}
        onClose={closeConfirmationModal}
      />
    </>
  );
}
