import { SectionForm } from 'services';
import * as yup from 'yup';

export const defaultSectionForm: SectionForm = {
  id: '',
  name: '',
};

export const SECTION_VALIDATION_SCHEMA = yup.object({
  name: yup.string().label('Section').required(),
});
