import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { defaultArticleForm, ARTICLE_VALIDATION_SCHEMA } from './const';
import { MessageType, showMessage } from 'helpers';
import { useEffect, useState } from 'react';
import { useArticleProvider } from '../ArticleProvider';
import { ArticleForm, postArticle, putArticle, User } from 'services';

export const useArticlesFormPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { article, isLoading, isCreate } = useArticleProvider();
  const location: any = useLocation();

  const [activeTab, setActiveTab] = useState(location.state?.editTranslation ?? 'en');
  const [disclaimer, setDisclaimer] = useState(false);
  const [languageModal, setLanguageModal] = useState(false);
  const [translationToDelete, setTranslationToDelete] = useState<any | null>({
    translation: null,
    index: null,
  });

  const { mutateAsync: updateArticle } = useMutation(putArticle);
  const { mutateAsync: createArticle } = useMutation(postArticle);

  const profile: User | undefined = queryClient.getQueryData(['profile']);

  const { register, formState, control, reset, handleSubmit, setValue, resetField } = useForm<ArticleForm>({
    defaultValues: defaultArticleForm,
    resolver: yupResolver(ARTICLE_VALIDATION_SCHEMA),
  });

  const formTranslations = useFieldArray({ control, name: 'translations', keyName: 'keyID' });

  const onSubmit = handleSubmit(
    async (values) => {
      if (isCreate) {
        try {
          await createArticle(values);
          showMessage('Article successfully created.', MessageType.Success);
          queryClient.invalidateQueries(['articles']);
          navigate('/articles');
        } catch {
          showMessage('Theres been an error!', MessageType.Error);
        }
        return;
      }

      try {
        await updateArticle(values);
        showMessage('Article successfully updated.', MessageType.Success);
        queryClient.invalidateQueries(['articles']);
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
    },
    () => showMessage('Please fill all the fields.', MessageType.Error),
  );

  useEffect(() => {
    if (isCreate && profile) {
      setValue('authorId', profile ? profile.id : '');
      setValue('authorName', profile?.firstName + ' ' + profile?.lastName);
    } else {
      reset(article);
    }
  }, [article, isCreate, profile]);

  const { fields, append, remove } = formTranslations;

  const handleTabClick = (tab: string) => () => {
    setActiveTab(tab);
  };

  const openDisclaimer = () => {
    const moreThenOneLanguage = fields.length === 1;
    if (moreThenOneLanguage) {
      setDisclaimer(true);
      return;
    }
    openLanguageModal();
  };

  const closeDisclaimer = () => {
    setDisclaimer(false);
  };

  const openLanguageModal = () => {
    setDisclaimer(false);
    setLanguageModal(true);
  };

  const closeLanguageModal = () => {
    setLanguageModal(false);
  };

  const onLanguageAdd = (data: any) => {
    const isLanguageAdded = fields.find((f) => f.language === data.language);

    if (isLanguageAdded) {
      showMessage('Language already added', MessageType.Error);
      return;
    }

    append(data);
    setActiveTab(data.language);
    closeLanguageModal();
  };

  const openConfirmationModal = (translation: any | null) => {
    if (translation) setTranslationToDelete({ translation: translation.language, index: translation.index });
  };

  const closeConfirmationModal = () => {
    setTranslationToDelete({
      translation: null,
      index: null,
    });
  };

  const onDeleteConfirm = () => {
    if (translationToDelete) {
      setActiveTab('en');
      remove(translationToDelete.index);
      setTranslationToDelete({
        translation: null,
        index: null,
      });
    }
  };

  const resetFile = (index: number) => {
    resetField(`translations.${index}.file`);
  };

  return {
    register,
    formState,
    fields,
    onSubmit,
    isLoading,
    control,
    isCreate,
    activeTab,
    languageModal,
    openLanguageModal,
    openDisclaimer,
    closeLanguageModal,
    onLanguageAdd,
    openConfirmationModal,
    closeConfirmationModal,
    onDeleteConfirm,
    handleTabClick,
    translationToDelete,
    disclaimer,
    closeDisclaimer,
    resetFile,
  };
};
