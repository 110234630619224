import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { MessageType, showMessage } from 'helpers';
import { PaginationRequest, SortDirection, usePagination } from 'helpers/pagination';
import { Article, deleteArticle, fetchArticles } from 'services';
import { ArticleStatus } from 'services/articles/const';

export const useArticlesPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { pagination, currentPage, globalSearch, onSortChange, onPageChange, onPageSizeChange, setPagination } =
    usePagination();

  const [articleToDelete, setArticleToDelete] = useState<Article | null>(null);

  const { data: newQueryData, isLoading: articlesLoading } = useQuery(['newArticles', pagination], () =>
    fetchArticles({
      ...pagination,
      filters: { ...pagination.filters, vettingStatus: ArticleStatus.APPROVED },
      sortBy: 'vettedAt',
      sortDirection: SortDirection.Desc,
    }),
  );

  const articles = useMemo(() => newQueryData?.data ?? [], [newQueryData?.data]);
  const metaData = useMemo(() => newQueryData?.meta, [newQueryData?.meta]);

  const onUserClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    return navigate(`/authors/${id}`);
  };

  const handleSearchChange = (values: any) => {
    setPagination((oldPagination: PaginationRequest) => ({
      ...oldPagination,
      globalSearch: values.globalSearch,
      tags: values.tags,
      filters: {
        ...oldPagination.filters,
        ...values.filters,
      },
      page: 1,
    }));
  };

  const onRowClick = (row: Article) => {
    navigate(`/articles/${row.id}`);
  };

  const onNewClick = () => {
    navigate('/articles/new');
  };

  const openConfirmationModal = (selectedArticle: Article | null) => {
    if (selectedArticle) setArticleToDelete(selectedArticle);
  };

  const closeConfirmationModal = () => {
    setArticleToDelete(null);
  };

  const onDeleteConfirm = async () => {
    if (articleToDelete) {
      try {
        await deleteArticle(articleToDelete.id);
        showMessage('Article successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries(['articles']);
        setArticleToDelete(null);
      } catch {
        showMessage('Something went wrong.', MessageType.Error);
      }
    }
  };

  return {
    articles,
    metaData,
    currentPage,
    globalSearch,
    isLoading: articlesLoading,
    articleToDelete,
    pagination,
    setPagination,
    onNewClick,
    openConfirmationModal,
    closeConfirmationModal,
    onRowClick,
    onDeleteConfirm,
    onUserClicked,
    onSortChange,
    onPageChange,
    onPageSizeChange,
    handleSearchChange,
  };
};
