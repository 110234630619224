import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AuthCardLayout, Button, Container, Input } from 'components';
import { MessageType, showMessage } from 'helpers';
import { forgot, RegisterForm } from 'services';
import { EMAIL_VALIDATION_SCHEMA } from './const';
import { Link } from 'react-router-dom';

export const ForgotPasswordPage = () => {
  const [message, setMessage] = useState('');

  const { handleSubmit, control } = useForm<RegisterForm>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(EMAIL_VALIDATION_SCHEMA),
  });

  const onSubmit = handleSubmit(async (values) => {
    try {
      await forgot(values);
      setMessage(
        'A password reset message was sent to your email adress. Please click link in that message to reset your password.',
      );
    } catch (error: any) {
      showMessage(error.response?.data?.title, MessageType.Error);
    }
  });

  return (
    <>
      {!message ? (
        <AuthCardLayout
          title="Recover your password"
          subTitle={
            <>
              Or{' '}
              <Link to="/register" className="font-medium text-primary-600 hover:text-primary-500">
                register for a new account
              </Link>
            </>
          }
        >
          <form className="space-y-6" onSubmit={onSubmit}>
            <Input control={control} name="email" label="Email" placeholder="Email" type="email" />

            <Button fullWidth type="submit">
              Send
            </Button>
          </form>
        </AuthCardLayout>
      ) : (
        <Container>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">{message}</p>
        </Container>
      )}
    </>
  );
};
