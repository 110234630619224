import { RestoreAccountForm } from 'services';
import * as yup from 'yup';

export const defaultRestoreForm: RestoreAccountForm = {
  email: '',
};

export const RESTORE_VALIDATION_SCHEMA = yup.object({
  email: yup.string().email().label('Email').required(),
});
