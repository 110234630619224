import { Section } from './types';

export function resToSection(res: any): Section {
  return {
    id: res.id ?? '',
    name: res.name ?? '',
    imageFileUrl: res.imageFileUrl ?? '',
    imageFileId: res.imageFileId ?? '',
    documentCount: res.documentCount ?? '',
    approvedDocumentCount: res.approvedDocumentCount ?? '',
    contributorCount: res.contributorCount ?? '',
  };
}

export const sectionToPostReq = (data: any) => {
  return {
    name: data.name ?? '',
  };
};

export const sectionToPutReq = (data: any) => {
  return {
    name: data.name ?? '',
  };
};
