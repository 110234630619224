import { Search, HeaderSection, Table, Container } from 'components';

import { useNewslettersPageProvider } from './NewslettersPageProvider';

import { columns } from './const';
import { Newsletter } from 'services';

export const NewslettersPage = () => {
  const {
    newsletters,
    metaData,
    currentPage,
    newslettersLoading,
    pagination,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onNewslettersSearch,
  } = useNewslettersPageProvider();

  return (
    <>
      <Search onSearch={onNewslettersSearch} values={pagination} datePicker />

      <HeaderSection title="Newsletters"></HeaderSection>

      <Container>
        <Table<Newsletter>
          data={newsletters}
          columns={columns()}
          pagination={metaData}
          currentPage={currentPage}
          isLoading={newslettersLoading}
          onRowClick={() => {}}
          pageClicked={onPageChange}
          pageSizeClicked={onPageSizeChange}
          headerClicked={onSortChange}
          emptyTableMessage="No published newsletters found"
        />
      </Container>
    </>
  );
};
