/* eslint-disable react/no-danger */
import { ReactNode, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

export function Tooltip({ children, tooltipMessage, popoverMessage, onClick, className }: Props) {
  const [textMessage, setTextMessage] = useState<string | undefined>(tooltipMessage);

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  const handleClick = (e: any) => {
    if (!onClick) {
      return;
    }
    e.stopPropagation();
    onClick();
    setTextMessage(popoverMessage);
  };

  const handleMouseLeave = () => {
    setTextMessage(tooltipMessage);
  };
  return (
    <div className="w-fit relative" onClick={handleClick} onMouseLeave={handleMouseLeave} ref={setTriggerRef}>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-70 transition-opacity duration-300 tooltip dark:bg-gray-00 ${className} `,
          })}
        >
          {textMessage}
        </div>
      )}
      {children}
    </div>
  );
}

interface Props {
  onClick?: () => void;
  tooltipMessage?: string;
  popoverMessage?: string;
  children: ReactNode;
  className?: string;
}
