import { CellProps } from 'react-table';
import { TableColumn } from 'components';
import { Document } from 'services/documents';
import { formatDate } from 'helpers';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { Section } from 'services';

export const columns = (
  deleteClicked: (rowData: Document) => void,
  userClicked: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void,
): TableColumn<Document>[] => [
  {
    Header: 'Name',
    accessor: 'name' as any,
    isSortable: true,
  },
  {
    Header: 'Section',
    accessor: 'sectionName' as any,
    isSortable: true,
  },
  {
    Header: 'Publisher',
    accessor: 'authorName' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Document>) => {
      const rowData = row.original;
      return (
        <button
          className="text-sm font-semibold text-indigo-600 hover:text-indigo-500"
          onClick={(e) => userClicked(e, rowData.authorId)}
        >
          {rowData.authorName}
        </button>
      );
    },
  },
  {
    Header: 'Languages',
    accessor: 'languages' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Document>) => {
      const rowData = row.original;
      return (
        <div className="flex gap-1 flex-wrap">
          {rowData.translations.map((trans, i) => (
            <div
              key={i}
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 whitespace-nowrap"
            >
              {trans.language}
            </div>
          ))}
        </div>
      );
    },
  },
  {
    Header: 'Associated tags',
    accessor: 'tags' as any,
    isSortable: false,
    Cell: ({ row }: CellProps<Document>) => {
      const rowData = row.original;
      return (
        <div className="flex gap-1 flex-wrap">
          {rowData.tags.map((tag, i) => (
            <div
              key={i}
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 whitespace-nowrap"
            >
              {tag.name}
            </div>
          ))}
        </div>
      );
    },
  },
  {
    Header: 'Date',
    accessor: 'vettedAt' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Document>) => {
      const rowData = row.original;
      if (rowData.vettedAt) {
        return <div>{formatDate(new Date(rowData.vettedAt))}</div>;
      }
      return <></>;
    },
  },
  {
    Header: () => <div className="text-center">Template</div>,
    accessor: 'isTemplate' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Document>) => {
      const rowData = row.original;
      return (
        <>
          {rowData.isTemplate ? (
            <CheckIcon className="w-5 h-5 text-green-700 mx-auto" />
          ) : (
            <XIcon className="w-5 h-5 text-red-700 mx-auto" />
          )}
        </>
      );
    },
  },
  /*
  {
    id: 'deleteButton',
    Header: '',
    Cell: ({ row }: CellProps<Document>) => {
      const rowData = row.original;

      return (
        <div className="flex justify-end">
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(ev) => {
              ev.stopPropagation();
              deleteClicked(rowData);
            }}
          >
            Delete
          </button>
        </div>
      );
    },
    width: 'auto',
  },
  */
];

export const sectionColumns: TableColumn<Section>[] = [
  {
    Header: 'Section',
    accessor: 'name' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Section>) => {
      const rowData = row.original;
      return <div>{rowData.name}</div>;
    },
  },
  {
    Header: 'Documents',
    accessor: 'documentCount' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Section>) => {
      const rowData = row.original;
      return <div>{rowData.approvedDocumentCount}</div>;
    },
  },
  {
    Header: 'Contributors',
    accessor: 'contributorCount' as any,
    isSortable: true,
    Cell: ({ row }: CellProps<Section>) => {
      const rowData = row.original;
      return <div>{rowData.contributorCount}</div>;
    },
  },
];
