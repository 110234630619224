import { Button } from 'components/Button';
import { MessageType, showMessage } from 'helpers';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { uploadUserAsset } from 'services';

type AvatarUploaderProps<TFields extends FieldValues = FieldValues> = {
  control: Control<TFields>;
  name: Path<TFields>;
  label?: string;
  enableRemove?: boolean;
} & JSX.IntrinsicElements['input'];

export function AvatarUploader<TFields extends FieldValues = FieldValues>({
  control,
  name,
  label,
  id,
  enableRemove,
  ...inputProps
}: AvatarUploaderProps<TFields>) {
  const { field, fieldState } = useController({ control, name });
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(async (files: File[]) => {
    setUploading(true);
    try {
      const data = await uploadUserAsset(files[0]);
      field.onChange({ id: data.id, url: data.url });
    } catch {
      showMessage('Something went wrong.', MessageType.Error);
    } finally {
      setUploading(false);
    }
  }, []);

  const onRemove = useCallback(async () => {
    field.onChange({ id: null, url: null });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
    maxFiles: 1,
    multiple: false,
    disabled: uploading,
    useFsAccessApi: false,
  });

  return (
    <div className="flex gap-4">
      <div {...getRootProps()}>
        {label && (
          <label className="block text-sm font-medium leading-5 text-gray-700" htmlFor={id}>
            {label}
          </label>
        )}
        <div className="mt-1">
          <input {...inputProps} {...getInputProps().onClick} {...getInputProps()} id={id} />
          <div className="flex-shrink-0 block group">
            <div className="flex items-center mt-1 cursor-pointer h-9">
              <div className="relative">
                <img
                  src={field.value ? field.value.url : undefined}
                  alt=""
                  className="inline-block object-cover rounded-full h-9 w-9"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">Profile picture</p>
                <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                  {uploading ? 'Uploading...' : 'Click or drop file to update'}
                </p>
              </div>
            </div>
          </div>
        </div>
        {fieldState.error && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {fieldState.error.message}
          </p>
        )}
      </div>
      <div>
        {' '}
        {enableRemove && (
          <Button type="button" onClick={onRemove}>
            Remove Image
          </Button>
        )}
      </div>
    </div>
  );
}
