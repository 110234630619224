import { createContext, useContext, useMemo, useState, useCallback } from 'react';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { Outlet, useNavigate } from 'react-router-dom';

import { MessageType, showMessage, SortDirection, usePagination } from 'helpers';
import { Document, deleteDocument, fetchDocuments, approveDocument } from 'services/documents';

import { initialConfirmationModal } from './const';
import { ConfirmationModal } from './types';

export const AdminDocumentsPageContext = createContext<{
  documents: Document[];
  metaData: any;
  currentPage: number;
  globalSearch: string;
  isLoading: boolean;
  activeDocument: any;
  confirmationModal: ConfirmationModal;
  onSearchChange: (search: string) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onRowClick: (row: Document) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  onNewClick: () => void;
  onDeleteClick: (row: Document) => void;
  onApproveClick: (row: Document) => void;
  onConfirmClick: () => Promise<void>;

  closeConfirmationModal: () => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const AdminDocumentsPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    globalSearch,
    tags,
    filters,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    onSortChange,
  } = usePagination();

  const [activeDocument, setActiveDocument] = useState<Document | null>(null);
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>(initialConfirmationModal);

  const { data: queryData, isLoading: documentsLoading } = useQuery(
    ['documents', currentPage, pageSize, sortBy, sortDirection, globalSearch, tags, filters],
    () => fetchDocuments({ page: currentPage, pageSize, sortBy, sortDirection, globalSearch, tags, filters }),
  );

  const { mutateAsync: removeDocument } = useMutation(deleteDocument);
  const { mutateAsync: approve } = useMutation(approveDocument);

  const documents = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onRowClick = useCallback(
    (row: Document) => {
      navigate(`/admin/documents/${row.id}`);
    },
    [navigate],
  );

  const closeConfirmationModal = useCallback(() => {
    setActiveDocument(null);
    setConfirmationModal(initialConfirmationModal);
  }, []);

  const onNewClick = useCallback(() => {
    navigate('/admin/documents/new');
  }, [navigate]);

  const deleteConfirm = useCallback(async () => {
    if (activeDocument) {
      try {
        await removeDocument(activeDocument.id);
        showMessage('Document successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries(['documents']);
        closeConfirmationModal();
      } catch (err: any) {
        const errorMsg = err?.response?.data?.title;
        showMessage(errorMsg, MessageType.Error);
      }
    }
  }, [activeDocument, removeDocument, queryClient]);

  const approveConfirm = useCallback(async () => {
    if (activeDocument) {
      try {
        await approve(activeDocument.id);
        showMessage('Document successfully approved.', MessageType.Success);
        queryClient.invalidateQueries(['documents']);
        closeConfirmationModal();
      } catch (err: any) {
        const errorMsg = err?.response?.data?.title;
        showMessage(errorMsg, MessageType.Error);
      }
    }
  }, [activeDocument, approve, queryClient]);

  const onConfirmClick = async () => {
    if (confirmationModal.action === 'delete') {
      deleteConfirm();
    } else {
      approveConfirm();
    }
  };

  const onDeleteClick = (row: Document) => {
    setActiveDocument(row);
    setConfirmationModal({
      action: 'delete',
      isOpen: true,
      title: `Delete ${row?.name || 'document'} ?`,
      message: `Are you sure you want to delete ${activeDocument?.name || 'document'} ?`,
      actionButtonLabel: 'Delete',
    });
  };

  const onApproveClick = (row: Document) => {
    setActiveDocument(row);
    setConfirmationModal({
      action: 'approve',
      isOpen: true,
      title: `Approve ${row?.name || 'document'} ?`,
      message: `Are you sure you want to approve ${activeDocument?.name || 'document'} ?`,
      actionButtonLabel: 'Approve',
    });
  };

  const providerValue = useMemo(
    () => ({
      documents,
      metaData,
      currentPage,
      globalSearch,
      tags,
      isLoading: documentsLoading,
      activeDocument,
      confirmationModal,
      closeConfirmationModal,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onDeleteClick,
      onApproveClick,
      onConfirmClick,
      onRowClick,
      onNewClick,
      onSortChange,
    }),
    [
      documents,
      metaData,
      currentPage,
      globalSearch,
      tags,
      documentsLoading,
      activeDocument,
      confirmationModal,
      closeConfirmationModal,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      onDeleteClick,
      onApproveClick,
      onConfirmClick,
      onRowClick,
      onNewClick,
      onSortChange,
    ],
  );

  return <AdminDocumentsPageContext.Provider value={providerValue}>{children}</AdminDocumentsPageContext.Provider>;
};

export const useAdminDocumentsPage = () => {
  return useContext(AdminDocumentsPageContext);
};

interface Props {
  children?: React.ReactNode;
}
