import React, { createContext, useContext, useMemo, useCallback, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { MetaData, SortDirection, usePagination, MessageType, showMessage } from 'helpers';
import { Newsletter, fetchNewsletters, deleteNewsletter } from 'services';

import { initialConfirmationModal } from '../consts';
import { ConfirmationModal } from './types';

const AdminNewslettersContext = createContext<{
  newsletters: Newsletter[];
  metaData?: MetaData;
  currentPage: number;
  globalSearch: string;
  newslettersLoading: boolean;
  confirmationModal: ConfirmationModal;
  onSearchChange: (search: string) => void;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (accessor: string, sort: SortDirection) => void;
  onRowClick: (row: Newsletter) => void;
  onNewslettersSearch: (values: any) => void;
  onNewClick: () => void;
  onDeleteClick: (row: Newsletter) => void;
  onConfirmClick: () => Promise<void>;
  closeConfirmationModal: () => void;
}>({} as any);

// This will be used in case we state shared inside the module
export const AdminNewslettersPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [activeNewsletter, setActiveNewsletter] = useState<Newsletter | null>(null);
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>(initialConfirmationModal);

  const { mutateAsync: removeNewsletter } = useMutation(deleteNewsletter);

  const {
    currentPage,
    pageSize,
    sortBy,
    sortDirection,
    tags,
    globalSearch,
    filters,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onSearchChange,
    onFiltersChange,
  } = usePagination();

  const { data: queryData, isLoading: newslettersLoading } = useQuery(
    ['newsletters', currentPage, pageSize, sortBy, sortDirection, tags, globalSearch, filters],
    () => fetchNewsletters({ page: currentPage, pageSize, sortBy, sortDirection, tags, globalSearch, filters }),
  );

  const newsletters = useMemo(() => queryData?.data ?? [], [queryData?.data]);
  const metaData = useMemo(() => queryData?.meta, [queryData?.meta]);

  const onNewslettersSearch = (values: any) => {
    onFiltersChange(values);
  };

  const onNewClick = useCallback(() => {
    navigate('/admin/newsletters/new');
  }, [navigate]);

  const onRowClick = useCallback(
    (row: Newsletter) => {
      navigate(`/admin/newsletters/${row.id}`);
    },
    [navigate],
  );

  const onDeleteClick = (row: Newsletter) => {
    setActiveNewsletter(row);
    setConfirmationModal({
      action: 'delete',
      isOpen: true,
      title: `Delete ${row?.mainArticleName || 'newsletter'} ?`,
      message: `Are you sure you want to delete ${activeNewsletter?.mainArticleName || 'newsletter'} ?`,
      actionButtonLabel: 'Delete',
    });
  };

  const closeConfirmationModal = useCallback(() => {
    setActiveNewsletter(null);
    setConfirmationModal(initialConfirmationModal);
  }, []);

  const deleteConfirm = useCallback(async () => {
    if (activeNewsletter) {
      try {
        await removeNewsletter(activeNewsletter.id);
        showMessage('Newsletter successfully deleted.', MessageType.Success);
        queryClient.invalidateQueries(['newsletters']);
        closeConfirmationModal();
      } catch (err: any) {
        const errorMsg = err?.response?.data?.title;
        showMessage(errorMsg, MessageType.Error);
      }
    }
  }, [activeNewsletter, removeNewsletter, queryClient]);

  const onConfirmClick = async () => {
    if (confirmationModal.action === 'delete') {
      deleteConfirm();
    }
    return;
  };

  const providerValue = useMemo(
    () => ({
      newsletters,
      metaData,
      currentPage,
      tags,
      globalSearch,
      newslettersLoading,
      confirmationModal,
      onPageChange,
      onPageSizeChange,
      onSortChange,
      onRowClick,
      onSearchChange,
      onNewslettersSearch,
      onNewClick,
      onDeleteClick,
      onConfirmClick,
      closeConfirmationModal,
    }),
    [
      newsletters,
      metaData,
      currentPage,
      tags,
      globalSearch,
      newslettersLoading,
      confirmationModal,
      onPageChange,
      onPageSizeChange,
      onSortChange,
      onRowClick,
      onSearchChange,
      onNewslettersSearch,
      onNewClick,
      onDeleteClick,
      onConfirmClick,
      closeConfirmationModal,
    ],
  );

  return <AdminNewslettersContext.Provider value={providerValue}>{children}</AdminNewslettersContext.Provider>;
};

export const useAdminNewslettersPageProvider = () => {
  return useContext(AdminNewslettersContext);
};

interface Props {
  children?: React.ReactNode;
}
