import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAuth } from 'GlobalProvider';
import { AuthCardLayout, Button, Input } from 'components';
import { MessageType, showMessage } from 'helpers';
import { LoginForm, useLogin } from 'services';
import { VALIDATION_SCHEMA } from './const';
import { Link } from 'react-router-dom';

export const SignIn = () => {
  const { loginUser } = useAuth();
  const { postLogin } = useLogin();
  const { handleSubmit, control } = useForm<LoginForm>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const onSubmit = handleSubmit(async (values) => {
    try {
      const authData = await postLogin(values);
      loginUser(authData);
      showMessage('Successfully logged in.', MessageType.Success);
    } catch (error: any) {
      showMessage(error.response?.data?.title, MessageType.Error);
      console.error(error);
    }
  });

  return (
    <AuthCardLayout
      title="Login in to your account"
      subTitle={
        <>
          Or{' '}
          <Link to="/register" className="font-medium text-primary-800 hover:text-primary-850">
            register for a new account
          </Link>
        </>
      }
    >
      <form className="space-y-6" onSubmit={onSubmit}>
        <Input control={control} name="username" label="Email" placeholder="Email" type="email" />
        <Input control={control} name="password" label="Password" placeholder="Password" type="password" />

        <Button fullWidth type="submit">
          Submit
        </Button>
      </form>

      <div className="flex items-center justify-between mt-4">
        <Link to="/forgot-password" className="font-medium text-xs text-primary-800 hover:text-primary-850">
          Forgot password?
        </Link>
        <a
          href="mailto:admin@globalcftexperthub.org"
          className="font-medium text-xs text-primary-800 hover:text-primary-850"
        >
          Contact Administrator
        </a>
      </div>
    </AuthCardLayout>
  );
};
