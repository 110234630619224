import React, { createContext, useContext, useMemo, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { MessageType, showMessage, useIdFromParams } from 'helpers';
import { Control, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Newsletter,
  fetchNewsletter,
  postNewsletter,
  putNewsletter,
  NewsletterForm,
  fetchProfile,
  fetchAllArticles,
} from 'services';
import { defaultNewsletterForm, NEWSLETTER_VALIDATION_SCHEMA } from '../consts';
import { newsletterToNewsletterForm } from './transformations';
import { SelectOption } from 'components';
import { NewsletterStatus } from 'services/newsletters/const';

const NewsletterPageContext = createContext<{
  isCreate: boolean;
  control: Control<Newsletter, any>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  articles: SelectOption[];
  markNewsletterAsReady: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  markReadyToBeSent: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  newsletterStatus: string;
}>({} as any);

// This will be used in case we state shared inside the module
export const NewsletterPageProvider = ({ children = <Outlet /> }: Props) => {
  const navigate = useNavigate();
  const { id: newsletterId, isCreate } = useIdFromParams();
  const queryClient = useQueryClient();

  const { data: newsletter } = useQuery(['newsletters', newsletterId], () => fetchNewsletter(newsletterId), {
    enabled: !isCreate,
  });

  const { data: profile } = useQuery(['profile'], () => fetchProfile());

  const { data: queryArticlesData } = useQuery(['articles', 1, 9999], () => fetchAllArticles());

  const { mutateAsync: updateNewsletter } = useMutation(putNewsletter);
  const { mutateAsync: createNewsletter } = useMutation(postNewsletter);

  const articles = useMemo(
    () =>
      queryArticlesData?.data.map((article) => ({
        label: article.name,
        value: article.id,
      })) ?? [],
    [queryArticlesData?.data],
  );
  const newsletterForm = useMemo(() => {
    if (newsletter) {
      return newsletterToNewsletterForm(newsletter);
    }
    return defaultNewsletterForm;
  }, [newsletter]);

  const { control, handleSubmit, reset, setValue, watch } = useForm<Newsletter>({
    defaultValues: newsletterForm,
    resolver: yupResolver(NEWSLETTER_VALIDATION_SCHEMA),
  });

  const newsletterStatus = watch('status');

  useEffect(() => {
    if (isCreate && profile) {
      setValue('publisherId', profile ? profile.id : '');
      setValue('publisherAlias', profile?.alias);
    } else {
      reset(newsletterForm);
    }
  }, [newsletterForm, isCreate, profile]);

  const onSubmit = handleSubmit(async (values: NewsletterForm) => {
    if (isCreate) {
      try {
        await createNewsletter(values);
        showMessage('Newsletter successfully sent.', MessageType.Success);
        queryClient.invalidateQueries(['newsletters']);
        navigate('/admin/newsletters');
      } catch {
        showMessage('Theres been an error!', MessageType.Error);
      }
      return;
    }

    try {
      await updateNewsletter(values);
      showMessage('Newsletter successfully updated.', MessageType.Success);
      queryClient.invalidateQueries(['newsletters']);
      navigate('/admin/newsletters');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const markNewsletterAsReady = handleSubmit(async (values: NewsletterForm) => {
    try {
      await updateNewsletter({ ...values, status: NewsletterStatus.READY });
      showMessage('Newsletter successfully updated.', MessageType.Success);
      queryClient.invalidateQueries(['newsletters']);
      navigate('/admin/newsletters');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const markReadyToBeSent = handleSubmit(async (values: NewsletterForm) => {
    try {
      await updateNewsletter({ ...values, status: NewsletterStatus.READYTOBESENT });
      showMessage('Newsletter successfully updated.', MessageType.Success);
      queryClient.invalidateQueries(['newsletters']);
      navigate('/admin/newsletters');
    } catch {
      showMessage('Theres been an error!', MessageType.Error);
    }
  });

  const providerValue = useMemo(
    () => ({ control, onSubmit, isCreate, articles, newsletterStatus, markNewsletterAsReady, markReadyToBeSent }),
    [control, onSubmit, isCreate, articles, newsletterStatus, markNewsletterAsReady, markReadyToBeSent],
  );

  return <NewsletterPageContext.Provider value={providerValue}>{children}</NewsletterPageContext.Provider>;
};

export const useNewsletterPage = () => {
  return useContext(NewsletterPageContext);
};

interface Props {
  children?: React.ReactNode;
}
