import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from 'GlobalProvider';

type AuthCardLayoutProps = {
  title: string;
  subTitle: ReactNode;
  children: ReactNode;
};

export function AuthCardLayout({ title, subTitle, children }: AuthCardLayoutProps) {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src="/logo-negative.svg" alt="logo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{title}</h2>
        <p className="mt-2 text-center text-sm text-gray-600">{subTitle}</p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">{children}</div>
      </div>
    </div>
  );
}
